import "core-js/fn/promise";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import LogRocket from "logrocket";
import "./index.css";
import { LicenseManager } from "ag-grid-enterprise";
import "./i18n";
import Root from "./Root";

const integrateLogRocketWithSentry = (sessionUrl) => {
  Sentry.configureScope((scope) => {
    scope.addEventProcessor(async (event) => {
      event.extra.sessionURL = sessionUrl;
      return event;
    });
  });
};

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
});

LogRocket.init(process.env.REACT_APP_LOG_ROCKET_APP_ID);
LogRocket.getSessionURL(integrateLogRocketWithSentry);

LicenseManager.setLicenseKey(
  "Avenir_Health__MultiApp_1Devs8_November_2019__MTU3MzE3MTIwMDAwMA==5fe24a203ae4d849fe9768ee0a3219f5"
);

ReactDOM.render(<Root />, document.getElementById("root"));
