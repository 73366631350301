import React, { Component } from "react";
import { Route, HashRouter as Router, Switch } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withErrorBoundary } from "./errors";
import { withTranslation } from "react-i18next";
import theme from "./theme";
import AppContainer from "./containers/AppContainer";
import LoginContainer from "./containers/LoginContainer";
import PrivateRoute from "./components/PrivateRoute";

class Root extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    document.title = this.props.t("APP_TITLE");
  }

  render() {
    return (
      <Router>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Switch>
            <Route path="/login" component={LoginContainer} />
            <PrivateRoute path="/" component={AppContainer} />
          </Switch>
        </MuiThemeProvider>
      </Router>
    );
  }
}

export default withErrorBoundary(withTranslation()(Root));
