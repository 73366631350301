import React from "react";
import * as PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/green";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  information: InfoIcon
};

const styles = theme => ({
  error: {
    backgroundColor: theme.palette.error.dark
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  information: {
    backgroundColor: theme.palette.primary.dark
  },
  margin: {
    margin: theme.spacing.unit
  },
  message: {
    display: "flex",
    alignItems: "center"
  },
  success: {
    backgroundColor: green[600]
  },
  warning: {
    backgroundColor: amber[700]
  }
});

const Notification = props => {
  const Icon = variantIcon[props.variant];

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      open={props.open}
      autoHideDuration={props.autoHideDurationMilliseconds}
      className={props.classes.container}
      onClose={props.onClose}
    >
      <SnackbarContent
        message={
          <span id="client-snackbar" className={props.classes.message}>
            <Icon
              className={classNames(
                props.classes.icon,
                props.classes.iconVariant
              )}
            />
            {props.message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            color="inherit"
            aria-label="Close"
            onClick={props.onClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
        className={classNames(
          props.classes.margin,
          props.classes[props.variant]
        )}
        aria-describedby="client-snackbar"
      />
    </Snackbar>
  );
};

Notification.propTypes = {
  variant: PropTypes.oneOf(["success", "information", "warning", "error"]),
  open: PropTypes.bool,
  message: PropTypes.string,
  autoHideDurationMilliseconds: PropTypes.number,
  onClose: PropTypes.func.isRequired
};

Notification.defaultProps = {
  variant: "information",
  open: false,
  message: "",
  autoHideDurationMilliseconds: 6000
};

export default withStyles(styles)(Notification);
