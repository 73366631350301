import React from "react";
import Markdown from "react-markdown";
import { withErrorBoundary } from "../errors";
import { withTranslation } from "react-i18next";
import LastUpdated from "./LastUpdated";

const About = ({ t }) => (
  <div>
    <Markdown>{t("ABOUT_INTRO")}</Markdown>
    <Markdown>{t("ABOUT_INSTRUCTIONS")}</Markdown>
    <Markdown>{t("ABOUT_ALKEMA")}</Markdown>
    <Markdown>{t("ABOUT_CAHILL")}</Markdown>
    <Markdown>{t("ABOUT_WHELDON")}</Markdown>
    <Markdown>{t("ABOUT_DATA")}</Markdown>
    <Markdown>{t("ABOUT_CREDITS")}</Markdown>
    <Markdown>{t("ABOUT_CITATION")}</Markdown>

    <p>
      <LastUpdated />
    </p>
  </div>
);

export default withErrorBoundary(withTranslation()(About));
