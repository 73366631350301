import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";

const DRAWER_WIDTH = 350;
const DENSE_TOOLBAR = true;

const styles = theme => ({
  drawerPaper: {
    width: DRAWER_WIDTH,
    padding: 10
  },
  mainContent: {
    marginLeft: DRAWER_WIDTH
  },
  toolbar: {
    ...theme.mixins.toolbar,
    marginTop: DENSE_TOOLBAR ? -16 : 0
  }
});

const contentWithDrawer = (MainContent, DrawerContent) => {
  const Component = ({ classes, ...rest }) => (
    <div className={classes.container}>
      <Drawer
        variant="permanent"
        anchor="left"
        classes={{
          docked: classes.drawer,
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.toolbar} />

        <DrawerContent {...rest} />
      </Drawer>

      <div className={classes.mainContent}>
        <MainContent {...rest} />
      </div>
    </div>
  );

  return withStyles(styles)(Component);
};

export default contentWithDrawer;
