import React from "react";
import * as PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EnIcon from "./EnIcon";
import FrIcon from "./FrIcon";

const styles = theme => ({
  button: {
    padding: 4
  },
  container: {
    whiteSpace: "nowrap"
  },
  icon: {
    width: 30
  }
});

const LanguageChooser = props => (
  <div className={props.classes.container}>
    <IconButton
      classes={{
        root: props.classes.button
      }}
      onClick={() => {
        props.onChangeLanguage("en");
      }}
    >
      <EnIcon className={props.classes.icon} />
    </IconButton>
    <IconButton
      classes={{
        root: props.classes.button
      }}
      onClick={() => {
        props.onChangeLanguage("fr");
      }}
    >
      <FrIcon className={props.classes.icon} />
    </IconButton>
  </div>
);

LanguageChooser.propTypes = {
  onChangeLanguage: PropTypes.func.isRequired
};

export default withStyles(styles)(LanguageChooser);
