import React, { Component } from "react";
import * as PropTypes from "prop-types";
import nouislider from "nouislider";
import "nouislider/distribute/nouislider.css";

class NoUiSlider extends Component {
  createSlider = () => {
    this.slider = nouislider.create(this.sliderContainer, {
      range: this.props.range,
      start: this.props.value,
      margin: this.props.margin,
      connect: this.props.connect,
      pips: this.props.pips,
      step: this.props.step,
      tooltips: this.props.tooltips,
      format: this.props.format,
      behaviour: this.props.behaviour
    });

    this.slider.on("change", () => this.onChange());
  };

  onChange = () => {
    if (this.props.onChange) {
      const [lower, upper] = this.slider.get();

      this.props.onChange({
        lower,
        upper
      });
    }
  };

  componentDidMount() {
    this.createSlider();
  }

  componentDidUpdate() {
    if (this.props.value) {
      this.slider.set(this.props.value);
    }
  }

  componentWillUnmount() {
    this.slider.destroy();
  }

  render() {
    return <div ref={element => (this.sliderContainer = element)} />;
  }
}

NoUiSlider.propTypes = {
  range: PropTypes.shape({
    min: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.number)
    ]).isRequired,
    max: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.number)
    ]).isRequired
  }).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number)
  ]).isRequired,
  step: PropTypes.number,
  margin: PropTypes.number,
  connect: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.bool)
  ]),
  pips: PropTypes.oneOfType([
    PropTypes.shape({
      mode: PropTypes.oneOf(["count"]),
      values: PropTypes.number.isRequired,
      density: PropTypes.number.isRequired
    })
  ]),
  format: PropTypes.object,
  tooltips: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.object]))
  ]),
  behaviour: PropTypes.string,
  onChange: PropTypes.func
};

export default NoUiSlider;
