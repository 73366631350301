import React, { Component } from "react";
import { createGuid } from "../utilities";
import * as Sentry from "@sentry/browser";
import { withStyles } from "@material-ui/core";
import Oops from "./Oops";

const styles = {
  details: {
    marginTop: "1em",
    whiteSpace: "pre-wrap"
  },
  error: {
    margin: 10
  },
  errorContentItem: {
    marginTop: "1em"
  }
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidCatch(error, errorInfo) {
    const eventId = createGuid();

    console.log(`Event ID: ${eventId}`);
    console.log(error);
    console.log(errorInfo);

    this.setState({
      error,
      errorInfo,
      eventId: createGuid()
    });

    Sentry.withScope(scope => {
      scope.setExtra("eventId", eventId);
      scope.setExtra("errorInfo", errorInfo);

      Sentry.captureException(error);
    });
  }

  render() {
    const { classes } = this.props;

    if (!this.state.error) {
      return this.props.children;
    } else {
      return (
        <div className={classes.error}>
          <Oops />

          <details className={classes.details}>
            {this.state.eventId && (
              <div className={classes.errorContentItem}>
                {`Event Id: ${this.state.eventId}`}
                <br />
              </div>
            )}

            <div className={classes.errorContentItem}>
              {this.state.error && this.state.error.toString()}
            </div>

            <div>{this.state.errorInfo.componentStack}</div>
          </details>
        </div>
      );
    }
  }
}

export default withStyles(styles)(ErrorBoundary);
