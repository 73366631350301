import React from "react";
import * as PropTypes from "prop-types";
import { managedLabeledValuePropType } from "../../propTypes";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";
import ManageDataItems from "./ManageDataItems";

const styles = {
  button: {
    marginRight: 20
  },
  buttonGroup: {
    marginTop: 20
  },
  footnotes: {
    margin: "1em 0"
  }
};

const ManageDataDatabases = ({ t, ...rest }) => (
  <div>
    <ManageDataItems
      items={rest.databases}
      selectedItemValues={rest.selectedDatabaseIds}
      onToggleItem={rest.onToggleDatabase}
    />

    <div className={rest.classes.buttonGroup}>
      <Button
        variant="contained"
        color="primary"
        disabled={!rest.selectedDatabaseIds.length}
        className={rest.classes.button}
        onClick={rest.onDownloadDatabases}
      >
        {t("Download selected databases")}
      </Button>

      <Button
        variant="contained"
        color="secondary"
        disabled={
          !rest.selectedDatabaseIds.length ||
          rest.databases.filter(
            database =>
              rest.selectedDatabaseIds.includes(database.value) &&
              (database.isInUse || database.isGlobal)
          ).length > 0
        }
        className={rest.classes.button}
        onClick={rest.onDeleteDatabases}
      >
        {t("Delete selected databases")}
      </Button>
    </div>

    <div className={rest.classes.footnotes}>
      <div>
        <sup>*</sup>
        {t("Global databases cannot be deleted.")}
      </div>
      <div>
        <sup>&dagger;</sup>
        {t("Databases referenced by active runs cannot be deleted.")}
      </div>
    </div>
  </div>
);

ManageDataDatabases.propTypes = {
  databases: PropTypes.arrayOf(managedLabeledValuePropType).isRequired,
  selectedDatabaseIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleDatabase: PropTypes.func.isRequired,
  onDownloadDatabases: PropTypes.func.isRequired,
  onDeleteDatabases: PropTypes.func.isRequired
};

ManageDataDatabases.defaultProps = {
  databases: []
};

export default withTranslation()(withStyles(styles)(ManageDataDatabases));
