import React from "react";
import preval from "preval.macro";
import { withTranslation } from "react-i18next";

const buildDate = preval`module.exports = new Date().toISOString()`;

const styles = {
  container: {
    fontSize: "smaller"
  }
};

const LastUpdated = ({ t }) => (
  <span style={styles.container}>
    {t("Last updated at")} {buildDate}
  </span>
);

export default withTranslation()(LastUpdated);
