import React from "react";
import { NavTab } from "react-router-tabs";
import "react-router-tabs/styles/react-router-tabs.css";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const styles = {
  active: {
    fontWeight: 600
  },
  container: {
    color: "#555"
  }
};

const NavigationTab = props => (
  <NavTab
    {...props}
    className={classNames("nav-tab", props.classes.container)}
    activeClassName={classNames("active", props.classes.active)}
  />
);

export default withStyles(styles)(NavigationTab);
