import React from "react";
import * as PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withTranslation } from "react-i18next";

const styles = {
  additionalButton: {
    marginTop: 10
  },
  button: {
    width: "100%"
  },
  externalDatabasePath: {
    marginTop: 10
  },
  fileInput: {
    display: "none"
  },
  inputLabel: {
    fontSize: "75%"
  }
};

const StartRunDatabaseUploader = ({ t, ...rest }) => (
  <div>
    {!rest.databaseName ? (
      <div>
        <input
          id="external-database-file"
          type="file"
          accept=".csv"
          className={rest.classes.fileInput}
          onChange={e => rest.onSelectDatabase(e.target.files[0])}
        />
        <label htmlFor="external-database-file">
          <Button
            variant="contained"
            component="span"
            className={rest.classes.button}
          >
            {t("Choose database")}
          </Button>
        </label>
      </div>
    ) : (
      undefined
    )}

    {rest.databaseName && !rest.uploadingDatabase ? (
      <div>
        <Button
          variant="contained"
          color="primary"
          className={rest.classes.button}
          onClick={rest.onUpload}
        >
          {t("Upload database")}
        </Button>

        <Button
          variant="contained"
          color="secondary"
          className={classNames(
            rest.classes.button,
            rest.classes.additionalButton
          )}
          onClick={rest.onCancelUpload}
        >
          {t("Cancel")}
        </Button>
      </div>
    ) : (
      undefined
    )}

    {rest.uploadingDatabase ? (
      <div>
        <LinearProgress
          variant="determinate"
          value={rest.databaseUploadProgress}
        />
      </div>
    ) : (
      undefined
    )}

    <div className={rest.classes.externalDatabasePath}>
      <InputLabel className={rest.classes.inputLabel}>
        {t("Database to upload")}
      </InputLabel>

      <div>{rest.databaseName || t("No database chosen")}</div>
    </div>
  </div>
);

StartRunDatabaseUploader.propTypes = {
  databaseName: PropTypes.string,
  uploadingDatabase: PropTypes.bool,
  databaseUploadProgress: PropTypes.number,
  onSelectDatabase: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onCancelUpload: PropTypes.func.isRequired
};

StartRunDatabaseUploader.defaultProps = {
  uploadingDatabase: false,
  databaseUploadProgress: 0
};

export default withTranslation()(withStyles(styles)(StartRunDatabaseUploader));
