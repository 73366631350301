import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { labeledValuePropType, populationDatumPropType } from "../../propTypes";
import { maximumYear, minimumYear } from "../../constants";
import { parseIntegerCell, parseNullableAlphaCell } from "../../utilities";
import { createDefaultPopulationDatum } from "../../data";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { withTranslation } from "react-i18next";
import { translateGridText } from "../../i18n";
import BooleanCellRenderer from "../BooleanCellRenderer";
import CountrySelector from "./PrepareDataCountrySelector";
import Saver from "./PrepareDataSaver";

const styles = {
  button: {
    marginRight: 20
  },
  buttonGroup: {
    marginBottom: 20
  },
  regionEditor: {
    margin: "20px 0 20px"
  },
  table: {
    margin: "20px 0",
    height: 400
  }
};

class PrepareDataPopulationData extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onGridReady = params => {
    this.gridApi = params.api;

    params.columnApi.autoSizeColumns(
      params.columnApi.getAllColumns().map(column => column.colId)
    );
  };

  onSelectionChanged = () => {
    this.setState({
      rowSelected: this.gridApi.getSelectedRows().length > 0
    });
  };

  onAddNewItem = () => {
    const item = createDefaultPopulationDatum();

    const newRowData = this.gridApi.updateRowData({
      add: [item]
    });

    this.gridApi.ensureIndexVisible(newRowData.add[0].childIndex);

    this.props.onAddNewItem(item);
  };

  onDeleteItem = () => {
    const selectedItemIds = this.gridApi.getSelectedRows().map(item => item.id);

    if (!selectedItemIds.length) {
      return;
    }

    this.props.onDeleteItem(selectedItemIds[0]);

    this.setState({
      rowSelected: false
    });
  };

  render() {
    return (
      <div>
        <CountrySelector
          databases={this.props.databases}
          countries={this.props.countries}
          selectedDatabaseId={this.props.selectedDatabaseId}
          selectedCountryNumericCode={this.props.selectedCountryNumericCode}
          onSelectDatabase={this.props.onSelectDatabase}
          onSelectCountry={this.props.onSelectCountry}
          onLoadData={this.props.onLoadCountryData}
        />

        <div
          className={classNames("ag-theme-material", this.props.classes.table)}
        >
          <AgGridReact
            defaultColDef={{
              sortable: true,
              editable: true
            }}
            rowSelection="single"
            suppressColumnVirtualisation={true}
            suppressScrollOnNewData={true}
            frameworkComponents={{ booleanCellRenderer: BooleanCellRenderer }}
            overlayNoRowsTemplate="<span />"
            localeTextFunc={translateGridText}
            rowData={this.props.data}
            onGridReady={this.onGridReady}
            onSelectionChanged={this.onSelectionChanged}
            onCellValueChanged={params => {
              this.props.onEditItem(params.data);
            }}
          >
            <AgGridColumn
              headerName={this.props.t("Region code")}
              field="regionCode"
              valueParser={params => parseNullableAlphaCell(params)}
            />
            <AgGridColumn
              headerName={this.props.t("In union?")}
              field="isInUnion"
              cellRenderer="booleanCellRenderer"
              cellEditor="agRichSelectCellEditor"
              cellEditorParams={{
                cellRenderer: "booleanCellRenderer",
                values: [true, false]
              }}
            />
            <AgGridColumn
              headerName={this.props.t("Age")}
              field="ageRange"
              cellEditor="agRichSelectCellEditor"
              cellEditorParams={{
                values: [
                  "10-14",
                  "15-19",
                  "20-24",
                  "25-29",
                  "30-34",
                  "35-39",
                  "40-44",
                  "45-49",
                  "15-49"
                ]
              }}
            />
            <AgGridColumn
              headerName={this.props.t("Year")}
              field="midYear"
              type="numericColumn"
              filter="agNumberColumnFilter"
              valueParser={params =>
                parseIntegerCell(params, minimumYear, maximumYear)
              }
            />
            <AgGridColumn
              headerName={this.props.t("Population")}
              field="population"
              type="numericColumn"
              filter="agNumberColumnFilter"
            />
          </AgGridReact>
        </div>

        <div className={this.props.classes.buttonGroup}>
          <Button
            variant="contained"
            color="primary"
            className={this.props.classes.button}
            onClick={this.onAddNewItem}
          >
            {this.props.t("Add new record")}
          </Button>

          <Button
            variant="contained"
            color="secondary"
            disabled={!this.state.rowSelected}
            className={this.props.classes.button}
            onClick={this.onDeleteItem}
          >
            {this.props.t("Delete selected row")}
          </Button>

          <Button
            variant="contained"
            color="secondary"
            className={this.props.classes.button}
            onClick={this.props.onClearData}
          >
            {this.props.t("Clear data")}
          </Button>
        </div>

        <Saver
          name={this.props.newDatabaseName}
          saveDisabled={
            (!this.props.regionData &&
              !this.props.selectedCountryNumericCode) ||
            (this.props.regionData &&
              (!this.props.regionNumericCode ||
                !this.props.regionAlphaCode ||
                !this.props.regionName)) ||
            !this.props.newDatabaseName
          }
          onChangeName={this.props.onChangeDatabaseName}
          onSave={this.props.onSave}
        />
      </div>
    );
  }
}

PrepareDataPopulationData.propTypes = {
  databases: PropTypes.arrayOf(labeledValuePropType),
  countries: PropTypes.arrayOf(labeledValuePropType),
  selectedDatabaseId: PropTypes.string,
  selectedCountryNumericCode: PropTypes.number,
  data: PropTypes.arrayOf(populationDatumPropType),
  newDatabaseName: PropTypes.string,
  onSelectDatabase: PropTypes.func.isRequired,
  onSelectCountry: PropTypes.func.isRequired,
  onLoadCountryData: PropTypes.func.isRequired,
  onAddNewItem: PropTypes.func.isRequired,
  onEditItem: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  onClearData: PropTypes.func.isRequired,
  onChangeDatabaseName: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

PrepareDataPopulationData.defaultProps = {
  databases: [],
  countries: [],
  data: []
};

export default withTranslation()(withStyles(styles)(PrepareDataPopulationData));
