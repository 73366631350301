import React from "react";
import * as PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { withTranslation } from "react-i18next";

const styles = {
  comparisonRunName: {
    backgroundColor: "plum",
    borderBottom: "3px dashed black",
    marginLeft: 10
  },
  container: {
    display: "flex"
  },
  runName: {
    backgroundColor: "lavender",
    borderBottom: "3px solid black",
    marginLeft: 10
  }
};

const ChartsComparisonLegend = ({ t, ...rest }) => (
  <div className={rest.classes.container}>
    <div>{t("Legend:")}</div>
    <div className={rest.classes.runName}>{rest.runName}</div>
    <div className={rest.classes.comparisonRunName}>
      {rest.comparisonRunName}
    </div>
  </div>
);

ChartsComparisonLegend.propTypes = {
  runName: PropTypes.string,
  comparisonRunName: PropTypes.string
};

export default withTranslation()(withStyles(styles)(ChartsComparisonLegend));
