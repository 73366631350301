import React from "react";
import * as PropTypes from "prop-types";
import { formatNumericCell } from "../utilities";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { withTranslation } from "react-i18next";
import { translateGridText } from "../i18n";

const EmuData = ({ t, ...rest }) => (
  <div className="ag-theme-material">
    <AgGridReact
      domLayout="autoHeight"
      defaultColDef={{
        sortable: true,
        filter: true
      }}
      pagination={true}
      paginationPageSize={10}
      suppressColumnVirtualisation={true}
      suppressCellSelection={true}
      overlayNoRowsTemplate="<span />"
      localeTextFunc={translateGridText}
      rowData={rest.data}
      onGridReady={params => {
        params.columnApi.autoSizeColumns(
          params.columnApi.getAllColumns().map(column => column.colId)
        );
      }}
    >
      <AgGridColumn 
        headerName={t("Region code")} 
        field="regionCode" 
      />
      <AgGridColumn
          headerName={t("Reference pop")}
          field="referencePop"
      />
      <AgGridColumn
        headerName={t("Service statistic type")}
        field="serviceStatisticType"
      />
      <AgGridColumn
        headerName={t("Year")}
        field="startYear"
        type="numericColumn"
        filter="agNumberColumnFilter"
      />
      <AgGridColumn
        headerName={t("EMU")}
        field="emu"
        type="numericColumn"
        filter="agNumberColumnFilter"
        valueFormatter={params => formatNumericCell(params.value, 3)}
      />
      <AgGridColumn
          headerName={t("SD_EMU")}
          field="sD_EMU"
      />
      <AgGridColumn
          headerName={t("EMU_ROC")}
          field="emU_ROC"
      />
      <AgGridColumn
          headerName={t("SD_EMU_ROC")}
          field="sD_EMU_ROC"
      />
      <AgGridColumn
          headerName={t("Include?")}
          field="included"
      /> 
      <AgGridColumn />
      <AgGridColumn />
    </AgGridReact>
  </div>
);

EmuData.propTypes = {
  data: PropTypes.array
};

EmuData.defaultProps = {
  data: []
};

export default withTranslation()(EmuData);
