import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { contraceptiveMethods, indicators } from "../constants";
import { trimToRange, trimYearToRange } from "../utilities";
import * as api from "../api/api";
import Targets from "../components/Targets/Targets";

const createInitialState = () => ({
  indicatorProbabilityParameters: {
    indicator: indicators[0].value,
    maritalStatus: "married",
    direction: "greaterThan"
  },
  indicatorLevelParameters: {
    indicator: indicators[0].value,
    maritalStatus: "married",
    direction: "greaterThan"
  },
  populationProbabilityParameters: {
    delta: "total",
    maritalStatus: "married",
    contraceptiveMethod: contraceptiveMethods[0].value,
    direction: "greaterThan"
  },
  populationLevelParameters: {
    delta: "total",
    maritalStatus: "married",
    contraceptiveMethod: contraceptiveMethods[0].value,
    direction: "greaterThan"
  }
});

const createPayload = state => {
  const payload = {};

  if (
    state.indicatorProbabilityParameters.year &&
    !isNaN(state.indicatorProbabilityParameters.level)
  ) {
    payload.indicatorProbabilityParameters = {
      ...state.indicatorProbabilityParameters,
      level: state.indicatorProbabilityParameters.level / 100
    };
  }

  if (
    state.indicatorLevelParameters.year &&
    !isNaN(state.indicatorLevelParameters.probability)
  ) {
    payload.indicatorLevelParameters = state.indicatorLevelParameters;
  }

  if (
    state.populationProbabilityParameters.year &&
    !isNaN(state.populationProbabilityParameters.level)
  ) {
    payload.populationProbabilityParameters =
      state.populationProbabilityParameters;
  }

  if (
    state.populationLevelParameters.year &&
    !isNaN(state.populationLevelParameters.probability)
  ) {
    payload.populationLevelParameters = state.populationLevelParameters;
  }

  return payload;
};

class TargetsContainer extends Component {
  constructor(props) {
    super(props);

    this.signal = api.getSignal();

    this.state = createInitialState();
  }

  setStateSafely = (state, callback) => {
    if (!this.unmounting) {
      this.setState(state, callback);
    }
  };

  onSelectIndicatorProbabilityIndicator = indicator => {
    this.setStateSafely({
      indicatorProbabilityParameters: {
        ...this.state.indicatorProbabilityParameters,
        indicator
      }
    });
  };

  onSelectIndicatorProbabilityMaritalStatus = maritalStatus => {
    this.setStateSafely({
      indicatorProbabilityParameters: {
        ...this.state.indicatorProbabilityParameters,
        maritalStatus
      }
    });
  };

  onChangeIndicatorProbabilityYear = (year, enforceRange) => {
    year = trimYearToRange(year, enforceRange);

    this.setStateSafely({
      indicatorProbabilityParameters: {
        ...this.state.indicatorProbabilityParameters,
        year
      }
    });
  };

  onSelectIndicatorProbabilityDirection = direction => {
    this.setStateSafely({
      indicatorProbabilityParameters: {
        ...this.state.indicatorProbabilityParameters,
        direction
      }
    });
  };

  onChangeIndicatorProbabilityLevel = level => {
    level = trimToRange(level, 0, 100);

    this.setStateSafely({
      indicatorProbabilityParameters: {
        ...this.state.indicatorProbabilityParameters,
        level
      }
    });
  };

  onSelectIndicatorLevelIndicator = indicator => {
    this.setStateSafely({
      indicatorLevelParameters: {
        ...this.state.indicatorLevelParameters,
        indicator
      }
    });
  };

  onSelectIndicatorLevelMaritalStatus = maritalStatus => {
    this.setStateSafely({
      indicatorLevelParameters: {
        ...this.state.indicatorLevelParameters,
        maritalStatus
      }
    });
  };

  onChangeIndicatorLevelYear = (year, enforceRange) => {
    year = trimYearToRange(year, enforceRange);

    this.setStateSafely({
      indicatorLevelParameters: {
        ...this.state.indicatorLevelParameters,
        year
      }
    });
  };

  onChangeIndicatorLevelProbability = probability => {
    if (!probability) {
      probability = "";
    } else if (!probability.match(/^(0?(\.\d*)?|1)$/)) {
      probability = this.state.indicatorLevelParameters.probability;
    }

    this.setStateSafely({
      indicatorLevelParameters: {
        ...this.state.indicatorLevelParameters,
        probability
      }
    });
  };

  onSelectIndicatorLevelDirection = direction => {
    this.setStateSafely({
      indicatorLevelParameters: {
        ...this.state.indicatorLevelParameters,
        direction
      }
    });
  };

  onSelectPopulationProbabilityDelta = delta => {
    this.setStateSafely({
      populationProbabilityParameters: {
        ...this.state.populationProbabilityParameters,
        delta
      }
    });
  };

  onSelectPopulationProbabilityMaritalStatus = maritalStatus => {
    this.setStateSafely({
      populationProbabilityParameters: {
        ...this.state.populationProbabilityParameters,
        maritalStatus
      }
    });
  };

  onChangePopulationProbabilityRelativeToYear = (year, enforceRange) => {
    year = trimYearToRange(year, enforceRange);

    this.setStateSafely({
      populationProbabilityParameters: {
        ...this.state.populationProbabilityParameters,
        relativeToYear: year
      }
    });
  };

  onSelectPopulationProbabilityContraceptiveMethod = contraceptiveMethod => {
    this.setStateSafely({
      populationProbabilityParameters: {
        ...this.state.populationProbabilityParameters,
        contraceptiveMethod
      }
    });
  };

  onChangePopulationProbabilityYear = (year, enforceRange) => {
    year = trimYearToRange(year, enforceRange);

    this.setStateSafely({
      populationProbabilityParameters: {
        ...this.state.populationProbabilityParameters,
        year
      }
    });
  };

  onSelectPopulationProbabilityDirection = direction => {
    this.setStateSafely({
      populationProbabilityParameters: {
        ...this.state.populationProbabilityParameters,
        direction
      }
    });
  };

  onChangePopulationProbabilityLevel = level => {
    level = trimToRange(level, 0, Number.MAX_VALUE);

    this.setStateSafely({
      populationProbabilityParameters: {
        ...this.state.populationProbabilityParameters,
        level
      }
    });
  };

  onSelectPopulationLevelDelta = delta => {
    this.setStateSafely({
      populationLevelParameters: {
        ...this.state.populationLevelParameters,
        delta
      }
    });
  };

  onSelectPopulationLevelMaritalStatus = maritalStatus => {
    this.setStateSafely({
      populationLevelParameters: {
        ...this.state.populationLevelParameters,
        maritalStatus
      }
    });
  };

  onChangePopulationLevelRelativeToYear = (year, enforceRange) => {
    year = trimYearToRange(year, enforceRange);

    this.setStateSafely({
      populationLevelParameters: {
        ...this.state.populationLevelParameters,
        relativeToYear: year
      }
    });
  };

  onSelectPopulationLevelContraceptiveMethod = contraceptiveMethod => {
    this.setStateSafely({
      populationLevelParameters: {
        ...this.state.populationLevelParameters,
        contraceptiveMethod
      }
    });
  };

  onChangePopulationLevelYear = (year, enforceRange) => {
    year = trimYearToRange(year, enforceRange);

    this.setStateSafely({
      populationLevelParameters: {
        ...this.state.populationLevelParameters,
        year
      }
    });
  };

  onChangePopulationLevelProbability = probability => {
    if (!probability) {
      probability = "";
    } else if (!probability.match(/^(0?(\.\d*)?|1)$/)) {
      probability = this.state.populationLevelParameters.probability;
    }

    this.setStateSafely({
      populationLevelParameters: {
        ...this.state.populationLevelParameters,
        probability
      }
    });
  };

  onSelectPopulationLevelDirection = direction => {
    this.setStateSafely({
      populationLevelParameters: {
        ...this.state.populationLevelParameters,
        direction
      }
    });
  };

  onCalculate = () => {
    this.setStateSafely({
      calculating: true
    });

    const countryNumericCodeOrRegion =
      this.props.countryNumericCode || this.props.region;

    api
      .calculateTargets(
        this.props.runId,
        countryNumericCodeOrRegion,
        createPayload(this.state),
        this.signal.token
      )
      .then(response => {
        if (!response) {
          return;
        }

        this.setStateSafely({
          ...response.data
        });
      })
      .finally(() => {
        this.setStateSafely({
          calculating: false
        });
      });
  };

  componentWillUnmount() {
    this.unmounting = true;
    this.signal.cancel();
  }

  render() {
    return (
      <Targets
        indicators={indicators}
        contraceptiveMethods={contraceptiveMethods}
        indicatorProbabilityParameters={
          this.state.indicatorProbabilityParameters
        }
        indicatorProbability={this.state.indicatorProbability}
        indicatorLevelParameters={this.state.indicatorLevelParameters}
        indicatorLevel={this.state.indicatorLevel}
        populationProbabilityParameters={
          this.state.populationProbabilityParameters
        }
        populationProbability={this.state.populationProbability}
        populationLevelParameters={this.state.populationLevelParameters}
        populationLevel={this.state.populationLevel}
        valid={
          (!!this.props.countryNumericCode || !!this.props.region) &&
          Object.keys(createPayload(this.state)).length > 0
        }
        calculating={this.state.calculating}
        onSelectIndicatorProbabilityIndicator={
          this.onSelectIndicatorProbabilityIndicator
        }
        onSelectIndicatorProbabilityMaritalStatus={
          this.onSelectIndicatorProbabilityMaritalStatus
        }
        onChangeIndicatorProbabilityYear={this.onChangeIndicatorProbabilityYear}
        onSelectIndicatorProbabilityDirection={
          this.onSelectIndicatorProbabilityDirection
        }
        onChangeIndicatorProbabilityLevel={
          this.onChangeIndicatorProbabilityLevel
        }
        onSelectIndicatorLevelIndicator={this.onSelectIndicatorLevelIndicator}
        onSelectIndicatorLevelMaritalStatus={
          this.onSelectIndicatorLevelMaritalStatus
        }
        onChangeIndicatorLevelYear={this.onChangeIndicatorLevelYear}
        onChangeIndicatorLevelProbability={
          this.onChangeIndicatorLevelProbability
        }
        onSelectIndicatorLevelDirection={this.onSelectIndicatorLevelDirection}
        onSelectPopulationProbabilityDelta={
          this.onSelectPopulationProbabilityDelta
        }
        onSelectPopulationProbabilityMaritalStatus={
          this.onSelectPopulationProbabilityMaritalStatus
        }
        onChangePopulationProbabilityRelativeToYear={
          this.onChangePopulationProbabilityRelativeToYear
        }
        onSelectPopulationProbabilityContraceptiveMethod={
          this.onSelectPopulationProbabilityContraceptiveMethod
        }
        onChangePopulationProbabilityYear={
          this.onChangePopulationProbabilityYear
        }
        onSelectPopulationProbabilityDirection={
          this.onSelectPopulationProbabilityDirection
        }
        onChangePopulationProbabilityLevel={
          this.onChangePopulationProbabilityLevel
        }
        onSelectPopulationLevelDelta={this.onSelectPopulationLevelDelta}
        onSelectPopulationLevelMaritalStatus={
          this.onSelectPopulationLevelMaritalStatus
        }
        onChangePopulationLevelRelativeToYear={
          this.onChangePopulationLevelRelativeToYear
        }
        onSelectPopulationLevelContraceptiveMethod={
          this.onSelectPopulationLevelContraceptiveMethod
        }
        onChangePopulationLevelYear={this.onChangePopulationLevelYear}
        onChangePopulationLevelProbability={
          this.onChangePopulationLevelProbability
        }
        onSelectPopulationLevelDirection={this.onSelectPopulationLevelDirection}
        onCalculate={this.onCalculate}
      />
    );
  }
}

TargetsContainer.propTypes = {
  runId: PropTypes.string,
  countryNumericCode: PropTypes.number,
  region: PropTypes.string
};

export default TargetsContainer;
