import React, { Component } from "react";
import { toggleSetExistence } from "../utilities";
import { normalizeDatabases, normalizeRuns } from "../data";
import * as api from "../api/api";
import fileDownload from "js-file-download";
import { withErrorBoundary } from "../errors";
import ManageData from "../components/ManageData/ManageData";

const createInitialState = () => ({
  selectedDatabaseIds: new Set(),
  selectedRunIds: new Set()
});

class ManageDataContainer extends Component {
  constructor(props) {
    super(props);

    this.signal = api.getSignal();

    this.state = createInitialState();
  }

  setStateSafely = (state, callback) => {
    if (!this.unmounting) {
      this.setState(state, callback);
    }
  };

  normalizeDatabases = databases =>
    Object.entries(normalizeDatabases(databases))
      .filter(database => !database.isGlobal)
      .map(([id, database]) => ({
        value: id,
        label: `${database.name} (${database.type})`,
        isGlobal: database.isGlobal,
        isInUse: database.isInUse
      }));

  onToggleDatabase = databaseId => {
    // noinspection JSCheckFunctionSignatures
    const selectedDatabaseIds = new Set(this.state.selectedDatabaseIds);

    toggleSetExistence(selectedDatabaseIds, databaseId);

    this.setStateSafely({
      selectedDatabaseIds
    });
  };

  onDownloadDatabases = () => {
    const selectedDatabaseIds = Array.from(this.state.selectedDatabaseIds);

    api
      .downloadDatabases(selectedDatabaseIds, this.signal.token)
      .then(response => {
        if (!response) {
          return;
        }

        fileDownload(response.data, "databases.zip");
      });
  };

  onDeleteDatabases = () => {
    api
      .deleteDatabases(
        Array.from(this.state.selectedDatabaseIds),
        this.signal.token
      )
      .then(() => {
        const databases = this.state.databases.filter(
          database => !this.state.selectedDatabaseIds.has(database.value)
        );

        this.setStateSafely({
          selectedDatabaseIds: new Set(),
          databases
        });
      });
  };

  onToggleRun = runId => {
    // noinspection JSCheckFunctionSignatures
    const selectedRunIds = new Set(this.state.selectedRunIds);

    toggleSetExistence(selectedRunIds, runId);

    this.setStateSafely({
      selectedRunIds
    });
  };

  onDeleteRuns = () => {
    api
      .deleteRuns(Array.from(this.state.selectedRunIds), this.signal.token)
      .then(() => {
        const runs = this.state.runs.filter(
          run => !this.state.selectedRunIds.has(run.value)
        );

        this.setStateSafely({
          selectedRunIds: new Set(),
          runs
        });

        api.fetchDatabases(this.signal.token).then(response => {
          this.setStateSafely({
            databases: this.normalizeDatabases(response.data)
          });
        });
      });
  };

  componentDidMount() {
    this.setStateSafely({
      loadingData: true
    });

    // noinspection JSCheckFunctionSignatures
    Promise.all([
      api.fetchDatabases(this.signal.token),
      api.fetchRuns(true, true, this.signal.token)
    ])
      .then(response => {
        if (!response || response.some(item => !item)) {
          return;
        }

        const [databases, runs] = response;

        this.setStateSafely({
          databases: this.normalizeDatabases(databases.data),
          runs: Object.entries(normalizeRuns(runs.data))
            .filter(run => !run.isGlobal)
            .map(([id, run]) => ({
              value: id,
              label: run.name,
              isGlobal: run.isGlobal,
              createdAt: run.startedAt,
              completed: run.completed,
              failed: run.failed
            }))
        });
      })
      .finally(() => {
        this.setStateSafely({
          loadingData: false
        });
      });
  }

  componentWillUnmount() {
    this.unmounting = true;
    this.signal.cancel();
  }

  render() {
    return (
      <ManageData
        loadingData={this.state.loadingData}
        match={this.props.match}
        databases={this.state.databases}
        selectedDatabaseIds={Array.from(this.state.selectedDatabaseIds)}
        selectedRunIds={Array.from(this.state.selectedRunIds)}
        runs={this.state.runs}
        onToggleDatabase={this.onToggleDatabase}
        onDeleteDatabases={this.onDeleteDatabases}
        onDownloadDatabases={this.onDownloadDatabases}
        onToggleRun={this.onToggleRun}
        onDeleteRuns={this.onDeleteRuns}
      />
    );
  }
}

export default withErrorBoundary(ManageDataContainer);
