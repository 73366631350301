import React from "react";
import * as PropTypes from "prop-types";
import { maritalStatusPropType } from "../../propTypes";
import { formatPercentage } from "../../utilities";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { withTranslation } from "react-i18next";
import ProgressButton from "../ProgressButton";
import ProgressContraceptionMethodSpecification from "./ProgressContraceptionMethodSpecification";
import ProgressIndicatorSpecification from "./ProgressIndicatorSpecification";

const styles = {
  answer: {
    fontWeight: "bold"
  },
  button: {
    display: "table",
    marginTop: 20
  },
  panel: {
    margin: "20px 0 33px"
  }
};

const formatQuantiles = (
  [lower, median, upper],
  percentages,
  includeConfidenceIntervals = false
) => {
  const formattedMedian = percentages
    ? formatPercentage(median, 1)
    : median.toFixed(0);

  const formattedLower = percentages
    ? formatPercentage(lower, 1)
    : lower.toFixed(0);

  const formattedUpper = percentages
    ? formatPercentage(upper, 1)
    : upper.toFixed(0);

  return includeConfidenceIntervals
    ? `${formattedMedian} (${formattedLower}, ${formattedUpper})`
    : formattedMedian;
};

const Progress = ({ t, ...rest }) => (
  <div>
    <Typography variant="h5">
      {t("Information for measuring progress")}
    </Typography>

    <p>
      {t(
        "These options tell you the difference between two selected years for median estimates of either prevalence or number of women."
      )}
    </p>

    <Divider />

    <div className={rest.classes.panel}>
      <ProgressIndicatorSpecification
        indicators={rest.indicators}
        indicator={rest.indicatorProgressParameters.indicator}
        maritalStatus={rest.indicatorProgressParameters.maritalStatus}
        period={rest.indicatorProgressParameters.period}
        onSelectIndicator={rest.onSelectIndicatorProgressIndicator}
        onSelectMaritalStatus={rest.onSelectIndicatorProgressMaritalStatus}
        onChangeFirstYear={rest.onChangeIndicatorProgressFirstYear}
        onChangeLastYear={rest.onChangeIndicatorProgressLastYear}
      />
      <p className={rest.classes.answer}>
        {rest.indicatorProgress != null
          ? formatQuantiles(rest.indicatorProgress, true)
          : "???"}
      </p>
    </div>

    <Divider />

    <div className={rest.classes.panel}>
      <ProgressContraceptionMethodSpecification
        contraceptiveMethods={rest.contraceptiveMethods}
        maritalStatus={rest.contraceptiveMethodProgressParameters.maritalStatus}
        contraceptiveMethod={
          rest.contraceptiveMethodProgressParameters.contraceptiveMethod
        }
        period={rest.contraceptiveMethodProgressParameters.period}
        onSelectMaritalStatus={
          rest.onSelectContraceptiveMethodProgressMaritalStatus
        }
        onSelectContraceptiveMethod={
          rest.onSelectContraceptiveMethodProgressContraceptiveMethod
        }
        onChangeFirstYear={rest.onChangeContraceptiveMethodProgressFirstYear}
        onChangeLastYear={rest.onChangeContraceptiveMethodProgressLastYear}
      />
      <p className={rest.classes.answer}>
        {rest.contraceptiveMethodProgress != null
          ? formatQuantiles(rest.contraceptiveMethodProgress, false)
          : "???"}
      </p>
    </div>

    <Divider />

    <ProgressButton
      variant="contained"
      color="primary"
      disabled={!rest.valid}
      loading={rest.calculating}
      className={rest.classes.button}
      onClick={rest.onCalculate}
    >
      {t("Calculate")}
    </ProgressButton>
  </div>
);

Progress.propTypes = {
  indicators: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  contraceptiveMethods: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  indicatorProgressParameters: PropTypes.shape({
    indicator: PropTypes.string.isRequired,
    maritalStatus: maritalStatusPropType,
    period: PropTypes.shape({
      firstYear: PropTypes.number,
      lastYear: PropTypes.number
    })
  }).isRequired,
  indicatorProgress: PropTypes.arrayOf(PropTypes.number),
  contraceptiveMethodProgressParameters: PropTypes.shape({
    maritalStatus: maritalStatusPropType,
    contraceptiveMethod: PropTypes.string.isRequired,
    period: PropTypes.shape({
      firstYear: PropTypes.number,
      lastYear: PropTypes.number
    })
  }).isRequired,
  contraceptiveMethodProgress: PropTypes.arrayOf(PropTypes.number),
  valid: PropTypes.bool.isRequired,
  calculating: PropTypes.bool,
  onSelectIndicatorProgressIndicator: PropTypes.func.isRequired,
  onSelectIndicatorProgressMaritalStatus: PropTypes.func.isRequired,
  onChangeIndicatorProgressFirstYear: PropTypes.func.isRequired,
  onChangeIndicatorProgressLastYear: PropTypes.func.isRequired,
  onSelectContraceptiveMethodProgressMaritalStatus: PropTypes.func.isRequired,
  onSelectContraceptiveMethodProgressContraceptiveMethod:
    PropTypes.func.isRequired,
  onChangeContraceptiveMethodProgressFirstYear: PropTypes.func.isRequired,
  onChangeContraceptiveMethodProgressLastYear: PropTypes.func.isRequired,
  onCalculate: PropTypes.func.isRequired
};

Progress.defaultProps = {
  calculating: false
};

export default withTranslation()(withStyles(styles)(Progress));
