import React, { Component } from "react";
import { createGuid } from "../utilities";
import {
    to01Null,
  normalizeDatabases,
  normalizePopulationData,
  normalizeSurveyData,
  normalizeEmuData,
  synchonizeContraceptivePrevalence
} from "../data";
import * as api from "../api/api";
import { withErrorBoundary } from "../errors";
import { withTranslation } from "react-i18next";
import PrepareData from "../components/PrepareData/PrepareData";

const createInitialState = () => ({
  selectedEmuDatabaseId: ""
});

const findDefaultDatabaseId = databases => {
  if (!databases) {
    return;
  }

  for (let databaseId of Object.keys(databases)) {
    const { type, isDefault } = databases[databaseId];

    if (type === "survey" && isDefault) {
      return databaseId;
    }
  }
};

const getCountries = (databases, databaseId) => {
  if (!databases || !databaseId) {
    return;
  }

  const database = databases[databaseId];

  return Object.keys(database.divisions).reduce((result, divisionKey) => {
    const division = database.divisions[divisionKey];

    if (division.isCountry) {
      result[divisionKey] = division;
    }

    return result;
  }, {});
};

const findIndex = (data, key, value) =>
  data.map(datum => datum[key]).indexOf(value);

class PrepareDataContainer extends Component {
  constructor(props) {
    super(props);

    this.signal = api.getSignal();

    this.state = createInitialState();
  }

  setStateSafely = (state, callback) => {
    if (!this.unmounting) {
      this.setState(state, callback);
    }
  };

  findDefaultDatabaseId = () => findDefaultDatabaseId(this.state.databases);

  getCountries = databaseId => getCountries(this.state.databases, databaseId);

  createDatabasePayload = (
    type,
    name,
    databaseId,
    divisionNumericCode,
    data
  ) => {
    const payload = {
        id: createGuid(),
        type,
        databaseId: databaseId || this.findDefaultDatabaseId(),
        divisionNumericCode,
        name,
        data: data.map(datum => ({
          ...datum,
          divisionNumericCode
        }))
    };
    
    return payload;
  };

  createDatabase = databasePayload => ({
    type: databasePayload.type,
    name: databasePayload.name,
    divisions: {
      [databasePayload.divisionNumericCode]: this.state.databases[
        databasePayload.databaseId
      ].divisions[databasePayload.divisionNumericCode]
    }
  });

  onSelectSurveyDatabase = databaseId => {
    const countries = this.getCountries(databaseId);

    this.setStateSafely({
      selectedSurveyDatabaseId: databaseId,
      selectedSurveyDataCountryNumericCode:
        Object.keys(countries).length !== 1
          ? undefined
          : parseInt(Object.keys(countries)[0], 10)
    });
  };

  onSelectSurveyDataCountry = countryNumericCode => {
    this.setStateSafely({
      selectedSurveyDataCountryNumericCode: countryNumericCode
    });
  };

  onLoadCountrySurveyData = () => {
    api
      .fetchSurveyDatabase(
        this.state.selectedSurveyDatabaseId,
        this.state.selectedSurveyDataCountryNumericCode,
        undefined,
        this.signal.token
      )
      .then(response => {
        if (!response) {
          return;
        }

        this.setStateSafely({
          surveyData: normalizeSurveyData(response.data).map(datum => ({
            ...datum,
            id: createGuid(),
            include: !datum.exclude
          }))
        });
      });
  };

  onAddNewSurveyItem = item => {
    this.setStateSafely({
      surveyData: [...(this.state.surveyData || []), item]
    });
  };

  onEditSurveyItem = (item, updatedField, callback) => {
    const surveyData = [...this.state.surveyData];

    const itemIndex = findIndex(surveyData, "id", item.id);

    // noinspection JSValidateTypes
    surveyData[itemIndex] = synchonizeContraceptivePrevalence(
      item,
      updatedField
    );

    this.setStateSafely(
      {
        surveyData
      },
      callback
    );
  };

  onDeleteSurveyItem = itemId => {
    this.setStateSafely({
      surveyData: this.state.surveyData.filter(datum => datum.id !== itemId)
    });
  };

  onClearSurveyData = () => {
    this.setStateSafely({
      surveyData: undefined
    });
  };

  onChangeSurveyDatabaseName = databaseName => {
    this.setStateSafely({
      newSurveyDatabaseName: databaseName
    });
  };

  onSaveSurveyDatabase = () => {
    const databasePayload = this.createDatabasePayload(
      "survey",
      this.state.newSurveyDatabaseName,
      this.state.selectedSurveyDatabaseId,
      this.state.selectedSurveyDataCountryNumericCode,
      this.state.surveyData.map(({ include, ...rest }) => ({
        ...rest,
        possible_outlier : to01Null(rest.possible_outlier),
        possible_outlier_userinput : to01Null(rest.possible_outlier_userinput),
        hasGeographicalRegionBias: !!rest.geographicalRegionBiasReason,
        exclude: !include
      }))
    );

    api.saveDatabase(databasePayload, this.signal.token).then(response => {
      const { id } = response.data;

      const databases = {
        ...this.state.databases,
        [id]: this.createDatabase(databasePayload)
      };

      this.setStateSafely({
        databases,
        notificationVariant: "success",
        notificationOpen: true,
        notificationMessage: this.props.t(
          'Database "{{databaseName}}" was saved',
          {
            databaseName: databasePayload.name
          }
        )
      });
    });
  };

  onSelectPopulationDatabase = databaseId => {
    const countries = this.getCountries(databaseId);

    this.setStateSafely({
      selectedPopulationDatabaseId: databaseId,
      selectedPopulationDataCountryNumericCode:
        Object.keys(countries).length !== 1
          ? undefined
          : parseInt(Object.keys(countries)[0], 10)
    });
  };

  onSelectPopulationDataCountry = countryNumericCode => {
    this.setStateSafely({
      selectedPopulationDataCountryNumericCode: countryNumericCode
    });
  };

  onLoadCountryPopulationData = () => {
    api
      .fetchPopulationDatabase(
        this.state.selectedPopulationDatabaseId,
        this.state.selectedPopulationDataCountryNumericCode,
        this.signal.token
      )
      .then(response => {
        if (!response) {
          return;
        }

        this.setStateSafely({
          populationData: normalizePopulationData(
            response.data.filter(
              datum =>
                datum.divisionNumericCode ===
                  this.state.selectedPopulationDataCountryNumericCode &&
                datum.ageRange === "15-49"
            )
          ).map(datum => ({
            ...datum,
            id: createGuid()
          }))
        });
      });
  };

  onAddNewPopulationItem = item => {
    this.setStateSafely({
      populationData: [...(this.state.populationData || []), item]
    });
  };

  onEditPopulationItem = item => {
    const populationData = [...this.state.populationData];

    const itemIndex = findIndex(populationData, "id", item.id);
    populationData[itemIndex] = item;

    this.setStateSafely({
      populationData
    });
  };

  onDeletePopulationItem = itemId => {
    this.setStateSafely({
      populationData: this.state.populationData.filter(
        datum => datum.id !== itemId
      )
    });
  };

  onClearPopulationData = () => {
    this.setStateSafely({
      populationData: undefined
    });
  };

  onChangePopulationDatabaseName = databaseName => {
    this.setStateSafely({
      newPopulationDatabaseName: databaseName
    });
  };

  onSavePopulationDatabase = () => {
    const databasePayload = this.createDatabasePayload(
      "population",
      this.state.newPopulationDatabaseName,
      this.state.selectedPopulationDatabaseId,
      this.state.selectedPopulationDataCountryNumericCode,
      this.state.populationData
    );

    api.saveDatabase(databasePayload, this.signal.token).then(response => {
      const { id } = response.data;

      const databases = {
        ...this.state.databases,
        [id]: this.createDatabase(databasePayload)
      };

      this.setStateSafely({
        databases,
        notificationVariant: "success",
        notificationOpen: true,
        notificationMessage: this.props.t(
          'Database "{{databaseName}}" was saved',
          {
            databaseName: databasePayload.name
          }
        )
      });
    });
  };

  onSelectEmuDatabase = databaseId => {
    const countries = this.getCountries(
      databaseId || this.findDefaultDatabaseId()
    );

    this.setStateSafely({
      selectedEmuDatabaseId: databaseId,
      selectedEmuDataCountryNumericCode:
        Object.keys(countries).length !== 1
          ? undefined
          : parseInt(Object.keys(countries)[0], 10)
    });
  };

  onSelectEmuDataCountry = countryNumericCode => {
    this.setStateSafely({
      selectedEmuDataCountryNumericCode: countryNumericCode
    });
  };

  onLoadCountryEmuData = () => {
    api
      .fetchEmuDatabase(
        this.state.selectedEmuDatabaseId,
        this.state.selectedEmuDataCountryNumericCode,
        this.signal.token
      )
      .then(response => {
        if (!response) {
          return;
        }

        this.setStateSafely({
          emuData: normalizeEmuData(
            response.data.filter(
              datum =>
                datum.divisionNumericCode ===
                this.state.selectedEmuDataCountryNumericCode
            )
          ).map(datum => ({
            ...datum,
            id: createGuid()
          }))
        });
      });
  };

  onAddNewEmuItem = item => {
    this.setStateSafely({
      emuData: [...(this.state.emuData || []), item]
    });
  };

  onEditEmuItem = item => {
    const emuData = [...this.state.emuData];

    const itemIndex = findIndex(emuData, "id", item.id);
    emuData[itemIndex] = item;

    this.setStateSafely({
      emuData
    });
  };

  onDeleteEmuItem = itemId => {
    this.setStateSafely({
      emuData: this.state.emuData.filter(datum => datum.id !== itemId)
    });
  };

  onClearEmuData = () => {
    this.setStateSafely({
      emuData: undefined
    });
  };

  onChangeEmuDatabaseName = databaseName => {
    this.setStateSafely({
      newEmuDatabaseName: databaseName
    });
  };

  onSaveEmuDatabase = () => {
    const databasePayload = this.createDatabasePayload(
      "emu",
      this.state.newEmuDatabaseName,
      this.state.selectedEmuDatabaseId || this.findDefaultDatabaseId(),
      this.state.selectedEmuDataCountryNumericCode,
      this.state.emuData.map(({ startYear, endYear, ...rest }) => ({
        ...rest,
        year: startYear
      }))
    );

    api.saveDatabase(databasePayload, this.signal.token).then(response => {
      const { id } = response.data;

      const databases = {
        ...this.state.databases,
        [id]: this.createDatabase(databasePayload)
      };

      this.setStateSafely({
        databases,
        notificationVariant: "success",
        notificationOpen: true,
        notificationMessage: this.props.t(
          'Database "{{databaseName}}" was saved',
          {
            databaseName: databasePayload.name
          }
        )
      });
    });
  };

  onCloseNotification = () => {
    this.setStateSafely({
      notificationVariant: undefined,
      notificationOpen: false,
      notificationMessage: undefined
    });
  };

  componentDidMount() {
    api.fetchDatabases(this.signal.token).then(response => {
      if (!response) {
        return;
      }

      const databases = normalizeDatabases(response.data);

      this.setStateSafely({
        databases: databases,
        defaultCountries: getCountries(
          databases,
          findDefaultDatabaseId(databases)
        )
      });
    });
  }

  componentWillUnmount() {
    this.unmounting = true;
    this.signal.cancel();
  }

  render() {
    return (
      <PrepareData
        match={this.props.match}
        databases={this.state.databases}
        surveyDatabaseCountries={this.getCountries(
          this.state.selectedSurveyDatabaseId
        )}
        selectedSurveyDatabaseId={this.state.selectedSurveyDatabaseId}
        selectedSurveyDataCountryNumericCode={
          this.state.selectedSurveyDataCountryNumericCode
        }
        surveyData={this.state.surveyData}
        newSurveyDatabaseName={this.state.newSurveyDatabaseName}
        populationDatabaseCountries={this.getCountries(
          this.state.selectedPopulationDatabaseId
        )}
        selectedPopulationDatabaseId={this.state.selectedPopulationDatabaseId}
        selectedPopulationDataCountryNumericCode={
          this.state.selectedPopulationDataCountryNumericCode
        }
        populationData={this.state.populationData}
        newPopulationDatabaseName={this.state.newPopulationDatabaseName}
        emuDatabaseCountries={this.getCountries(
          this.state.selectedEmuDatabaseId || this.findDefaultDatabaseId()
        )}
        selectedEmuDatabaseId={this.state.selectedEmuDatabaseId}
        selectedEmuDataCountryNumericCode={
          this.state.selectedEmuDataCountryNumericCode
        }
        emuData={this.state.emuData}
        newEmuDatabaseName={this.state.newEmuDatabaseName}
        notificationVariant={this.state.notificationVariant}
        notificationOpen={this.state.notificationOpen}
        notificationMessage={this.state.notificationMessage}
        onSelectSurveyDatabase={this.onSelectSurveyDatabase}
        onSelectSurveyDataCountry={this.onSelectSurveyDataCountry}
        onLoadCountrySurveyData={this.onLoadCountrySurveyData}
        onAddNewSurveyItem={this.onAddNewSurveyItem}
        onEditSurveyItem={this.onEditSurveyItem}
        onDeleteSurveyItem={this.onDeleteSurveyItem}
        onClearSurveyData={this.onClearSurveyData}
        onChangeSurveyDatabaseName={this.onChangeSurveyDatabaseName}
        onSaveSurveyDatabase={this.onSaveSurveyDatabase}
        onSelectPopulationDatabase={this.onSelectPopulationDatabase}
        onSelectPopulationDataCountry={this.onSelectPopulationDataCountry}
        onLoadCountryPopulationData={this.onLoadCountryPopulationData}
        onAddNewPopulationItem={this.onAddNewPopulationItem}
        onEditPopulationItem={this.onEditPopulationItem}
        onDeletePopulationItem={this.onDeletePopulationItem}
        onClearPopulationData={this.onClearPopulationData}
        onChangePopulationDatabaseName={this.onChangePopulationDatabaseName}
        onSavePopulationDatabase={this.onSavePopulationDatabase}
        onSelectEmuDatabase={this.onSelectEmuDatabase}
        onSelectEmuDataCountry={this.onSelectEmuDataCountry}
        onLoadCountryEmuData={this.onLoadCountryEmuData}
        onAddNewEmuItem={this.onAddNewEmuItem}
        onEditEmuItem={this.onEditEmuItem}
        onDeleteEmuItem={this.onDeleteEmuItem}
        onClearEmuData={this.onClearEmuData}
        onChangeEmuDatabaseName={this.onChangeEmuDatabaseName}
        onSaveEmuDatabase={this.onSaveEmuDatabase}
        onCloseNotification={this.onCloseNotification}
      />
    );
  }
}

export default withTranslation()(withErrorBoundary(PrepareDataContainer));
