import React from "react";
import * as PropTypes from "prop-types";
import { managedLabeledValuePropType } from "../../propTypes";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const styles = {
  checkBox: {
    padding: 0
  },
  item: {
    padding: "4px 0"
  }
};

const ManageDataItems = props => (
  <List>
    {props.items.map(item => (
      <ListItem key={item.value} dense={true} className={props.classes.item}>
        <Checkbox
          checked={props.selectedItemValues.includes(item.value)}
          className={props.classes.checkBox}
          onChange={() => {
            props.onToggleItem(item.value);
          }}
        />
        <ListItemText
          primary={
            <>
              <span>{item.label}</span>

              {item.isGlobal && <sup>*</sup>}
              {item.isInUse && <sup>&dagger;</sup>}

              {item.createdAt && (
                <span>{` (${item.createdAt.toLocaleDateString()})`}</span>
              )}
            </>
          }
        />
      </ListItem>
    ))}
  </List>
);

ManageDataItems.propTypes = {
  items: PropTypes.arrayOf(managedLabeledValuePropType).isRequired,
  selectedItemValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleItem: PropTypes.func.isRequired
};

export default withStyles(styles)(ManageDataItems);
