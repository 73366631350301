import React from "react";
import * as PropTypes from "prop-types";
import { measurePropType } from "../propTypes";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import { withTranslation } from "react-i18next";

const MeasureSelector = ({ t, ...rest }) => (
  <div className={rest.className}>
    <FormControl>
      <InputLabel htmlFor="input" shrink={true}>
        {t(rest.label)}
      </InputLabel>

      <NativeSelect
        input={<Input id="input" />}
        value={rest.value}
        onChange={e => rest.onChange(e.target.value)}
      >
        <option value="percentage">{t("Percentage")}</option>
        <option value="count">{t("Count (in '000s)")}</option>
      </NativeSelect>
    </FormControl>
  </div>
);

MeasureSelector.propTypes = {
  label: PropTypes.string,
  value: measurePropType.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

MeasureSelector.defaultProps = {
  label: "Measure"
};

export default withTranslation()(MeasureSelector);
