import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { labeledValuePropType, surveyDatumPropType } from "../../propTypes";
import { maximumYear, minimumYear } from "../../constants";
import {
  formatNumericCell,
  parseNullableAlphaCell,
  parseNullableNumericCell,
  parseNumericCell,
} from "../../utilities";
import { createDefaultSurveyDatum } from "../../data";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { withTranslation } from "react-i18next";
import { translateGridText } from "../../i18n";
import BooleanCellRenderer from "../BooleanCellRenderer";
import CountrySelector from "./PrepareDataCountrySelector";
import Saver from "./PrepareDataSaver";

const styles = {
  button: {
    marginRight: 20
  },
  buttonGroup: {
    marginBottom: 20
  },
  regionEditor: {
    margin: "20px 0 20px"
  },
  table: {
    margin: "20px 0",
    height: 400
  }
};

class PrepareDataSurveyData extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  refreshCells = () => {
    this.gridApi.refreshCells();
  };

  onGridReady = params => {
    this.gridApi = params.api;

    params.columnApi.autoSizeColumns(
      params.columnApi.getAllColumns().map(column => column.colId)
    );
  };

  onSelectionChanged = () => {
    this.setState({
      rowSelected: this.gridApi.getSelectedRows().length > 0
    });
  };

  onAddNewItem = () => {
    const item = createDefaultSurveyDatum();

    const newRowData = this.gridApi.updateRowData({
      add: [item]
    });

    this.gridApi.ensureIndexVisible(newRowData.add[0].childIndex);

    this.props.onAddNewItem(item);
  };

  onDeleteItem = () => {
    const selectedItemIds = this.gridApi.getSelectedRows().map(item => item.id);

    if (!selectedItemIds.length) {
      return;
    }

    this.props.onDeleteItem(selectedItemIds[0]);

    this.setState({
      rowSelected: false
    });
  };

  render() {
    return (
      <div>
        <CountrySelector
          databases={this.props.databases}
          countries={this.props.countries}
          selectedDatabaseId={this.props.selectedDatabaseId}
          selectedCountryNumericCode={this.props.selectedCountryNumericCode}
          onSelectDatabase={this.props.onSelectDatabase}
          onSelectCountry={this.props.onSelectCountry}
          onLoadData={this.props.onLoadCountryData}
        />

        <div
          className={classNames("ag-theme-material", this.props.classes.table)}
        >
          <AgGridReact
            defaultColDef={{
              sortable: true,
              editable: true
            }}
            rowSelection="single"
            suppressColumnVirtualisation={true}
            suppressScrollOnNewData={true}
            frameworkComponents={{ booleanCellRenderer: BooleanCellRenderer }}
            overlayNoRowsTemplate="<span />"
            localeTextFunc={translateGridText}
            rowData={this.props.data}
            onGridReady={this.onGridReady}
            onSelectionChanged={this.onSelectionChanged}
            onCellValueChanged={params => {
              this.props.onEditItem(
                params.data,
                params.colDef.field,
                this.refreshCells
              );
            }}
          >
            <AgGridColumn
              headerName={this.props.t("Include?")}
              field="include"
              cellRenderer="booleanCellRenderer"
              cellEditor="agRichSelectCellEditor"
              cellEditorParams={{
                cellRenderer: "booleanCellRenderer",
                values: [true, false]
              }}
            />
            <AgGridColumn
              headerName={this.props.t("possible_outlier")}
              field="possible_outlier"
              cellEditor="agRichSelectCellEditor"
              cellEditorParams={{
                values: ["0", "1", null]
              }}
            />
            <AgGridColumn
              headerName={this.props.t("possible_outlier_userinput")}
              field="possible_outlier_userinput"
              cellEditor="agRichSelectCellEditor"
              cellEditorParams={{
                values: ["0", "1", null]
              }}
            />
            <AgGridColumn
              headerName={this.props.t("Region code")}
              field="regionCode"
              valueParser={params => parseNullableAlphaCell(params)}
            />
            <AgGridColumn
              headerName={this.props.t("Start year")}
              field="startDate"
              type="numericColumn"
              filter="agNumberColumnFilter"
              valueFormatter={params => formatNumericCell(params.value, 2)}
              valueParser={params =>
                parseNumericCell(
                  params,
                  minimumYear + 0.5,
                  params.node.data.endDate
                )
              }
            />
            <AgGridColumn
              headerName={this.props.t("End year")}
              field="endDate"
              type="numericColumn"
              filter="agNumberColumnFilter"
              valueFormatter={params => formatNumericCell(params.value, 2)}
              valueParser={params =>
                parseNumericCell(
                  params,
                  params.node.data.startDate,
                  maximumYear + 0.5
                )
              }
            />
            <AgGridColumn
              headerName={this.props.t("In union?")}
              field="isInUnion"
              cellRenderer="booleanCellRenderer"
              cellEditor="agRichSelectCellEditor"
              cellEditorParams={{
                cellRenderer: "booleanCellRenderer",
                values: [true, false]
              }}
            />
            <AgGridColumn
              headerName={this.props.t("Age")}
              field="ageRange"
              cellEditor="agRichSelectCellEditor"
              cellEditorParams={{
                values: [
                  "10-14",
                  "15-19",
                  "20-24",
                  "25-29",
                  "30-34",
                  "35-39",
                  "40-44",
                  "45-49",
                  "15-49"
                ]
              }}
            />
            <AgGridColumn
              headerName={this.props.t("Data series/type")}
              field="dataSeriesType"
              cellEditor="agRichSelectCellEditor"
              cellEditorParams={{
                values: ["DHS", "MICS", "National survey", "PMA", "Other"]
              }}
            />
            <AgGridColumn
              headerName={this.props.t("Population")}
              field="groupTypeRelativeToBaseline"
              headerTooltip={this.props.t("Group type relative to baseline")}
              cellEditor="agRichSelectCellEditor"
              cellEditorParams={{
                values: ["AL", "BS", "EM", "FM", "HW", "MW", "PW", "SA", "UW"]
              }}
            />
            <AgGridColumn
              headerName={this.props.t("Modern contraceptive use")}
              field="contraceptiveUseModern"
              type="numericColumn"
              filter="agNumberColumnFilter"
              valueFormatter={params => formatNumericCell(params.value, 3)}
              valueParser={params => parseNumericCell(params, 0, 1)}
              headerTooltip={this.props.t("Contraceptive use (modern)")}
            />
            <AgGridColumn
              headerName={this.props.t("Traditional contraceptive use")}
              field="contraceptiveUseTraditional"
              type="numericColumn"
              filter="agNumberColumnFilter"
              valueFormatter={params => formatNumericCell(params.value, 3)}
              valueParser={params => parseNumericCell(params, 0, 1)}
              headerTooltip={this.props.t("Contraceptive use (traditional)")}
            />
            <AgGridColumn
              headerName={this.props.t("All contraceptive use")}
              field="contraceptiveUseAny"
              type="numericColumn"
              filter="agNumberColumnFilter"
              valueFormatter={params => formatNumericCell(params.value, 3)}
              valueParser={params => parseNumericCell(params, 0, 1)}
              headerTooltip={this.props.t("Contraceptive use (all)")}
            />
            <AgGridColumn
              headerName={this.props.t("Modern methods unmet need")}
              field="unmetNeedModern"
              type="numericColumn"
              filter="agNumberColumnFilter"
              valueFormatter={params => formatNumericCell(params.value, 3)}
              valueParser={params => parseNullableNumericCell(params, 0, 1)}
              headerTooltip={this.props.t("Unmet need for modern methods")}
            />
            <AgGridColumn
              headerName={this.props.t("Any method unmet need")}
              field="unmetNeedAny"
              type="numericColumn"
              filter="agNumberColumnFilter"
              valueFormatter={params => formatNumericCell(params.value, 3)}
              valueParser={params => parseNullableNumericCell(params, 0, 1)}
              headerTooltip={this.props.t("Unmet need for any method")}
            />
            <AgGridColumn
              headerName={this.props.t(
                "Pertaining to methods used since last pregnancy"
              )}
              field="pertainingToMethodsUsedSinceLastPregnancyReason"
            />
            <AgGridColumn
              headerName={this.props.t("Geographical bias")}
              field="geographicalRegionBiasReason"
            />
            <AgGridColumn
              headerName={this.props.t(
                "Non-pregnant and other positive biases"
              )}
              field="nonPregnantAndOtherPositiveBiasesReason"
            />
            <AgGridColumn
              headerName={this.props.t("Age bias")}
              field="ageGroupBias"
              cellEditor="agRichSelectCellEditor"
              cellEditorParams={{
                values: ["+", "-", "?", "None"]
              }}
            />
            <AgGridColumn
              headerName={this.props.t("Modern method bias")}
              field="modernMethodBias"
              cellEditor="agRichSelectCellEditor"
              cellEditorParams={{
                values: ["+", "-", "None"]
              }}
            />
            <AgGridColumn
              headerName={this.props.t("Traditional method bias")}
              field="traditionalMethodBiasReason"
            />
            <AgGridColumn
              headerName={this.props.t("Absence of probing question bias?")}
              field="hasAbsenceOfProbingQuestionsBias"
              cellRenderer="booleanCellRenderer"
              cellEditor="agRichSelectCellEditor"
              cellEditorParams={{
                cellRenderer: "booleanCellRenderer",
                values: [true, false]
              }}
            />
            <AgGridColumn
              headerName={this.props.t("SE log-ratio modern/no use")}
              field="seLogRModernNoUse"
              type="numericColumn"
              filter="agNumberColumnFilter"
              valueFormatter={params => formatNumericCell(params.value, 2)}
              valueParser={params =>
                parseNullableNumericCell(params, 0, Number.MAX_VALUE)
              }
              headerTooltip={this.props.t(
                "Sampling error for the log-ratio of modern contraceptive method use to no use"
              )}
            />
            <AgGridColumn
              headerName={this.props.t("SE log-ratio traditional/no use")}
              field="seLogRTraditionalNoUse"
              type="numericColumn"
              filter="agNumberColumnFilter"
              valueFormatter={params => formatNumericCell(params.value, 2)}
              valueParser={params =>
                parseNullableNumericCell(params, 0, Number.MAX_VALUE)
              }
              headerTooltip={this.props.t(
                "Sampling error for the log-ratio of traditional contraceptive method use to no use"
              )}
            />
            <AgGridColumn
              headerName={this.props.t("SE log-ratio unmet/no need")}
              field="seLogRUnmetNoNeed"
              type="numericColumn"
              filter="agNumberColumnFilter"
              valueFormatter={params => formatNumericCell(params.value, 2)}
              valueParser={params =>
                parseNullableNumericCell(params, 0, Number.MAX_VALUE)
              }
              headerTooltip={this.props.t(
                "Sampling error for the log-ratio of unmet need (any method) to no need"
              )}
            />
            <AgGridColumn
              headerName={this.props.t("Source ID")}
              field="sourceId"
              type="numericColumn"
            />
          </AgGridReact>
        </div>

        <div className={this.props.classes.buttonGroup}>
          <Button
            variant="contained"
            color="primary"
            className={this.props.classes.button}
            onClick={this.onAddNewItem}
          >
            {this.props.t("Add new record")}
          </Button>

          <Button
            variant="contained"
            color="secondary"
            disabled={!this.state.rowSelected}
            className={this.props.classes.button}
            onClick={this.onDeleteItem}
          >
            {this.props.t("Delete selected row")}
          </Button>

          <Button
            variant="contained"
            color="secondary"
            className={this.props.classes.button}
            onClick={this.props.onClearData}
          >
            {this.props.t("Clear data")}
          </Button>
        </div>

        <Saver
          name={this.props.newDatabaseName}
          saveDisabled={
            (!this.props.regionData &&
              !this.props.selectedCountryNumericCode) ||
            (this.props.regionData &&
              (!this.props.regionNumericCode ||
                !this.props.regionAlphaCode ||
                !this.props.regionName)) ||
            !this.props.newDatabaseName
          }
          onChangeName={this.props.onChangeDatabaseName}
          onSave={this.props.onSave}
        />
      </div>
    );
  }
}

PrepareDataSurveyData.propTypes = {
  databases: PropTypes.arrayOf(labeledValuePropType),
  countries: PropTypes.arrayOf(labeledValuePropType),
  selectedDatabaseId: PropTypes.string,
  selectedCountryNumericCode: PropTypes.number,
  data: PropTypes.arrayOf(surveyDatumPropType),
  newDatabaseName: PropTypes.string,
  onSelectDatabase: PropTypes.func.isRequired,
  onSelectCountry: PropTypes.func.isRequired,
  onLoadCountryData: PropTypes.func.isRequired,
  onAddNewItem: PropTypes.func.isRequired,
  onEditItem: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  onClearData: PropTypes.func.isRequired,
  onChangeDatabaseName: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

PrepareDataSurveyData.defaultProps = {
  databases: [],
  countries: [],
  data: []
};

export default withTranslation()(withStyles(styles)(PrepareDataSurveyData));
