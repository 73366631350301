import MockAdapter from "axios-mock-adapter";
import * as queryString from "query-string";
import { createGuid } from "../utilities";
import { chartSets } from "../constants";
import {
  databases as testDatabases,
  emuData as testEmuData,
  populationData as testPopulationData,
  results as testResults,
  runs as testRuns,
  surveyData as testSurveyData
} from "./mockApiData";

const extractUrlPathComponents = url => url.replace(/\/$/, "").split("/");

const extractUrlPathComponent = (url, index) =>
  extractUrlPathComponents(url).slice(index)[0];

const getQueryString = url => url.split("?")[1];

class MockApiAdapter extends MockAdapter {
  constructor(webService) {
    super(webService, { delayResponse: 1000 });

    this.onGet(/\/api\/v1\/systeminfo/).reply(200, {
      systemOperational: true,
      level: 0,
      message: null
    });

    this.onPost(/\/api\/v1\/session\/.+/).reply(201, {
      token: "9de345262da94df5af666a043faea701"
    });

    this.onDelete(/\/api\/v1\/session\/.+/).reply(204);

    this.onGet(/\/api\/v1\/databases/).reply(200, testDatabases);

    this.onPost(/\/api\/v1\/databases\/files\/.+/).reply(config => [
      201,
      {
        id: createGuid(),
        type: extractUrlPathComponent(config.url, -1),
        name: createGuid().slice(0, 8),
        divisions: [
          {
            numericCode: 50,
            name: "Bangladesh",
            alphaCode: "BGD",
            isCountry: true
          }
        ]
      }
    ]);

    this.onPost(/\/api\/v1\/runs/).reply(config => {
      console.log("[POST] /api/v1/runs/ request body:");
      console.log(config.data);

      return [201];
    });

    this.onGet(/\/api\/v1\/runs/).reply(config => {
      const {
        includePending: includePendingRuns,
        includeFailed: includeFailedRuns
      } = queryString.parse(getQueryString(config.url));

      let runs = testRuns;

      if (!includePendingRuns) {
        runs = runs.filter(run => run.completed);
      }

      if (!includeFailedRuns) {
        runs = runs.filter(run => !run.failed);
      }

      return [200, runs];
    });

    this.onGet(/\/api\/v1\/surveys\/.+\/.+/).reply(config => {
      const divisionNumericCodeOrRegion = extractUrlPathComponent(
        config.url,
        -1
      );

      const divisionNumericCode = parseInt(divisionNumericCodeOrRegion, 10);

      let data = testSurveyData;

      if (divisionNumericCode) {
        data = data.map(datum => ({
          ...datum,
          divisionNumericCode: divisionNumericCode
        }));
      } else if (divisionNumericCodeOrRegion) {
        data = data.map(datum => ({
          ...datum,
          regionCode: divisionNumericCodeOrRegion
        }));
      }

      return [200, data];
    });

    this.onGet(/\/api\/v1\/populations\/.+\/.+/).reply(config => {
      const divisionNumericCodeOrRegion = extractUrlPathComponent(
        config.url,
        -1
      );

      const divisionNumericCode = parseInt(divisionNumericCodeOrRegion, 10);

      let data = testPopulationData;

      if (divisionNumericCode) {
        data = data.map(datum => ({
          ...datum,
          divisionNumericCode: divisionNumericCode
        }));
      } else if (divisionNumericCodeOrRegion) {
        data = data.map(datum => ({
          ...datum,
          regionCode: divisionNumericCodeOrRegion
        }));
      }

      return [200, data];
    });

    this.onGet(/\/api\/v1\/emu\/.+\/.+/).reply(config => {
      const divisionNumericCodeOrRegion = extractUrlPathComponent(
        config.url,
        -1
      );

      const divisionNumericCode = parseInt(divisionNumericCodeOrRegion, 10);

      let data = testEmuData;

      if (divisionNumericCode) {
        data = data.map(datum => ({
          ...datum,
          divisionNumericCode: divisionNumericCode
        }));
      } else if (divisionNumericCodeOrRegion) {
        data = data.map(datum => ({
          ...datum,
          regionCode: divisionNumericCodeOrRegion
        }));
      }

      return [200, data];
    });

    this.onGet(/\/api\/v1\/results\/.+\/.+/).reply(config => {
      console.log("[GET] /api/v1/results response:");
      console.log(testResults);

      const { comparisonRun: comparisonRunId } = queryString.parse(
        getQueryString(config.url)
      );

      let results = testResults;

      if (comparisonRunId) {
        results = {
          proportions: results.proportions.map(datum => ({
            ...datum,
            comparisonMean: datum.mean * 0.95,
            comparisonPercentile2pt5: datum.percentile2pt5 * 0.95,
            comparisonPercentile5: datum.percentile5 * 0.95,
            comparisonPercentile10: datum.percentile10 * 0.95,
            comparisonMedian: datum.mean * 0.95,
            comparisonPercentile90: datum.percentile90 * 0.95,
            comparisonPercentile95: datum.percentile95 * 0.95,
            comparisonPercentile97pt5: datum.percentile95 * 0.95
          })),
          populations: results.populations.map(datum => ({
            ...datum,
            comparisonMean: datum.mean * 0.95,
            comparisonPercentile2pt5: datum.percentile2pt5 * 0.95,
            comparisonPercentile5: datum.percentile5 * 0.95,
            comparisonPercentile10: datum.percentile10 * 0.95,
            comparisonMedian: datum.mean * 0.95,
            comparisonPercentile90: datum.percentile90 * 0.95,
            comparisonPercentile95: datum.percentile95 * 0.95,
            comparisonPercentile97pt5: datum.percentile95 * 0.95
          }))
        };
      }

      return [200, results];
    });

    this.onGet(/\/api\/v1\/csv\/results\/.+\/.+/).reply(200, "[CSV data]");

    this.onGet(/\/api\/v1\/csv\/dataset\/.+\/.+/).reply(200, "[ZIP archive]");

    this.onGet(/\/api\/v1\/chartsets\/?$/).reply(200, chartSets);

    this.onGet(/\/api\/v1\/chartsets\/.+\/.+\/.+\/.+\/.+/).reply(
      200,
      "[Chart set PDF document]"
    );

    this.onGet(/\/api\/v1\/charts\/.+\/.+\/.+\/.+/).reply(
      200,
      "[Selected charts PDF document]"
    );

    this.onPost(/\/api\/v1\/targets\/.+\/.+/).reply(config => {
      const data = JSON.parse(config.data);

      return [
        201,
        {
          indicatorProbability: data.indicatorProbabilityParameters
            ? 0.5
            : undefined,
          indicatorLevel: data.indicatorLevelParameters ? 5000 : undefined,
          populationProbability: data.populationProbabilityParameters
            ? 0.6
            : undefined,
          populationLevel: data.populationLevelParameters ? 6000 : undefined
        }
      ];
    });

    this.onPost(/\/api\/v1\/progress\/.+\/.+/).reply(config => {
      const data = JSON.parse(config.data);

      return [
        201,
        {
          indicatorProgress: data.indicatorProgressParameters
            ? [0.1, 0.3, 0.65]
            : undefined,
          contraceptiveMethodProgress: data.contraceptiveMethodProgressParameters
            ? [0, 0.4, 0.5]
            : undefined
        }
      ];
    });

    this.onGet(/\/api\/v1\/logs\/.+/).reply(200, "OK!");

    this.onPost(/\/api\/v1\/databases\/documents\/.+/).reply(config => {
      console.log("[POST] /api/v1/databases/documents request body:");
      console.log(config.data);

      // noinspection JSUnusedLocalSymbols
      const { data, ...database } = JSON.parse(config.data);

      return [
        201,
        {
          id: createGuid(),
          ...database
        }
      ];
    });

    this.onGet(/\/api\/v1\/csv\/databases/).reply(
      200,
      "[Archive of selected databases]"
    );

    this.onDelete(/\/api\/v1\/databases/).reply(204);

    this.onDelete(/\/api\/v1\/runs/).reply(204);
  }
}

export default MockApiAdapter;
