import { indicators } from "../constants";

const divisions = [
  {
    numericCode: 1,
    name: "World",
    alphaCode: "001",
    isCountry: false
  },
  {
    numericCode: 2,
    name: "Africa",
    alphaCode: "002",
    isCountry: false
  },
  {
    numericCode: 15,
    name: "Northern Africa",
    alphaCode: "015",
    isCountry: false
  },
  {
    numericCode: 12,
    name: "Algeria",
    alphaCode: "DZA",
    isCountry: true
  },
  {
    numericCode: 818,
    name: "Egypt",
    alphaCode: "EGY",
    isCountry: true
  },
  {
    numericCode: 434,
    name: "Libya",
    alphaCode: "LBY",
    isCountry: true
  },
  {
    numericCode: 504,
    name: "Morocco",
    alphaCode: "MAR",
    isCountry: true
  },
  {
    numericCode: 729,
    name: "Sudan",
    alphaCode: "SDN",
    isCountry: true
  },
  {
    numericCode: 788,
    name: "Tunisia",
    alphaCode: "TUN",
    isCountry: true
  },
  {
    numericCode: 732,
    name: "Western Sahara",
    alphaCode: "ESH",
    isCountry: true
  },
  {
    numericCode: 202,
    name: "Sub-Saharan Africa",
    alphaCode: "202",
    isCountry: false
  },
  {
    numericCode: 14,
    name: "Eastern Africa",
    alphaCode: "014",
    isCountry: false
  },
  {
    numericCode: 86,
    name: "British Indian Ocean Territory",
    alphaCode: "IOT",
    isCountry: true
  },
  {
    numericCode: 108,
    name: "Burundi",
    alphaCode: "BDI",
    isCountry: true
  },
  {
    numericCode: 174,
    name: "Comoros",
    alphaCode: "COM",
    isCountry: true
  },
  {
    numericCode: 262,
    name: "Djibouti",
    alphaCode: "DJI",
    isCountry: true
  },
  {
    numericCode: 232,
    name: "Eritrea",
    alphaCode: "ERI",
    isCountry: true
  },
  {
    numericCode: 231,
    name: "Ethiopia",
    alphaCode: "ETH",
    isCountry: true
  },
  {
    numericCode: 260,
    name: "French Southern Territories",
    alphaCode: "ATF",
    isCountry: true
  },
  {
    numericCode: 404,
    name: "Kenya",
    alphaCode: "KEN",
    isCountry: true
  },
  {
    numericCode: 450,
    name: "Madagascar",
    alphaCode: "MDG",
    isCountry: true
  },
  {
    numericCode: 454,
    name: "Malawi",
    alphaCode: "MWI",
    isCountry: true
  },
  {
    numericCode: 480,
    name: "Mauritius",
    alphaCode: "MUS",
    isCountry: true
  },
  {
    numericCode: 175,
    name: "Mayotte",
    alphaCode: "MYT",
    isCountry: true
  },
  {
    numericCode: 508,
    name: "Mozambique",
    alphaCode: "MOZ",
    isCountry: true
  },
  {
    numericCode: 638,
    name: "Réunion",
    alphaCode: "REU",
    isCountry: true
  },
  {
    numericCode: 646,
    name: "Rwanda",
    alphaCode: "RWA",
    isCountry: true
  },
  {
    numericCode: 690,
    name: "Seychelles",
    alphaCode: "SYC",
    isCountry: true
  },
  {
    numericCode: 706,
    name: "Somalia",
    alphaCode: "SOM",
    isCountry: true
  },
  {
    numericCode: 728,
    name: "South Sudan",
    alphaCode: "SSD",
    isCountry: true
  },
  {
    numericCode: 800,
    name: "Uganda",
    alphaCode: "UGA",
    isCountry: true
  },
  {
    numericCode: 834,
    name: "United Republic of Tanzania",
    alphaCode: "TZA",
    isCountry: true
  },
  {
    numericCode: 894,
    name: "Zambia",
    alphaCode: "ZMB",
    isCountry: true
  },
  {
    numericCode: 716,
    name: "Zimbabwe",
    alphaCode: "ZWE",
    isCountry: true
  },
  {
    numericCode: 17,
    name: "Middle Africa",
    alphaCode: "017",
    isCountry: false
  },
  {
    numericCode: 24,
    name: "Angola",
    alphaCode: "AGO",
    isCountry: true
  },
  {
    numericCode: 120,
    name: "Cameroon",
    alphaCode: "CMR",
    isCountry: true
  },
  {
    numericCode: 140,
    name: "Central African Republic",
    alphaCode: "CAF",
    isCountry: true
  },
  {
    numericCode: 148,
    name: "Chad",
    alphaCode: "TCD",
    isCountry: true
  },
  {
    numericCode: 178,
    name: "Congo",
    alphaCode: "COG",
    isCountry: true
  },
  {
    numericCode: 180,
    name: "Democratic Republic of the Congo",
    alphaCode: "COD",
    isCountry: true
  },
  {
    numericCode: 226,
    name: "Equatorial Guinea",
    alphaCode: "GNQ",
    isCountry: true
  },
  {
    numericCode: 266,
    name: "Gabon",
    alphaCode: "GAB",
    isCountry: true
  },
  {
    numericCode: 678,
    name: "Sao Tome and Principe",
    alphaCode: "STP",
    isCountry: true
  },
  {
    numericCode: 18,
    name: "Southern Africa",
    alphaCode: "018",
    isCountry: false
  },
  {
    numericCode: 72,
    name: "Botswana",
    alphaCode: "BWA",
    isCountry: true
  },
  {
    numericCode: 748,
    name: "Eswatini",
    alphaCode: "SWZ",
    isCountry: true
  },
  {
    numericCode: 426,
    name: "Lesotho",
    alphaCode: "LSO",
    isCountry: true
  },
  {
    numericCode: 516,
    name: "Namibia",
    alphaCode: "NAM",
    isCountry: true
  },
  {
    numericCode: 710,
    name: "South Africa",
    alphaCode: "ZAF",
    isCountry: true
  },
  {
    numericCode: 11,
    name: "Western Africa",
    alphaCode: "011",
    isCountry: false
  },
  {
    numericCode: 204,
    name: "Benin",
    alphaCode: "BEN",
    isCountry: true
  },
  {
    numericCode: 854,
    name: "Burkina Faso",
    alphaCode: "BFA",
    isCountry: true
  },
  {
    numericCode: 132,
    name: "Cabo Verde",
    alphaCode: "CPV",
    isCountry: true
  },
  {
    numericCode: 384,
    name: "Côte d’Ivoire",
    alphaCode: "CIV",
    isCountry: true
  },
  {
    numericCode: 270,
    name: "Gambia",
    alphaCode: "GMB",
    isCountry: true
  },
  {
    numericCode: 288,
    name: "Ghana",
    alphaCode: "GHA",
    isCountry: true
  },
  {
    numericCode: 324,
    name: "Guinea",
    alphaCode: "GIN",
    isCountry: true
  },
  {
    numericCode: 624,
    name: "Guinea-Bissau",
    alphaCode: "GNB",
    isCountry: true
  },
  {
    numericCode: 430,
    name: "Liberia",
    alphaCode: "LBR",
    isCountry: true
  },
  {
    numericCode: 466,
    name: "Mali",
    alphaCode: "MLI",
    isCountry: true
  },
  {
    numericCode: 478,
    name: "Mauritania",
    alphaCode: "MRT",
    isCountry: true
  },
  {
    numericCode: 562,
    name: "Niger",
    alphaCode: "NER",
    isCountry: true
  },
  {
    numericCode: 566,
    name: "Nigeria",
    alphaCode: "NGA",
    isCountry: true
  },
  {
    numericCode: 654,
    name: "Saint Helena",
    alphaCode: "SHN",
    isCountry: true
  },
  {
    numericCode: 686,
    name: "Senegal",
    alphaCode: "SEN",
    isCountry: true
  },
  {
    numericCode: 694,
    name: "Sierra Leone",
    alphaCode: "SLE",
    isCountry: true
  },
  {
    numericCode: 768,
    name: "Togo",
    alphaCode: "TGO",
    isCountry: true
  },
  {
    numericCode: 19,
    name: "Americas",
    alphaCode: "019",
    isCountry: false
  },
  {
    numericCode: 419,
    name: "Latin America and the Caribbean",
    alphaCode: "419",
    isCountry: false
  },
  {
    numericCode: 29,
    name: "Caribbean",
    alphaCode: "029",
    isCountry: false
  },
  {
    numericCode: 660,
    name: "Anguilla",
    alphaCode: "AIA",
    isCountry: true
  },
  {
    numericCode: 28,
    name: "Antigua and Barbuda",
    alphaCode: "ATG",
    isCountry: true
  },
  {
    numericCode: 533,
    name: "Aruba",
    alphaCode: "ABW",
    isCountry: true
  },
  {
    numericCode: 44,
    name: "Bahamas",
    alphaCode: "BHS",
    isCountry: true
  },
  {
    numericCode: 52,
    name: "Barbados",
    alphaCode: "BRB",
    isCountry: true
  },
  {
    numericCode: 535,
    name: "Bonaire, Sint Eustatius and Saba",
    alphaCode: "BES",
    isCountry: true
  },
  {
    numericCode: 92,
    name: "British Virgin Islands",
    alphaCode: "VGB",
    isCountry: true
  },
  {
    numericCode: 136,
    name: "Cayman Islands",
    alphaCode: "CYM",
    isCountry: true
  },
  {
    numericCode: 192,
    name: "Cuba",
    alphaCode: "CUB",
    isCountry: true
  },
  {
    numericCode: 531,
    name: "Curaçao",
    alphaCode: "CUW",
    isCountry: true
  },
  {
    numericCode: 212,
    name: "Dominica",
    alphaCode: "DMA",
    isCountry: true
  },
  {
    numericCode: 214,
    name: "Dominican Republic",
    alphaCode: "DOM",
    isCountry: true
  },
  {
    numericCode: 308,
    name: "Grenada",
    alphaCode: "GRD",
    isCountry: true
  },
  {
    numericCode: 312,
    name: "Guadeloupe",
    alphaCode: "GLP",
    isCountry: true
  },
  {
    numericCode: 332,
    name: "Haiti",
    alphaCode: "HTI",
    isCountry: true
  },
  {
    numericCode: 388,
    name: "Jamaica",
    alphaCode: "JAM",
    isCountry: true
  },
  {
    numericCode: 474,
    name: "Martinique",
    alphaCode: "MTQ",
    isCountry: true
  },
  {
    numericCode: 500,
    name: "Montserrat",
    alphaCode: "MSR",
    isCountry: true
  },
  {
    numericCode: 630,
    name: "Puerto Rico",
    alphaCode: "PRI",
    isCountry: true
  },
  {
    numericCode: 652,
    name: "Saint Barthélemy",
    alphaCode: "BLM",
    isCountry: true
  },
  {
    numericCode: 659,
    name: "Saint Kitts and Nevis",
    alphaCode: "KNA",
    isCountry: true
  },
  {
    numericCode: 662,
    name: "Saint Lucia",
    alphaCode: "LCA",
    isCountry: true
  },
  {
    numericCode: 663,
    name: "Saint Martin (French Part)",
    alphaCode: "MAF",
    isCountry: true
  },
  {
    numericCode: 670,
    name: "Saint Vincent and the Grenadines",
    alphaCode: "VCT",
    isCountry: true
  },
  {
    numericCode: 534,
    name: "Sint Maarten (Dutch part)",
    alphaCode: "SXM",
    isCountry: true
  },
  {
    numericCode: 780,
    name: "Trinidad and Tobago",
    alphaCode: "TTO",
    isCountry: true
  },
  {
    numericCode: 796,
    name: "Turks and Caicos Islands",
    alphaCode: "TCA",
    isCountry: true
  },
  {
    numericCode: 850,
    name: "United States Virgin Islands",
    alphaCode: "VIR",
    isCountry: true
  },
  {
    numericCode: 13,
    name: "Central America",
    alphaCode: "013",
    isCountry: false
  },
  {
    numericCode: 84,
    name: "Belize",
    alphaCode: "BLZ",
    isCountry: true
  },
  {
    numericCode: 188,
    name: "Costa Rica",
    alphaCode: "CRI",
    isCountry: true
  },
  {
    numericCode: 222,
    name: "El Salvador",
    alphaCode: "SLV",
    isCountry: true
  },
  {
    numericCode: 320,
    name: "Guatemala",
    alphaCode: "GTM",
    isCountry: true
  },
  {
    numericCode: 340,
    name: "Honduras",
    alphaCode: "HND",
    isCountry: true
  },
  {
    numericCode: 484,
    name: "Mexico",
    alphaCode: "MEX",
    isCountry: true
  },
  {
    numericCode: 558,
    name: "Nicaragua",
    alphaCode: "NIC",
    isCountry: true
  },
  {
    numericCode: 591,
    name: "Panama",
    alphaCode: "PAN",
    isCountry: true
  },
  {
    numericCode: 5,
    name: "South America",
    alphaCode: "005",
    isCountry: false
  },
  {
    numericCode: 32,
    name: "Argentina",
    alphaCode: "ARG",
    isCountry: true
  },
  {
    numericCode: 68,
    name: "Bolivia (Plurinational State of)",
    alphaCode: "BOL",
    isCountry: true
  },
  {
    numericCode: 74,
    name: "Bouvet Island",
    alphaCode: "BVT",
    isCountry: true
  },
  {
    numericCode: 76,
    name: "Brazil",
    alphaCode: "BRA",
    isCountry: true
  },
  {
    numericCode: 152,
    name: "Chile",
    alphaCode: "CHL",
    isCountry: true
  },
  {
    numericCode: 170,
    name: "Colombia",
    alphaCode: "COL",
    isCountry: true
  },
  {
    numericCode: 218,
    name: "Ecuador",
    alphaCode: "ECU",
    isCountry: true
  },
  {
    numericCode: 238,
    name: "Falkland Islands (Malvinas)",
    alphaCode: "FLK",
    isCountry: true
  },
  {
    numericCode: 254,
    name: "French Guiana",
    alphaCode: "GUF",
    isCountry: true
  },
  {
    numericCode: 328,
    name: "Guyana",
    alphaCode: "GUY",
    isCountry: true
  },
  {
    numericCode: 600,
    name: "Paraguay",
    alphaCode: "PRY",
    isCountry: true
  },
  {
    numericCode: 604,
    name: "Peru",
    alphaCode: "PER",
    isCountry: true
  },
  {
    numericCode: 239,
    name: "South Georgia and the South Sandwich Islands",
    alphaCode: "SGS",
    isCountry: true
  },
  {
    numericCode: 740,
    name: "Suriname",
    alphaCode: "SUR",
    isCountry: true
  },
  {
    numericCode: 858,
    name: "Uruguay",
    alphaCode: "URY",
    isCountry: true
  },
  {
    numericCode: 862,
    name: "Venezuela (Bolivarian Republic of)",
    alphaCode: "VEN",
    isCountry: true
  },
  {
    numericCode: 21,
    name: "Northern America",
    alphaCode: "021",
    isCountry: false
  },
  {
    numericCode: 60,
    name: "Bermuda",
    alphaCode: "BMU",
    isCountry: true
  },
  {
    numericCode: 124,
    name: "Canada",
    alphaCode: "CAN",
    isCountry: true
  },
  {
    numericCode: 304,
    name: "Greenland",
    alphaCode: "GRL",
    isCountry: true
  },
  {
    numericCode: 666,
    name: "Saint Pierre and Miquelon",
    alphaCode: "SPM",
    isCountry: true
  },
  {
    numericCode: 840,
    name: "United States of America",
    alphaCode: "USA",
    isCountry: true
  },
  {
    numericCode: 10,
    name: "Antarctica",
    alphaCode: "ATA",
    isCountry: true
  },
  {
    numericCode: 142,
    name: "Asia",
    alphaCode: "142",
    isCountry: false
  },
  {
    numericCode: 143,
    name: "Central Asia",
    alphaCode: "143",
    isCountry: false
  },
  {
    numericCode: 398,
    name: "Kazakhstan",
    alphaCode: "KAZ",
    isCountry: true
  },
  {
    numericCode: 417,
    name: "Kyrgyzstan",
    alphaCode: "KGZ",
    isCountry: true
  },
  {
    numericCode: 762,
    name: "Tajikistan",
    alphaCode: "TJK",
    isCountry: true
  },
  {
    numericCode: 795,
    name: "Turkmenistan",
    alphaCode: "TKM",
    isCountry: true
  },
  {
    numericCode: 860,
    name: "Uzbekistan",
    alphaCode: "UZB",
    isCountry: true
  },
  {
    numericCode: 30,
    name: "Eastern Asia",
    alphaCode: "030",
    isCountry: false
  },
  {
    numericCode: 156,
    name: "China",
    alphaCode: "CHN",
    isCountry: true
  },
  {
    numericCode: 344,
    name: "China, Hong Kong Special Administrative Region",
    alphaCode: "HKG",
    isCountry: true
  },
  {
    numericCode: 446,
    name: "China, Macao Special Administrative Region",
    alphaCode: "MAC",
    isCountry: true
  },
  {
    numericCode: 408,
    name: "Democratic People's Republic of Korea",
    alphaCode: "PRK",
    isCountry: true
  },
  {
    numericCode: 392,
    name: "Japan",
    alphaCode: "JPN",
    isCountry: true
  },
  {
    numericCode: 496,
    name: "Mongolia",
    alphaCode: "MNG",
    isCountry: true
  },
  {
    numericCode: 410,
    name: "Republic of Korea",
    alphaCode: "KOR",
    isCountry: true
  },
  {
    numericCode: 35,
    name: "South-eastern Asia",
    alphaCode: "035",
    isCountry: false
  },
  {
    numericCode: 96,
    name: "Brunei Darussalam",
    alphaCode: "BRN",
    isCountry: true
  },
  {
    numericCode: 116,
    name: "Cambodia",
    alphaCode: "KHM",
    isCountry: true
  },
  {
    numericCode: 360,
    name: "Indonesia",
    alphaCode: "IDN",
    isCountry: true
  },
  {
    numericCode: 418,
    name: "Lao People's Democratic Republic",
    alphaCode: "LAO",
    isCountry: true
  },
  {
    numericCode: 458,
    name: "Malaysia",
    alphaCode: "MYS",
    isCountry: true
  },
  {
    numericCode: 104,
    name: "Myanmar",
    alphaCode: "MMR",
    isCountry: true
  },
  {
    numericCode: 608,
    name: "Philippines",
    alphaCode: "PHL",
    isCountry: true
  },
  {
    numericCode: 702,
    name: "Singapore",
    alphaCode: "SGP",
    isCountry: true
  },
  {
    numericCode: 764,
    name: "Thailand",
    alphaCode: "THA",
    isCountry: true
  },
  {
    numericCode: 626,
    name: "Timor-Leste",
    alphaCode: "TLS",
    isCountry: true
  },
  {
    numericCode: 704,
    name: "Viet Nam",
    alphaCode: "VNM",
    isCountry: true
  },
  {
    numericCode: 34,
    name: "Southern Asia",
    alphaCode: "034",
    isCountry: false
  },
  {
    numericCode: 4,
    name: "Afghanistan",
    alphaCode: "AFG",
    isCountry: true
  },
  {
    numericCode: 50,
    name: "Bangladesh",
    alphaCode: "BGD",
    isCountry: true
  },
  {
    numericCode: 64,
    name: "Bhutan",
    alphaCode: "BTN",
    isCountry: true
  },
  {
    numericCode: 356,
    name: "India",
    alphaCode: "IND",
    isCountry: true
  },
  {
    numericCode: 364,
    name: "Iran (Islamic Republic of)",
    alphaCode: "IRN",
    isCountry: true
  },
  {
    numericCode: 462,
    name: "Maldives",
    alphaCode: "MDV",
    isCountry: true
  },
  {
    numericCode: 524,
    name: "Nepal",
    alphaCode: "NPL",
    isCountry: true
  },
  {
    numericCode: 586,
    name: "Pakistan",
    alphaCode: "PAK",
    isCountry: true
  },
  {
    numericCode: 144,
    name: "Sri Lanka",
    alphaCode: "LKA",
    isCountry: true
  },
  {
    numericCode: 145,
    name: "Western Asia",
    alphaCode: "145",
    isCountry: false
  },
  {
    numericCode: 51,
    name: "Armenia",
    alphaCode: "ARM",
    isCountry: true
  },
  {
    numericCode: 31,
    name: "Azerbaijan",
    alphaCode: "AZE",
    isCountry: true
  },
  {
    numericCode: 48,
    name: "Bahrain",
    alphaCode: "BHR",
    isCountry: true
  },
  {
    numericCode: 196,
    name: "Cyprus",
    alphaCode: "CYP",
    isCountry: true
  },
  {
    numericCode: 268,
    name: "Georgia",
    alphaCode: "GEO",
    isCountry: true
  },
  {
    numericCode: 368,
    name: "Iraq",
    alphaCode: "IRQ",
    isCountry: true
  },
  {
    numericCode: 376,
    name: "Israel",
    alphaCode: "ISR",
    isCountry: true
  },
  {
    numericCode: 400,
    name: "Jordan",
    alphaCode: "JOR",
    isCountry: true
  },
  {
    numericCode: 414,
    name: "Kuwait",
    alphaCode: "KWT",
    isCountry: true
  },
  {
    numericCode: 422,
    name: "Lebanon",
    alphaCode: "LBN",
    isCountry: true
  },
  {
    numericCode: 512,
    name: "Oman",
    alphaCode: "OMN",
    isCountry: true
  },
  {
    numericCode: 634,
    name: "Qatar",
    alphaCode: "QAT",
    isCountry: true
  },
  {
    numericCode: 682,
    name: "Saudi Arabia",
    alphaCode: "SAU",
    isCountry: true
  },
  {
    numericCode: 275,
    name: "State of Palestine",
    alphaCode: "PSE",
    isCountry: true
  },
  {
    numericCode: 760,
    name: "Syrian Arab Republic",
    alphaCode: "SYR",
    isCountry: true
  },
  {
    numericCode: 792,
    name: "Turkey",
    alphaCode: "TUR",
    isCountry: true
  },
  {
    numericCode: 784,
    name: "United Arab Emirates",
    alphaCode: "ARE",
    isCountry: true
  },
  {
    numericCode: 887,
    name: "Yemen",
    alphaCode: "YEM",
    isCountry: true
  },
  {
    numericCode: 150,
    name: "Europe",
    alphaCode: "150",
    isCountry: false
  },
  {
    numericCode: 151,
    name: "Eastern Europe",
    alphaCode: "151",
    isCountry: false
  },
  {
    numericCode: 112,
    name: "Belarus",
    alphaCode: "BLR",
    isCountry: true
  },
  {
    numericCode: 100,
    name: "Bulgaria",
    alphaCode: "BGR",
    isCountry: true
  },
  {
    numericCode: 203,
    name: "Czechia",
    alphaCode: "CZE",
    isCountry: true
  },
  {
    numericCode: 348,
    name: "Hungary",
    alphaCode: "HUN",
    isCountry: true
  },
  {
    numericCode: 616,
    name: "Poland",
    alphaCode: "POL",
    isCountry: true
  },
  {
    numericCode: 498,
    name: "Republic of Moldova",
    alphaCode: "MDA",
    isCountry: true
  },
  {
    numericCode: 642,
    name: "Romania",
    alphaCode: "ROU",
    isCountry: true
  },
  {
    numericCode: 643,
    name: "Russian Federation",
    alphaCode: "RUS",
    isCountry: true
  },
  {
    numericCode: 703,
    name: "Slovakia",
    alphaCode: "SVK",
    isCountry: true
  },
  {
    numericCode: 804,
    name: "Ukraine",
    alphaCode: "UKR",
    isCountry: true
  },
  {
    numericCode: 154,
    name: "Northern Europe",
    alphaCode: "154",
    isCountry: false
  },
  {
    numericCode: 248,
    name: "Åland Islands",
    alphaCode: "ALA",
    isCountry: true
  },
  {
    numericCode: 830,
    name: "Channel Islands",
    alphaCode: "830",
    isCountry: false
  },
  {
    numericCode: 831,
    name: "Guernsey",
    alphaCode: "GGY",
    isCountry: true
  },
  {
    numericCode: 832,
    name: "Jersey",
    alphaCode: "JEY",
    isCountry: true
  },
  {
    numericCode: 680,
    name: "Sark",
    alphaCode: "680",
    isCountry: false
  },
  {
    numericCode: 208,
    name: "Denmark",
    alphaCode: "DNK",
    isCountry: true
  },
  {
    numericCode: 233,
    name: "Estonia",
    alphaCode: "EST",
    isCountry: true
  },
  {
    numericCode: 234,
    name: "Faroe Islands",
    alphaCode: "FRO",
    isCountry: true
  },
  {
    numericCode: 246,
    name: "Finland",
    alphaCode: "FIN",
    isCountry: true
  },
  {
    numericCode: 352,
    name: "Iceland",
    alphaCode: "ISL",
    isCountry: true
  },
  {
    numericCode: 372,
    name: "Ireland",
    alphaCode: "IRL",
    isCountry: true
  },
  {
    numericCode: 833,
    name: "Isle of Man",
    alphaCode: "IMN",
    isCountry: true
  },
  {
    numericCode: 428,
    name: "Latvia",
    alphaCode: "LVA",
    isCountry: true
  },
  {
    numericCode: 440,
    name: "Lithuania",
    alphaCode: "LTU",
    isCountry: true
  },
  {
    numericCode: 578,
    name: "Norway",
    alphaCode: "NOR",
    isCountry: true
  },
  {
    numericCode: 744,
    name: "Svalbard and Jan Mayen Islands",
    alphaCode: "SJM",
    isCountry: true
  },
  {
    numericCode: 752,
    name: "Sweden",
    alphaCode: "SWE",
    isCountry: true
  },
  {
    numericCode: 826,
    name: "United Kingdom of Great Britain and Northern Ireland",
    alphaCode: "GBR",
    isCountry: true
  },
  {
    numericCode: 39,
    name: "Southern Europe",
    alphaCode: "039",
    isCountry: false
  },
  {
    numericCode: 8,
    name: "Albania",
    alphaCode: "ALB",
    isCountry: true
  },
  {
    numericCode: 20,
    name: "Andorra",
    alphaCode: "AND",
    isCountry: true
  },
  {
    numericCode: 70,
    name: "Bosnia and Herzegovina",
    alphaCode: "BIH",
    isCountry: true
  },
  {
    numericCode: 191,
    name: "Croatia",
    alphaCode: "HRV",
    isCountry: true
  },
  {
    numericCode: 292,
    name: "Gibraltar",
    alphaCode: "GIB",
    isCountry: true
  },
  {
    numericCode: 300,
    name: "Greece",
    alphaCode: "GRC",
    isCountry: true
  },
  {
    numericCode: 336,
    name: "Holy See",
    alphaCode: "VAT",
    isCountry: true
  },
  {
    numericCode: 380,
    name: "Italy",
    alphaCode: "ITA",
    isCountry: true
  },
  {
    numericCode: 470,
    name: "Malta",
    alphaCode: "MLT",
    isCountry: true
  },
  {
    numericCode: 499,
    name: "Montenegro",
    alphaCode: "MNE",
    isCountry: true
  },
  {
    numericCode: 620,
    name: "Portugal",
    alphaCode: "PRT",
    isCountry: true
  },
  {
    numericCode: 674,
    name: "San Marino",
    alphaCode: "SMR",
    isCountry: true
  },
  {
    numericCode: 688,
    name: "Serbia",
    alphaCode: "SRB",
    isCountry: true
  },
  {
    numericCode: 705,
    name: "Slovenia",
    alphaCode: "SVN",
    isCountry: true
  },
  {
    numericCode: 724,
    name: "Spain",
    alphaCode: "ESP",
    isCountry: true
  },
  {
    numericCode: 807,
    name: "The former Yugoslav Republic of Macedonia",
    alphaCode: "MKD",
    isCountry: true
  },
  {
    numericCode: 155,
    name: "Western Europe",
    alphaCode: "155",
    isCountry: false
  },
  {
    numericCode: 40,
    name: "Austria",
    alphaCode: "AUT",
    isCountry: true
  },
  {
    numericCode: 56,
    name: "Belgium",
    alphaCode: "BEL",
    isCountry: true
  },
  {
    numericCode: 250,
    name: "France",
    alphaCode: "FRA",
    isCountry: true
  },
  {
    numericCode: 276,
    name: "Germany",
    alphaCode: "DEU",
    isCountry: true
  },
  {
    numericCode: 438,
    name: "Liechtenstein",
    alphaCode: "LIE",
    isCountry: true
  },
  {
    numericCode: 442,
    name: "Luxembourg",
    alphaCode: "LUX",
    isCountry: true
  },
  {
    numericCode: 492,
    name: "Monaco",
    alphaCode: "MCO",
    isCountry: true
  },
  {
    numericCode: 528,
    name: "Netherlands",
    alphaCode: "NLD",
    isCountry: true
  },
  {
    numericCode: 756,
    name: "Switzerland",
    alphaCode: "CHE",
    isCountry: true
  },
  {
    numericCode: 9,
    name: "Oceania",
    alphaCode: "009",
    isCountry: false
  },
  {
    numericCode: 53,
    name: "Australia and New Zealand",
    alphaCode: "053",
    isCountry: false
  },
  {
    numericCode: 36,
    name: "Australia",
    alphaCode: "AUS",
    isCountry: true
  },
  {
    numericCode: 162,
    name: "Christmas Island",
    alphaCode: "CXR",
    isCountry: true
  },
  {
    numericCode: 166,
    name: "Cocos (Keeling) Islands",
    alphaCode: "CCK",
    isCountry: true
  },
  {
    numericCode: 334,
    name: "Heard Island and McDonald Islands",
    alphaCode: "HMD",
    isCountry: true
  },
  {
    numericCode: 554,
    name: "New Zealand",
    alphaCode: "NZL",
    isCountry: true
  },
  {
    numericCode: 574,
    name: "Norfolk Island",
    alphaCode: "NFK",
    isCountry: true
  },
  {
    numericCode: 54,
    name: "Melanesia",
    alphaCode: "054",
    isCountry: false
  },
  {
    numericCode: 242,
    name: "Fiji",
    alphaCode: "FJI",
    isCountry: true
  },
  {
    numericCode: 540,
    name: "New Caledonia",
    alphaCode: "NCL",
    isCountry: true
  },
  {
    numericCode: 598,
    name: "Papua New Guinea",
    alphaCode: "PNG",
    isCountry: true
  },
  {
    numericCode: 90,
    name: "Solomon Islands",
    alphaCode: "SLB",
    isCountry: true
  },
  {
    numericCode: 548,
    name: "Vanuatu",
    alphaCode: "VUT",
    isCountry: true
  },
  {
    numericCode: 57,
    name: "Micronesia",
    alphaCode: "057",
    isCountry: false
  },
  {
    numericCode: 316,
    name: "Guam",
    alphaCode: "GUM",
    isCountry: true
  },
  {
    numericCode: 296,
    name: "Kiribati",
    alphaCode: "KIR",
    isCountry: true
  },
  {
    numericCode: 584,
    name: "Marshall Islands",
    alphaCode: "MHL",
    isCountry: true
  },
  {
    numericCode: 583,
    name: "Micronesia (Federated States of)",
    alphaCode: "FSM",
    isCountry: true
  },
  {
    numericCode: 520,
    name: "Nauru",
    alphaCode: "NRU",
    isCountry: true
  },
  {
    numericCode: 580,
    name: "Northern Mariana Islands",
    alphaCode: "MNP",
    isCountry: true
  },
  {
    numericCode: 585,
    name: "Palau",
    alphaCode: "PLW",
    isCountry: true
  },
  {
    numericCode: 581,
    name: "United States Minor Outlying Islands",
    alphaCode: "UMI",
    isCountry: true
  },
  {
    numericCode: 61,
    name: "Polynesia",
    alphaCode: "061",
    isCountry: false
  },
  {
    numericCode: 16,
    name: "American Samoa",
    alphaCode: "ASM",
    isCountry: true
  },
  {
    numericCode: 184,
    name: "Cook Islands",
    alphaCode: "COK",
    isCountry: true
  },
  {
    numericCode: 258,
    name: "French Polynesia",
    alphaCode: "PYF",
    isCountry: true
  },
  {
    numericCode: 570,
    name: "Niue",
    alphaCode: "NIU",
    isCountry: true
  },
  {
    numericCode: 612,
    name: "Pitcairn",
    alphaCode: "PCN",
    isCountry: true
  },
  {
    numericCode: 882,
    name: "Samoa",
    alphaCode: "WSM",
    isCountry: true
  },
  {
    numericCode: 772,
    name: "Tokelau",
    alphaCode: "TKL",
    isCountry: true
  },
  {
    numericCode: 776,
    name: "Tonga",
    alphaCode: "TON",
    isCountry: true
  },
  {
    numericCode: 798,
    name: "Tuvalu",
    alphaCode: "TUV",
    isCountry: true
  },
  {
    numericCode: 876,
    name: "Wallis and Futuna Islands",
    alphaCode: "WLF",
    isCountry: true
  },
  {
    numericCode: 158,
    name: "Taiwan, Province of China",
    alphaCode: "TWN",
    isCountry: true
  }
];

const indicatorPopulationResults = [
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1970,
    mean: 5262.5207,
    percentile2pt5: 641.5279,
    percentile5: 866.4624,
    percentile10: 1169.5356,
    median: 3694.6668,
    percentile90: 11017.4177,
    percentile95: 14771.7693,
    percentile97pt5: 19142.1997
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1971,
    mean: 7216.9839,
    percentile2pt5: 960.0561,
    percentile5: 1273.3893,
    percentile10: 1715.4953,
    median: 5209.7276,
    percentile90: 14739.2351,
    percentile95: 19585.2456,
    percentile97pt5: 25989.3158
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1972,
    mean: 9856.0795,
    percentile2pt5: 1425.3487,
    percentile5: 1888.4032,
    percentile10: 2511.1561,
    median: 7281.404,
    percentile90: 19912.5334,
    percentile95: 25965.7295,
    percentile97pt5: 33636.147
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1973,
    mean: 13430.943,
    percentile2pt5: 2121.6354,
    percentile5: 2774.6773,
    percentile10: 3629.3889,
    median: 10148.3952,
    percentile90: 26760.3558,
    percentile95: 34633.6734,
    percentile97pt5: 44172.4811
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1974,
    mean: 18320.0118,
    percentile2pt5: 3178.6226,
    percentile5: 4016.5379,
    percentile10: 5230.9168,
    median: 14248.1107,
    percentile90: 35339.2585,
    percentile95: 46296.7394,
    percentile97pt5: 57072.0728
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1975,
    mean: 24971.7396,
    percentile2pt5: 4733.6177,
    percentile5: 5835.1529,
    percentile10: 7724.53,
    median: 19879.2482,
    percentile90: 47403.1515,
    percentile95: 61951.6614,
    percentile97pt5: 77581.0763
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1976,
    mean: 34121.6616,
    percentile2pt5: 7094.8847,
    percentile5: 8688.7332,
    percentile10: 11054.4213,
    median: 27838.2745,
    percentile90: 63428.8183,
    percentile95: 81866.8654,
    percentile97pt5: 100361.4448
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1977,
    mean: 46607.6119,
    percentile2pt5: 10444.1845,
    percentile5: 12766.271,
    percentile10: 16095.0977,
    median: 38618.3655,
    percentile90: 85564.7388,
    percentile95: 106806.638,
    percentile97pt5: 132374.9733
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1978,
    mean: 63705.6178,
    percentile2pt5: 15551.0828,
    percentile5: 18990.26,
    percentile10: 23664.8474,
    median: 53844.6059,
    percentile90: 113925.089,
    percentile95: 140603.6888,
    percentile97pt5: 171486.4482
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1979,
    mean: 87024.6402,
    percentile2pt5: 23070.4502,
    percentile5: 28292.2722,
    percentile10: 34978.9752,
    median: 75399.1968,
    percentile90: 151981.3766,
    percentile95: 182901.5253,
    percentile97pt5: 222236.2939
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1980,
    mean: 118517.5928,
    percentile2pt5: 34473.9212,
    percentile5: 41428.5136,
    percentile10: 50687.8804,
    median: 104351.521,
    percentile90: 201864.0573,
    percentile95: 239492.693,
    percentile97pt5: 284359.7119
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1981,
    mean: 160072.0696,
    percentile2pt5: 52043.5372,
    percentile5: 60888.1988,
    percentile10: 73388.898,
    median: 144341.5766,
    percentile90: 265342.6392,
    percentile95: 310335.6762,
    percentile97pt5: 364185.4256
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1982,
    mean: 215581.9899,
    percentile2pt5: 77603.5688,
    percentile5: 89728.7281,
    percentile10: 106995.3309,
    median: 197196.6311,
    percentile90: 346069.3991,
    percentile95: 397624.6152,
    percentile97pt5: 461765.4783
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1983,
    mean: 289170.587,
    percentile2pt5: 115913.563,
    percentile5: 132927.1239,
    percentile10: 155308.2234,
    median: 269299.2096,
    percentile90: 449003.2111,
    percentile95: 509103.5174,
    percentile97pt5: 572193.7015
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1984,
    mean: 385219.1454,
    percentile2pt5: 174640.3763,
    percentile5: 196352.7922,
    percentile10: 224733.704,
    median: 363709.453,
    percentile90: 569495.311,
    percentile95: 643088.2903,
    percentile97pt5: 720841.8607
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1985,
    mean: 510153.2925,
    percentile2pt5: 258161.0866,
    percentile5: 286181.0743,
    percentile10: 323963.8182,
    median: 488627.5271,
    percentile90: 719051.7611,
    percentile95: 801961.9395,
    percentile97pt5: 887778.7842
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1986,
    mean: 670141.3592,
    percentile2pt5: 380616.3376,
    percentile5: 414165.5039,
    percentile10: 458435.1201,
    median: 649722.1208,
    percentile90: 905454.6444,
    percentile95: 987603.8324,
    percentile97pt5: 1071373.5531
  },
  {
    isInUnion: true,
    ageRange: "15-49countResul",
    year: 1987,
    mean: 873759.3245,
    percentile2pt5: 558303.5445,
    percentile5: 593471.5795,
    percentile10: 647173.0132,
    median: 857534.8603,
    percentile90: 1123291.6737,
    percentile95: 1211392.4063,
    percentile97pt5: 1285141.8191
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1988,
    mean: 1128232.8737,
    percentile2pt5: 809359.8063,
    percentile5: 848921.4656,
    percentile10: 901455.8682,
    median: 1115137.3336,
    percentile90: 1368428.4431,
    percentile95: 1450925.7565,
    percentile97pt5: 1519139.6217
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1989,
    mean: 1443664.3863,
    percentile2pt5: 1148057.0076,
    percentile5: 1192741.6799,
    percentile10: 1244953.8456,
    median: 1435214.3036,
    percentile90: 1653652.851,
    percentile95: 1722404.3491,
    percentile97pt5: 1773792.2078
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1990,
    mean: 1829716.2151,
    percentile2pt5: 1615044.2394,
    percentile5: 1647844.2486,
    percentile10: 1684791.8541,
    median: 1826156.475,
    percentile90: 1979803.798,
    percentile95: 2020506.9684,
    percentile97pt5: 2059313.991
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1991,
    mean: 2293242.1349,
    percentile2pt5: 1860050.0262,
    percentile5: 1932331.7139,
    percentile10: 2009729.0321,
    median: 2282028.0584,
    percentile90: 2590954.6427,
    percentile95: 2679142.7895,
    percentile97pt5: 2762898.6828
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1992,
    mean: 2833778.27,
    percentile2pt5: 2153693.5279,
    percentile5: 2255076.5799,
    percentile10: 2368414.1568,
    median: 2823262.044,
    percentile90: 3308873.21,
    percentile95: 3458887.4387,
    percentile97pt5: 3580607.2338
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1993,
    mean: 3451008.7723,
    percentile2pt5: 2468219.3734,
    percentile5: 2621963.3354,
    percentile10: 2778773.9856,
    median: 3433017.9383,
    percentile90: 4131062.0859,
    percentile95: 4349158.0365,
    percentile97pt5: 4543112.3062
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1994,
    mean: 4139566.2383,
    percentile2pt5: 2850645.5816,
    percentile5: 3008995.6565,
    percentile10: 3251216.1785,
    median: 4111001.0039,
    percentile90: 5064129.5553,
    percentile95: 5337852.4537,
    percentile97pt5: 5598320.9684
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1995,
    mean: 4892080.3214,
    percentile2pt5: 3264576.0867,
    percentile5: 3484338.2801,
    percentile10: 3764055.9371,
    median: 4861780.1195,
    percentile90: 6049917.2571,
    percentile95: 6399807.2528,
    percentile97pt5: 6739074.4421
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1996,
    mean: 5702650.2846,
    percentile2pt5: 3719773.3469,
    percentile5: 4005385.713,
    percentile10: 4355449.367,
    median: 5652369.4494,
    percentile90: 7116022.394,
    percentile95: 7492821.9234,
    percentile97pt5: 7917732.9574
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1997,
    mean: 6565468.2263,
    percentile2pt5: 4210487.7898,
    percentile5: 4583238.6416,
    percentile10: 5003456.8542,
    median: 6528656.8972,
    percentile90: 8181078.7205,
    percentile95: 8672299.3256,
    percentile97pt5: 9092269.6676
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1998,
    mean: 7472419.9548,
    percentile2pt5: 4763373.5092,
    percentile5: 5202225.466,
    percentile10: 5699231.9254,
    median: 7445181.7506,
    percentile90: 9297795.0346,
    percentile95: 9826401.8555,
    percentile97pt5: 10305091.6616
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1999,
    mean: 8406644.6788,
    percentile2pt5: 5376385.5334,
    percentile5: 5882705.8063,
    percentile10: 6424445.5809,
    median: 8410996.4064,
    percentile90: 10429789.3116,
    percentile95: 11032261.5386,
    percentile97pt5: 11495274.4077
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2000,
    mean: 9370464.695,
    percentile2pt5: 6081961.6008,
    percentile5: 6588573.5191,
    percentile10: 7161326.1367,
    median: 9404095.7708,
    percentile90: 11564687.5098,
    percentile95: 12216462.279,
    percentile97pt5: 12730724.8323
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2001,
    mean: 10341767.046,
    percentile2pt5: 6813885.4732,
    percentile5: 7292229.9884,
    percentile10: 7941485.3882,
    median: 10387341.4892,
    percentile90: 12657887.0418,
    percentile95: 13372318.548,
    percentile97pt5: 13854327.4467
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2002,
    mean: 11319424.8578,
    percentile2pt5: 7514853.6153,
    percentile5: 8104878.0138,
    percentile10: 8762374.0678,
    median: 11356850.4529,
    percentile90: 13780055.7711,
    percentile95: 14422845.8129,
    percentile97pt5: 14900461.1128
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2003,
    mean: 12294653.0878,
    percentile2pt5: 8271288.398,
    percentile5: 8873438.7092,
    percentile10: 9594003.3472,
    median: 12371681.4595,
    percentile90: 14854625.4434,
    percentile95: 15530815.5555,
    percentile97pt5: 15993050.3793
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2004,
    mean: 13251453.3252,
    percentile2pt5: 8951249.5934,
    percentile5: 9641906.4704,
    percentile10: 10491351.4713,
    median: 13330144.3793,
    percentile90: 15894797.2956,
    percentile95: 16544803.0194,
    percentile97pt5: 17065932.5148
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2005,
    mean: 14181516.0858,
    percentile2pt5: 9740682.0143,
    percentile5: 10416805.3068,
    percentile10: 11322926.1052,
    median: 14279574.0894,
    percentile90: 16852288.4662,
    percentile95: 17549439.4226,
    percentile97pt5: 18008487.832
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2006,
    mean: 15072402.5137,
    percentile2pt5: 10453770.6317,
    percentile5: 11227440.5273,
    percentile10: 12158554.771,
    median: 15182371.6006,
    percentile90: 17759519.4658,
    percentile95: 18448756.8438,
    percentile97pt5: 18941792.1112
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2007,
    mean: 15924415.6978,
    percentile2pt5: 11174636.8355,
    percentile5: 12005478.3935,
    percentile10: 12993472.4523,
    median: 16048741.0202,
    percentile90: 18639430.3766,
    percentile95: 19269429.724,
    percentile97pt5: 19803017.7656
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2008,
    mean: 16733692.313,
    percentile2pt5: 11986261.2872,
    percentile5: 12740518.4608,
    percentile10: 13803515.2972,
    median: 16880579.3678,
    percentile90: 19460867.4801,
    percentile95: 20056761.8049,
    percentile97pt5: 20623420.2659
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2009,
    mean: 17508633.2573,
    percentile2pt5: 12733969.8558,
    percentile5: 13561322.2179,
    percentile10: 14548516.2758,
    median: 17655268.9824,
    percentile90: 20214768.987,
    percentile95: 20852302.43,
    percentile97pt5: 21387748.2996
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2010,
    mean: 18243139.7338,
    percentile2pt5: 13456110.4676,
    percentile5: 14312371.3998,
    percentile10: 15271810.3857,
    median: 18388346.7554,
    percentile90: 20963912.3788,
    percentile95: 21604252.3002,
    percentile97pt5: 22191761.0007
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2011,
    mean: 18941064.4682,
    percentile2pt5: 14151181.8378,
    percentile5: 15077405.7142,
    percentile10: 15987868.9725,
    median: 19095507.1889,
    percentile90: 21667338.8705,
    percentile95: 22310928.8781,
    percentile97pt5: 22903011.403
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2012,
    mean: 19595291.1237,
    percentile2pt5: 14841659.7945,
    percentile5: 15679446.8349,
    percentile10: 16677329.6514,
    median: 19736866.19,
    percentile90: 22342254.0063,
    percentile95: 23024168.684,
    percentile97pt5: 23582509.2967
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2013,
    mean: 20212615.0082,
    percentile2pt5: 15496554.2382,
    percentile5: 16358803.8371,
    percentile10: 17335257.5345,
    median: 20334343.9726,
    percentile90: 22959382.2073,
    percentile95: 23675082.6775,
    percentile97pt5: 24297322.6486
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2014,
    mean: 20785115.978,
    percentile2pt5: 16016439.8563,
    percentile5: 16972201.6879,
    percentile10: 17918382.9829,
    median: 20908060.671,
    percentile90: 23514895.2863,
    percentile95: 24249397.7369,
    percentile97pt5: 24828484.1377
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2015,
    mean: 21318488.1478,
    percentile2pt5: 16574457.4227,
    percentile5: 17485871.591,
    percentile10: 18462132.3496,
    median: 21427955.3639,
    percentile90: 24039202.9718,
    percentile95: 24794300.5471,
    percentile97pt5: 25419203.0949
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2016,
    mean: 21808693.738,
    percentile2pt5: 17026015.264,
    percentile5: 17961520.0264,
    percentile10: 18927274.1047,
    median: 21923758.6317,
    percentile90: 24536504.7084,
    percentile95: 25293171.0403,
    percentile97pt5: 25944406.0171
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2017,
    mean: 22269954.2326,
    percentile2pt5: 17564127.6843,
    percentile5: 18473902.2361,
    percentile10: 19430960.2646,
    median: 22357956.3837,
    percentile90: 25041430.5761,
    percentile95: 25748375.5432,
    percentile97pt5: 26473978.6389
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2018,
    mean: 22705316.943,
    percentile2pt5: 18015541.805,
    percentile5: 18946004.8873,
    percentile10: 19835948.0516,
    median: 22781023.4008,
    percentile90: 25501834.5656,
    percentile95: 26255781.1419,
    percentile97pt5: 26966140.5649
  }
];

const indicatorProportionalResults = [
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1970,
    mean: 0.0004,
    percentile2pt5: 0.0001,
    percentile5: 0.0001,
    percentile10: 0.0001,
    median: 0.0003,
    percentile90: 0.0009,
    percentile95: 0.0012,
    percentile97pt5: 0.0016
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1971,
    mean: 0.0006,
    percentile2pt5: 0.0001,
    percentile5: 0.0001,
    percentile10: 0.0001,
    median: 0.0004,
    percentile90: 0.0012,
    percentile95: 0.0016,
    percentile97pt5: 0.0021
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1972,
    mean: 0.0008,
    percentile2pt5: 0.0001,
    percentile5: 0.0002,
    percentile10: 0.0002,
    median: 0.0006,
    percentile90: 0.0016,
    percentile95: 0.0021,
    percentile97pt5: 0.0027
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1973,
    mean: 0.0011,
    percentile2pt5: 0.0002,
    percentile5: 0.0002,
    percentile10: 0.0003,
    median: 0.0008,
    percentile90: 0.0021,
    percentile95: 0.0027,
    percentile97pt5: 0.0035
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1974,
    mean: 0.0014,
    percentile2pt5: 0.0002,
    percentile5: 0.0003,
    percentile10: 0.0004,
    median: 0.0011,
    percentile90: 0.0027,
    percentile95: 0.0036,
    percentile97pt5: 0.0044
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1975,
    mean: 0.0019,
    percentile2pt5: 0.0004,
    percentile5: 0.0004,
    percentile10: 0.0006,
    median: 0.0015,
    percentile90: 0.0036,
    percentile95: 0.0047,
    percentile97pt5: 0.0059
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1976,
    mean: 0.0026,
    percentile2pt5: 0.0005,
    percentile5: 0.0007,
    percentile10: 0.0008,
    median: 0.0021,
    percentile90: 0.0048,
    percentile95: 0.0061,
    percentile97pt5: 0.0075
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1977,
    mean: 0.0034,
    percentile2pt5: 0.0008,
    percentile5: 0.0009,
    percentile10: 0.0012,
    median: 0.0028,
    percentile90: 0.0063,
    percentile95: 0.0078,
    percentile97pt5: 0.0097
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1978,
    mean: 0.0045,
    percentile2pt5: 0.0011,
    percentile5: 0.0014,
    percentile10: 0.0017,
    median: 0.0038,
    percentile90: 0.0081,
    percentile95: 0.01,
    percentile97pt5: 0.0122
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1979,
    mean: 0.006,
    percentile2pt5: 0.0016,
    percentile5: 0.002,
    percentile10: 0.0024,
    median: 0.0052,
    percentile90: 0.0106,
    percentile95: 0.0127,
    percentile97pt5: 0.0154
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1980,
    mean: 0.008,
    percentile2pt5: 0.0023,
    percentile5: 0.0028,
    percentile10: 0.0034,
    median: 0.007,
    percentile90: 0.0136,
    percentile95: 0.0161,
    percentile97pt5: 0.0192
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1981,
    mean: 0.0105,
    percentile2pt5: 0.0034,
    percentile5: 0.004,
    percentile10: 0.0048,
    median: 0.0095,
    percentile90: 0.0174,
    percentile95: 0.0204,
    percentile97pt5: 0.0239
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1982,
    mean: 0.0138,
    percentile2pt5: 0.005,
    percentile5: 0.0057,
    percentile10: 0.0068,
    median: 0.0126,
    percentile90: 0.0221,
    percentile95: 0.0254,
    percentile97pt5: 0.0295
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1983,
    mean: 0.0179,
    percentile2pt5: 0.0072,
    percentile5: 0.0082,
    percentile10: 0.0096,
    median: 0.0167,
    percentile90: 0.0278,
    percentile95: 0.0315,
    percentile97pt5: 0.0354
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1984,
    mean: 0.0231,
    percentile2pt5: 0.0105,
    percentile5: 0.0118,
    percentile10: 0.0135,
    median: 0.0218,
    percentile90: 0.0342,
    percentile95: 0.0386,
    percentile97pt5: 0.0432
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1985,
    mean: 0.0297,
    percentile2pt5: 0.015,
    percentile5: 0.0166,
    percentile10: 0.0188,
    median: 0.0284,
    percentile90: 0.0418,
    percentile95: 0.0466,
    percentile97pt5: 0.0516
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1986,
    mean: 0.0378,
    percentile2pt5: 0.0215,
    percentile5: 0.0234,
    percentile10: 0.0259,
    median: 0.0366,
    percentile90: 0.0511,
    percentile95: 0.0557,
    percentile97pt5: 0.0604
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1987,
    mean: 0.0478,
    percentile2pt5: 0.0305,
    percentile5: 0.0325,
    percentile10: 0.0354,
    median: 0.0469,
    percentile90: 0.0614,
    percentile95: 0.0663,
    percentile97pt5: 0.0703
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1988,
    mean: 0.0598,
    percentile2pt5: 0.0429,
    percentile5: 0.045,
    percentile10: 0.0478,
    median: 0.0591,
    percentile90: 0.0726,
    percentile95: 0.0769,
    percentile97pt5: 0.0806
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1989,
    mean: 0.0742,
    percentile2pt5: 0.059,
    percentile5: 0.0613,
    percentile10: 0.064,
    median: 0.0738,
    percentile90: 0.085,
    percentile95: 0.0886,
    percentile97pt5: 0.0912
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1990,
    mean: 0.0912,
    percentile2pt5: 0.0805,
    percentile5: 0.0821,
    percentile10: 0.084,
    median: 0.091,
    percentile90: 0.0987,
    percentile95: 0.1007,
    percentile97pt5: 0.1027
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1991,
    mean: 0.1111,
    percentile2pt5: 0.0901,
    percentile5: 0.0937,
    percentile10: 0.0974,
    median: 0.1106,
    percentile90: 0.1256,
    percentile95: 0.1298,
    percentile97pt5: 0.1339
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1992,
    mean: 0.1335,
    percentile2pt5: 0.1015,
    percentile5: 0.1063,
    percentile10: 0.1116,
    median: 0.133,
    percentile90: 0.1559,
    percentile95: 0.163,
    percentile97pt5: 0.1687
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1993,
    mean: 0.1581,
    percentile2pt5: 0.1131,
    percentile5: 0.1201,
    percentile10: 0.1273,
    median: 0.1573,
    percentile90: 0.1893,
    percentile95: 0.1993,
    percentile97pt5: 0.2081
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1994,
    mean: 0.1843,
    percentile2pt5: 0.1269,
    percentile5: 0.134,
    percentile10: 0.1448,
    median: 0.1831,
    percentile90: 0.2255,
    percentile95: 0.2377,
    percentile97pt5: 0.2493
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1995,
    mean: 0.2117,
    percentile2pt5: 0.1412,
    percentile5: 0.1508,
    percentile10: 0.1629,
    median: 0.2104,
    percentile90: 0.2618,
    percentile95: 0.2769,
    percentile97pt5: 0.2916
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1996,
    mean: 0.2398,
    percentile2pt5: 0.1564,
    percentile5: 0.1684,
    percentile10: 0.1831,
    median: 0.2377,
    percentile90: 0.2992,
    percentile95: 0.3151,
    percentile97pt5: 0.3329
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1997,
    mean: 0.2683,
    percentile2pt5: 0.172,
    percentile5: 0.1873,
    percentile10: 0.2044,
    median: 0.2668,
    percentile90: 0.3343,
    percentile95: 0.3543,
    percentile97pt5: 0.3715
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1998,
    mean: 0.2966,
    percentile2pt5: 0.1891,
    percentile5: 0.2065,
    percentile10: 0.2262,
    median: 0.2955,
    percentile90: 0.3691,
    percentile95: 0.3901,
    percentile97pt5: 0.4091
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 1999,
    mean: 0.3241,
    percentile2pt5: 0.2073,
    percentile5: 0.2268,
    percentile10: 0.2477,
    median: 0.3243,
    percentile90: 0.4021,
    percentile95: 0.4253,
    percentile97pt5: 0.4432
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2000,
    mean: 0.3507,
    percentile2pt5: 0.2276,
    percentile5: 0.2466,
    percentile10: 0.268,
    median: 0.352,
    percentile90: 0.4329,
    percentile95: 0.4573,
    percentile97pt5: 0.4765
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2001,
    mean: 0.3761,
    percentile2pt5: 0.2478,
    percentile5: 0.2652,
    percentile10: 0.2888,
    median: 0.3778,
    percentile90: 0.4604,
    percentile95: 0.4864,
    percentile97pt5: 0.5039
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2002,
    mean: 0.3999,
    percentile2pt5: 0.2655,
    percentile5: 0.2864,
    percentile10: 0.3096,
    median: 0.4013,
    percentile90: 0.4869,
    percentile95: 0.5096,
    percentile97pt5: 0.5265
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2003,
    mean: 0.4222,
    percentile2pt5: 0.284,
    percentile5: 0.3047,
    percentile10: 0.3294,
    median: 0.4248,
    percentile90: 0.5101,
    percentile95: 0.5333,
    percentile97pt5: 0.5492
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2004,
    mean: 0.4428,
    percentile2pt5: 0.2991,
    percentile5: 0.3222,
    percentile10: 0.3506,
    median: 0.4455,
    percentile90: 0.5312,
    percentile95: 0.5529,
    percentile97pt5: 0.5703
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2005,
    mean: 0.462,
    percentile2pt5: 0.3173,
    percentile5: 0.3394,
    percentile10: 0.3689,
    median: 0.4652,
    percentile90: 0.549,
    percentile95: 0.5718,
    percentile97pt5: 0.5867
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2006,
    mean: 0.4799,
    percentile2pt5: 0.3328,
    percentile5: 0.3575,
    percentile10: 0.3871,
    median: 0.4834,
    percentile90: 0.5654,
    percentile95: 0.5874,
    percentile97pt5: 0.6031
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2007,
    mean: 0.4961,
    percentile2pt5: 0.3481,
    percentile5: 0.374,
    percentile10: 0.4048,
    median: 0.5,
    percentile90: 0.5807,
    percentile95: 0.6003,
    percentile97pt5: 0.6169
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2008,
    mean: 0.5108,
    percentile2pt5: 0.3659,
    percentile5: 0.3889,
    percentile10: 0.4214,
    median: 0.5153,
    percentile90: 0.5941,
    percentile95: 0.6122,
    percentile97pt5: 0.6295
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2009,
    mean: 0.5244,
    percentile2pt5: 0.3814,
    percentile5: 0.4062,
    percentile10: 0.4357,
    median: 0.5288,
    percentile90: 0.6054,
    percentile95: 0.6245,
    percentile97pt5: 0.6405
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2010,
    mean: 0.5367,
    percentile2pt5: 0.3958,
    percentile5: 0.421,
    percentile10: 0.4493,
    median: 0.5409,
    percentile90: 0.6167,
    percentile95: 0.6355,
    percentile97pt5: 0.6528
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2011,
    mean: 0.5477,
    percentile2pt5: 0.4092,
    percentile5: 0.436,
    percentile10: 0.4623,
    median: 0.5522,
    percentile90: 0.6266,
    percentile95: 0.6452,
    percentile97pt5: 0.6623
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2012,
    mean: 0.5578,
    percentile2pt5: 0.4225,
    percentile5: 0.4463,
    percentile10: 0.4747,
    median: 0.5618,
    percentile90: 0.636,
    percentile95: 0.6554,
    percentile97pt5: 0.6713
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2013,
    mean: 0.567,
    percentile2pt5: 0.4347,
    percentile5: 0.4589,
    percentile10: 0.4863,
    median: 0.5704,
    percentile90: 0.6441,
    percentile95: 0.6642,
    percentile97pt5: 0.6816
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2014,
    mean: 0.5755,
    percentile2pt5: 0.4435,
    percentile5: 0.4699,
    percentile10: 0.4961,
    median: 0.5789,
    percentile90: 0.6511,
    percentile95: 0.6714,
    percentile97pt5: 0.6874
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2015,
    mean: 0.5831,
    percentile2pt5: 0.4533,
    percentile5: 0.4783,
    percentile10: 0.505,
    median: 0.5861,
    percentile90: 0.6575,
    percentile95: 0.6782,
    percentile97pt5: 0.6952
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2016,
    mean: 0.5899,
    percentile2pt5: 0.4606,
    percentile5: 0.4859,
    percentile10: 0.512,
    median: 0.5931,
    percentile90: 0.6637,
    percentile95: 0.6842,
    percentile97pt5: 0.7018
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2017,
    mean: 0.5963,
    percentile2pt5: 0.4703,
    percentile5: 0.4947,
    percentile10: 0.5203,
    median: 0.5987,
    percentile90: 0.6705,
    percentile95: 0.6894,
    percentile97pt5: 0.7089
  },
  {
    isInUnion: true,
    ageRange: "15-49",
    year: 2018,
    mean: 0.6022,
    percentile2pt5: 0.4778,
    percentile5: 0.5025,
    percentile10: 0.5261,
    median: 0.6042,
    percentile90: 0.6764,
    percentile95: 0.6964,
    percentile97pt5: 0.7152
  }
];

const populationResults = [].concat(
  ...indicators
    .filter(indicator => indicator !== "modernToTotalRatio")
    .map(indicator =>
      indicatorPopulationResults.map(datum => ({
        ...datum,
        indicator: indicator.value
      }))
    )
);

const proportionalResults = [].concat(
  ...indicators.map(indicator =>
    indicatorProportionalResults.map(datum => ({
      ...datum,
      indicator: indicator.value
    }))
  )
);

export const databases = [
  {
    id: 1,
    type: "survey",
    name: "UNPD 2019",
    divisions: divisions,
    isGlobal: true,
    isDefault: true,
    isInUse: true
  },
  {
    id: 2,
    type: "survey",
    name: "Track20",
    divisions: divisions,
    isGlobal: true
  },
  {
    id: 3,
    type: "survey",
    name: "YEM_2019",
    divisions: divisions.filter(division => division.numericCode === 887),
    regionCodes: ["ZZZ"]
  },
  {
    id: 4,
    type: "survey",
    name: "NPL_2019",
    divisions: divisions.filter(division => division.numericCode === 524)
  },
  {
    id: 5,
    type: "population",
    name: "UNPD 2019",
    divisions: divisions,
    isGlobal: true,
    isDefault: true,
    isInUse: true
  },
  {
    id: 6,
    type: "population",
    name: "Track20",
    divisions: divisions,
    isGlobal: true
  },
  {
    id: 7,
    type: "population",
    name: "YEM_2019",
    divisions: divisions.filter(division => division.numericCode === 887),
    regionCodes: ["ZZZ"]
  },
  {
    id: 8,
    type: "population",
    name: "NPL_2019",
    divisions: divisions.filter(division => division.numericCode === 524)
  },
  {
    id: 9,
    type: "emu",
    name: "YEM_2019",
    divisions: divisions.filter(division => division.numericCode === 887)
  }
];

export const emuData = [
  {
    divisionNumericCode: 50,
    startYear: 1995,
    endYear: 2000,
    emu: 7,
    serviceStatisticType: "clients",
    referencePop : "All women", // "All women", "Married women"
    emU_ROC : undefined, // real, undefined
    sD_EMU_ROC: undefined, // real >0, undefined
    sD_EMU : undefined, // real >0, undefined
    included : true, // true, false
  },
  {
    divisionNumericCode: 50,
    startYear: 2000,
    endYear: 2005,
    emu: 12.5,
    serviceStatisticType: "visitors",
    referencePop : "All women", // "All women", "Married women"
    emU_ROC : undefined, // real, undefined
    sD_EMU_ROC: undefined, // real >0, undefined
    sD_EMU : undefined, // real >0, undefined
    included : true, // true, false
  }
];

export const populationData = [
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1970,
    population: 835341
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1970,
    population: 2122664
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1970,
    population: 2001219
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1970,
    population: 12125896
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1971,
    population: 839164
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1971,
    population: 2132379
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1971,
    population: 2030358
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1971,
    population: 12329686
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1972,
    population: 849655
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1972,
    population: 2159037
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1972,
    population: 2063445
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1972,
    population: 12512890
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1973,
    population: 868980
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1973,
    population: 2208143
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1973,
    population: 2104193
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1973,
    population: 12691913
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1974,
    population: 896391
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1974,
    population: 2277797
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1974,
    population: 2154034
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1974,
    population: 12882973
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1975,
    population: 963008
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1975,
    population: 2334906
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1975,
    population: 2238571
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1975,
    population: 13075233
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1976,
    population: 1058773
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1976,
    population: 2451627
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1976,
    population: 2346888
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1976,
    population: 13335207
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1977,
    population: 1163786
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1977,
    population: 2575700
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1977,
    population: 2469894
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1977,
    population: 13646590
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1978,
    population: 1274895
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1978,
    population: 2698963
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1978,
    population: 2604465
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1978,
    population: 14002941
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1979,
    population: 1388669
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1979,
    population: 2813999
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1979,
    population: 2747135
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1979,
    population: 14400963
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1980,
    population: 1502402
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1980,
    population: 2916027
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1980,
    population: 2894764
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1980,
    population: 14835669
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1981,
    population: 1605075
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1981,
    population: 2985629
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1981,
    population: 3026009
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1981,
    population: 15221932
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1982,
    population: 1709310
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1982,
    population: 3048806
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1982,
    population: 3165502
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1982,
    population: 15666453
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1983,
    population: 1812915
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1983,
    population: 3102182
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1983,
    population: 3309557
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1983,
    population: 16154891
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1984,
    population: 1913091
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1984,
    population: 3141961
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1984,
    population: 3453177
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1984,
    population: 16667150
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1985,
    population: 2008792
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1985,
    population: 3167754
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1985,
    population: 3594239
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1985,
    population: 17195502
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1986,
    population: 2092549
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1986,
    population: 3169588
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1986,
    population: 3725190
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1986,
    population: 17730221
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1987,
    population: 2169164
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1987,
    population: 3156987
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1987,
    population: 3850835
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1987,
    population: 18283954
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1988,
    population: 2244880
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1988,
    population: 3140181
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1988,
    population: 3975719
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1988,
    population: 18855590
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1989,
    population: 2331342
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1989,
    population: 3135171
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1989,
    population: 4109458
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1989,
    population: 19448250
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1990,
    population: 2436409
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1990,
    population: 3150633
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1990,
    population: 4258613
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1990,
    population: 20060901
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1991,
    population: 2550157
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1991,
    population: 3171697
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1991,
    population: 4407826
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1991,
    population: 20632958
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1992,
    population: 2684635
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1992,
    population: 3211874
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1992,
    population: 4574307
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1992,
    population: 21220719
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1993,
    population: 2833981
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1993,
    population: 3261946
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1993,
    population: 4755120
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1993,
    population: 21827167
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1994,
    population: 2986919
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1994,
    population: 3307923
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1994,
    population: 4943510
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1994,
    population: 22456462
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1995,
    population: 3136025
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1995,
    population: 3341924
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1995,
    population: 5135189
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1995,
    population: 23112715
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1996,
    population: 3281655
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1996,
    population: 3365235
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1996,
    population: 5328724
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1996,
    population: 23782136
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1997,
    population: 3422335
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1997,
    population: 3377213
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1997,
    population: 5523980
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1997,
    population: 24474421
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1998,
    population: 3557882
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1998,
    population: 3378601
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1998,
    population: 5721108
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1998,
    population: 25191852
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 1999,
    population: 3690395
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 1999,
    population: 3372180
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 1999,
    population: 5921938
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 1999,
    population: 25939372
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2000,
    population: 3821321
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2000,
    population: 3359813
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2000,
    population: 6126717
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2000,
    population: 26716389
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2001,
    population: 3945707
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2001,
    population: 3337709
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2001,
    population: 6327097
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2001,
    population: 27494648
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2002,
    population: 4066872
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2002,
    population: 3309422
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2002,
    population: 6527978
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2002,
    population: 28303334
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2003,
    population: 4184102
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2003,
    population: 3274872
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2003,
    population: 6725002
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2003,
    population: 29121950
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2004,
    population: 4295761
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2004,
    population: 3233346
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2004,
    population: 6912794
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2004,
    population: 29924092
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2005,
    population: 4401431
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2005,
    population: 3185163
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2005,
    population: 7089047
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2005,
    population: 30693877
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2006,
    population: 4494919
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2006,
    population: 3126635
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2006,
    population: 7248340
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2006,
    population: 31409495
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2007,
    population: 4580163
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2007,
    population: 3061476
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2007,
    population: 7393800
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2007,
    population: 32099079
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2008,
    population: 4661869
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2008,
    population: 2993410
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2008,
    population: 7528233
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2008,
    population: 32759272
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2009,
    population: 4745554
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2009,
    population: 2926142
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2009,
    population: 7656245
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2009,
    population: 33389813
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2010,
    population: 4835862
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2010,
    population: 2862292
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2010,
    population: 7783095
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2010,
    population: 33993748
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2011,
    population: 4932228
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2011,
    population: 2801085
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2011,
    population: 7913779
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2011,
    population: 34581666
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2012,
    population: 5030118
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2012,
    population: 2739665
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2012,
    population: 8038242
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2012,
    population: 35130430
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2013,
    population: 5129162
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2013,
    population: 2677782
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2013,
    population: 8156992
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2013,
    population: 35646420
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2014,
    population: 5224029
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2014,
    population: 2617338
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2014,
    population: 8294030
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2014,
    population: 36117273
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2015,
    population: 5317400
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2015,
    population: 2553738
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2015,
    population: 8437086
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2015,
    population: 36561634
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2016,
    population: 5408664
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2016,
    population: 2487059
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2016,
    population: 8588336
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2016,
    population: 36967045
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2017,
    population: 5497075
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2017,
    population: 2417371
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2017,
    population: 8745762
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2017,
    population: 37346722
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2018,
    population: 5579052
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2018,
    population: 2343605
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2018,
    population: 8906076
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2018,
    population: 37702956
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2019,
    population: 5648586
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2019,
    population: 2263990
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2019,
    population: 9064122
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2019,
    population: 38040929
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2020,
    population: 5703167
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2020,
    population: 2178496
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2020,
    population: 9218537
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2020,
    population: 38365313
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2021,
    population: 5744734
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2021,
    population: 2088877
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2021,
    population: 9376278
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2021,
    population: 38686337
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2022,
    population: 5767762
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2022,
    population: 1994126
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2022,
    population: 9529708
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2022,
    population: 39001460
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2023,
    population: 5778581
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2023,
    population: 1897414
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2023,
    population: 9681709
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2023,
    population: 39289951
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2024,
    population: 5787830
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2024,
    population: 1802807
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2024,
    population: 9838184
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2024,
    population: 39524262
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2025,
    population: 5803423
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2025,
    population: 1712818
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2025,
    population: 10004484
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2025,
    population: 39686816
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2026,
    population: 5827037
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2026,
    population: 1627648
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2026,
    population: 10188491
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2026,
    population: 39794717
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2027,
    population: 5854332
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2027,
    population: 1545869
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2027,
    population: 10380633
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2027,
    population: 39831384
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2028,
    population: 5885087
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2028,
    population: 1467329
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2028,
    population: 10582349
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2028,
    population: 39801033
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2029,
    population: 5916828
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2029,
    population: 1391378
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2029,
    population: 10795657
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2029,
    population: 39713228
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-19",
    midYear: 2030,
    population: 5948455
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2030,
    population: 1317750
  },
  {
    divisionNumericCode: 50,
    isInUnion: false,
    ageRange: "15-49",
    midYear: 2030,
    population: 11023887
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2030,
    population: 39573599
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2031,
    population: 1311331
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2031,
    population: 39682336
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2032,
    population: 1305103
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2032,
    population: 39754634
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2033,
    population: 1298770
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2033,
    population: 39793155
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2034,
    population: 1291724
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2034,
    population: 39801922
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-19",
    midYear: 2035,
    population: 1283736
  },
  {
    divisionNumericCode: 50,
    isInUnion: true,
    ageRange: "15-49",
    midYear: 2035,
    population: 39784414
  }
];

export const results = {
  proportions: proportionalResults,
  populations: populationResults
};

export const runs = [
  {
    id: 1,
    surveyDatabaseId: 1,
    populationDatabaseId: 1,
    name: "UNPD 2019",
    countryNumericCodes: [
      4,
      1,
      2,
      15,
      12,
      818,
      434,
      504,
      729,
      788,
      732,
      202,
      14,
      86,
      108,
      174,
      262,
      232,
      231,
      260,
      404,
      450,
      454,
      480,
      175,
      508,
      638,
      646,
      690,
      706,
      728,
      800,
      834,
      894,
      716,
      17,
      24,
      120,
      140,
      148,
      178,
      180,
      226,
      266,
      678,
      18,
      72,
      748,
      426,
      516,
      710,
      11,
      204,
      854,
      132,
      384,
      270,
      288,
      324,
      624,
      430,
      466,
      478,
      562,
      566,
      654,
      686,
      694,
      768,
      19,
      419,
      29,
      660,
      28,
      533,
      44,
      52,
      535,
      92,
      136,
      192,
      531,
      212,
      214,
      308,
      312,
      332,
      388,
      474,
      500,
      630,
      652,
      659,
      662,
      663,
      670,
      534,
      780,
      796,
      850,
      13,
      84,
      188,
      222,
      320,
      340,
      484,
      558,
      591,
      5,
      32,
      68,
      74,
      76,
      152,
      170,
      218,
      238,
      254,
      328,
      600,
      604,
      239,
      740,
      858,
      862,
      21,
      60,
      124,
      304,
      666,
      840,
      10,
      142,
      143,
      398,
      417,
      762,
      795,
      860,
      30,
      156,
      344,
      446,
      408,
      392,
      496,
      410,
      35,
      96,
      116,
      360,
      418,
      458,
      104,
      608,
      702,
      764,
      626,
      704,
      34,
      50,
      64,
      356,
      364,
      462,
      524,
      586,
      144,
      145,
      51,
      31,
      48,
      196,
      268,
      368,
      376,
      400,
      414,
      422,
      512,
      634,
      682,
      275,
      760,
      792,
      784,
      887,
      150,
      151,
      112,
      100,
      203,
      348,
      616,
      498,
      642,
      643,
      703,
      804,
      154,
      248,
      830,
      831,
      832,
      680,
      208,
      233,
      234,
      246,
      352,
      372,
      833,
      428,
      440,
      578,
      744,
      752,
      826,
      39,
      8,
      20,
      70,
      191,
      292,
      300,
      336,
      380,
      470,
      499,
      620,
      674,
      688,
      705,
      724,
      807,
      155,
      40,
      56,
      250,
      276,
      438,
      442,
      492,
      528,
      756,
      9,
      53,
      36,
      162,
      166,
      334,
      554,
      574,
      54,
      242,
      540,
      598,
      90,
      548,
      57,
      316,
      296,
      584,
      583,
      520,
      580,
      585,
      581,
      61,
      16,
      184,
      258,
      570,
      612,
      882,
      772,
      776,
      798,
      876,
      158
    ],
    isGlobal: true,
    period: {
      firstYear: 1990,
      lastYear: 2020
    },
    startedAt: "2019-02-19T07:43:32.5794241Z",
    completed: true
  },
  {
    id: 2,
    surveyDatabaseId: 1,
    populationDatabaseId: 1,
    name: "BGD_2019_1",
    countryNumericCodes: [50],
    period: {
      firstYear: 1990,
      lastYear: 2020
    },
    startedAt: "2019-02-19T06:43:32.5794241Z",
    completed: true
  },
  {
    id: 3,
    surveyDatabaseId: 1,
    populationDatabaseId: 1,
    name: "BGD_2019_2",
    countryNumericCodes: [50],
    period: {
      firstYear: 1990,
      lastYear: 2020
    },
    startedAt: "2019-02-19T23:33:32.5794241Z",
    completed: true
  },
  {
    id: 4,
    surveyDatabaseId: 1,
    populationDatabaseId: 1,
    emuDatabaseId: 9,
    name: "1_YEM",
    countryNumericCodes: [887],
    period: {
      firstYear: 1990,
      lastYear: 2020
    },
    startedAt: "2019-02-19T17:43:32.5794241Z",
    completed: true
  },
  {
    id: 5,
    surveyDatabaseId: 1,
    populationDatabaseId: 1,
    name: "2019_Nepal 1_NPL",
    countryNumericCodes: [524],
    period: {
      firstYear: 1990,
      lastYear: 2020
    },
    startedAt: "2019-02-19T18:43:32.5794241Z",
    completed: true
  },
  {
    id: 6,
    surveyDatabaseId: 1,
    populationDatabaseId: 1,
    name: "1_YEM ZZZ",
    countryNumericCodes: [],
    regionCodes: ["ZZZ"],
    period: {
      firstYear: 1990,
      lastYear: 2020
    },
    startedAt: "2019-02-19T18:43:32.5794241Z",
    completed: true
  }
];

export const surveyData = [
  {
    divisionNumericCode: 50,
    startDate: 2004,
    endDate: 2004.4137,
    isInUnion: false,
    ageRange: "15-49",
    dataSeriesType: "Other",
    groupTypeRelativeToBaseline: "FM",
    contraceptiveUseModern: 0.0118,
    contraceptiveUseTraditional: null,
    contraceptiveUseAny: 0.0118,
    ratioModernAll: 1,
    unmetNeedModern: null,
    unmetNeedAny: null,
    demand: null,
    demandModern: null,
    demandSatisfied: null,
    demandSatisfiedModern: null,
    nonUse: 0.9882,
    isPertainingToMethodsUsedSinceLastPregnancy: false,
    pertainingToMethodsUsedSinceLastPregnancyReason: null,
    hasGeographicalRegionBias: false,
    geographicalRegionBiasReason: null,
    hasNonPregnantAndOtherPositiveBiases: false,
    nonPregnantAndOtherPositiveBiasesReason: null,
    ageGroupBias: "None",
    modernMethodBias: "-",
    hasTraditionalMethodBias: false,
    traditionalMethodBiasReason: null,
    hasAbsenceOfProbingQuestionsBias: false,
    seModern: 0.009,
    seTraditional: 0,
    seUnmetNeed: 0,
    seLogRModernNoUse: 0.1662,
    seLogRTraditionalNoUse: null,
    seLogRUnmetNoNeed: null,
    sourceId: "Bangladesh 2004 Demographic and Health Survey",
    recordId: "166"
  },
  {
    divisionNumericCode: 50,
    startDate: 1993.8329,
    endDate: 1994.2439,
    isInUnion: false,
    ageRange: "15-49",
    dataSeriesType: "Other",
    groupTypeRelativeToBaseline: "FM",
    contraceptiveUseModern: 0.0186,
    contraceptiveUseTraditional: null,
    contraceptiveUseAny: 0.0186,
    ratioModernAll: 1,
    unmetNeedModern: null,
    unmetNeedAny: null,
    demand: null,
    demandModern: null,
    demandSatisfied: null,
    demandSatisfiedModern: null,
    nonUse: 0.9814,
    isPertainingToMethodsUsedSinceLastPregnancy: false,
    pertainingToMethodsUsedSinceLastPregnancyReason: null,
    hasGeographicalRegionBias: false,
    geographicalRegionBiasReason: null,
    hasNonPregnantAndOtherPositiveBiases: false,
    nonPregnantAndOtherPositiveBiasesReason: null,
    ageGroupBias: "None",
    modernMethodBias: "-",
    hasTraditionalMethodBias: false,
    traditionalMethodBiasReason: null,
    hasAbsenceOfProbingQuestionsBias: false,
    seModern: 0.0081,
    seTraditional: 0,
    seUnmetNeed: 0,
    seLogRModernNoUse: 0.1701,
    seLogRTraditionalNoUse: null,
    seLogRUnmetNoNeed: null,
    sourceId: "Bangladesh 1993-1994 Demographic and Health Survey",
    recordId: "167"
  },
  {
    divisionNumericCode: 50,
    startDate: 1996.8356,
    endDate: 1997.2439,
    isInUnion: false,
    ageRange: "15-49",
    dataSeriesType: "Other",
    groupTypeRelativeToBaseline: "FM",
    contraceptiveUseModern: 0.0203,
    contraceptiveUseTraditional: null,
    contraceptiveUseAny: 0.0203,
    ratioModernAll: 1,
    unmetNeedModern: null,
    unmetNeedAny: null,
    demand: null,
    demandModern: null,
    demandSatisfied: null,
    demandSatisfiedModern: null,
    nonUse: 0.9797,
    isPertainingToMethodsUsedSinceLastPregnancy: false,
    pertainingToMethodsUsedSinceLastPregnancyReason: null,
    hasGeographicalRegionBias: false,
    geographicalRegionBiasReason: null,
    hasNonPregnantAndOtherPositiveBiases: false,
    nonPregnantAndOtherPositiveBiasesReason: null,
    ageGroupBias: "None",
    modernMethodBias: "-",
    hasTraditionalMethodBias: false,
    traditionalMethodBiasReason: null,
    hasAbsenceOfProbingQuestionsBias: false,
    seModern: 0.0088,
    seTraditional: 0,
    seUnmetNeed: 0,
    seLogRModernNoUse: 0.1669,
    seLogRTraditionalNoUse: null,
    seLogRUnmetNoNeed: null,
    sourceId: "Bangladesh 1996-1997 Demographic and Health Survey",
    recordId: "168"
  },
  {
    divisionNumericCode: 50,
    startDate: 1999.8329,
    endDate: 2000.2466,
    isInUnion: false,
    ageRange: "15-49",
    dataSeriesType: "Other",
    groupTypeRelativeToBaseline: "FM",
    contraceptiveUseModern: 0.0113,
    contraceptiveUseTraditional: null,
    contraceptiveUseAny: 0.0113,
    ratioModernAll: 1,
    unmetNeedModern: null,
    unmetNeedAny: null,
    demand: null,
    demandModern: null,
    demandSatisfied: null,
    demandSatisfiedModern: null,
    nonUse: 0.9887,
    isPertainingToMethodsUsedSinceLastPregnancy: false,
    pertainingToMethodsUsedSinceLastPregnancyReason: null,
    hasGeographicalRegionBias: false,
    geographicalRegionBiasReason: null,
    hasNonPregnantAndOtherPositiveBiases: false,
    nonPregnantAndOtherPositiveBiasesReason: null,
    ageGroupBias: "None",
    modernMethodBias: "-",
    hasTraditionalMethodBias: false,
    traditionalMethodBiasReason: null,
    hasAbsenceOfProbingQuestionsBias: false,
    seModern: 0.0063,
    seTraditional: 0,
    seUnmetNeed: 0,
    seLogRModernNoUse: 0.1589,
    seLogRTraditionalNoUse: null,
    seLogRUnmetNoNeed: null,
    sourceId: "Bangladesh 1999-2000 Demographic and Health Survey",
    recordId: "169"
  },
  {
    divisionNumericCode: 50,
    startDate: 2007.1616,
    endDate: 2007.663,
    isInUnion: false,
    ageRange: "15-49",
    dataSeriesType: "Other",
    groupTypeRelativeToBaseline: "FM",
    contraceptiveUseModern: 0.0117,
    contraceptiveUseTraditional: null,
    contraceptiveUseAny: 0.0117,
    ratioModernAll: 1,
    unmetNeedModern: null,
    unmetNeedAny: null,
    demand: null,
    demandModern: null,
    demandSatisfied: null,
    demandSatisfiedModern: null,
    nonUse: 0.9883,
    isPertainingToMethodsUsedSinceLastPregnancy: false,
    pertainingToMethodsUsedSinceLastPregnancyReason: null,
    hasGeographicalRegionBias: false,
    geographicalRegionBiasReason: null,
    hasNonPregnantAndOtherPositiveBiases: false,
    nonPregnantAndOtherPositiveBiasesReason: null,
    ageGroupBias: "None",
    modernMethodBias: "-",
    hasTraditionalMethodBias: false,
    traditionalMethodBiasReason: null,
    hasAbsenceOfProbingQuestionsBias: false,
    seModern: 0.0062,
    seTraditional: 0,
    seUnmetNeed: 0,
    seLogRModernNoUse: 0.168,
    seLogRTraditionalNoUse: null,
    seLogRUnmetNoNeed: null,
    sourceId: "Bangladesh 2007 Demographic and Health Survey",
    recordId: "184"
  },
  {
    divisionNumericCode: 50,
    startDate: 1989.1616,
    endDate: 1989.4959,
    isInUnion: true,
    ageRange: "15-49",
    dataSeriesType: "National survey",
    groupTypeRelativeToBaseline: "MW",
    contraceptiveUseModern: 0.245,
    contraceptiveUseTraditional: 0.07,
    contraceptiveUseAny: 0.315,
    ratioModernAll: 0.7778,
    unmetNeedModern: null,
    unmetNeedAny: null,
    demand: null,
    demandModern: null,
    demandSatisfied: null,
    demandSatisfiedModern: null,
    nonUse: 0.685,
    isPertainingToMethodsUsedSinceLastPregnancy: false,
    pertainingToMethodsUsedSinceLastPregnancyReason: null,
    hasGeographicalRegionBias: false,
    geographicalRegionBiasReason: null,
    hasNonPregnantAndOtherPositiveBiases: false,
    nonPregnantAndOtherPositiveBiasesReason: null,
    ageGroupBias: "?",
    modernMethodBias: "None",
    hasTraditionalMethodBias: false,
    traditionalMethodBiasReason: null,
    hasAbsenceOfProbingQuestionsBias: false,
    seModern: null,
    seTraditional: null,
    seUnmetNeed: null,
    seLogRModernNoUse: null,
    seLogRTraditionalNoUse: null,
    seLogRUnmetNoNeed: null,
    sourceId: "Bangladesh 1989 Contraceptive Prevalence Survey",
    recordId: "587"
  },
  {
    divisionNumericCode: 50,
    startDate: 1983.7479,
    endDate: 1984,
    isInUnion: true,
    ageRange: "15-49",
    dataSeriesType: "National survey",
    groupTypeRelativeToBaseline: "MW",
    contraceptiveUseModern: 0.138,
    contraceptiveUseTraditional: 0.054,
    contraceptiveUseAny: 0.192,
    ratioModernAll: 0.7188,
    unmetNeedModern: null,
    unmetNeedAny: null,
    demand: null,
    demandModern: null,
    demandSatisfied: null,
    demandSatisfiedModern: null,
    nonUse: 0.808,
    isPertainingToMethodsUsedSinceLastPregnancy: false,
    pertainingToMethodsUsedSinceLastPregnancyReason: null,
    hasGeographicalRegionBias: false,
    geographicalRegionBiasReason: null,
    hasNonPregnantAndOtherPositiveBiases: false,
    nonPregnantAndOtherPositiveBiasesReason: null,
    ageGroupBias: "?",
    modernMethodBias: "None",
    hasTraditionalMethodBias: false,
    traditionalMethodBiasReason: null,
    hasAbsenceOfProbingQuestionsBias: false,
    seModern: null,
    seTraditional: null,
    seUnmetNeed: null,
    seLogRModernNoUse: null,
    seLogRTraditionalNoUse: null,
    seLogRUnmetNoNeed: null,
    sourceId: "Bangladesh 1983-1984 Contraceptive Prevalence Survey",
    recordId: "589"
  },
  {
    divisionNumericCode: 50,
    startDate: 1981.3287,
    endDate: 1981.5808,
    isInUnion: true,
    ageRange: "15-49",
    dataSeriesType: "National survey",
    groupTypeRelativeToBaseline: "MW",
    contraceptiveUseModern: 0.11,
    contraceptiveUseTraditional: 0.076,
    contraceptiveUseAny: 0.186,
    ratioModernAll: 0.5914,
    unmetNeedModern: null,
    unmetNeedAny: null,
    demand: null,
    demandModern: null,
    demandSatisfied: null,
    demandSatisfiedModern: null,
    nonUse: 0.814,
    isPertainingToMethodsUsedSinceLastPregnancy: false,
    pertainingToMethodsUsedSinceLastPregnancyReason: null,
    hasGeographicalRegionBias: false,
    geographicalRegionBiasReason: null,
    hasNonPregnantAndOtherPositiveBiases: false,
    nonPregnantAndOtherPositiveBiasesReason: null,
    ageGroupBias: "?",
    modernMethodBias: "None",
    hasTraditionalMethodBias: false,
    traditionalMethodBiasReason: null,
    hasAbsenceOfProbingQuestionsBias: false,
    seModern: null,
    seTraditional: null,
    seUnmetNeed: null,
    seLogRModernNoUse: null,
    seLogRTraditionalNoUse: null,
    seLogRUnmetNoNeed: null,
    sourceId: "Bangladesh 1981 Contraceptive Prevalence Survey",
    recordId: "636"
  },
  {
    divisionNumericCode: 50,
    startDate: 1975,
    endDate: 1977,
    isInUnion: true,
    ageRange: "15-49",
    dataSeriesType: "Other",
    groupTypeRelativeToBaseline: "MW",
    contraceptiveUseModern: 0.05,
    contraceptiveUseTraditional: 0.03,
    contraceptiveUseAny: 0.08,
    ratioModernAll: 0.625,
    unmetNeedModern: null,
    unmetNeedAny: null,
    demand: null,
    demandModern: null,
    demandSatisfied: null,
    demandSatisfiedModern: null,
    nonUse: 0.92,
    isPertainingToMethodsUsedSinceLastPregnancy: false,
    pertainingToMethodsUsedSinceLastPregnancyReason: null,
    hasGeographicalRegionBias: false,
    geographicalRegionBiasReason: null,
    hasNonPregnantAndOtherPositiveBiases: false,
    nonPregnantAndOtherPositiveBiasesReason: null,
    ageGroupBias: "None",
    modernMethodBias: "None",
    hasTraditionalMethodBias: false,
    traditionalMethodBiasReason: null,
    hasAbsenceOfProbingQuestionsBias: false,
    seModern: null,
    seTraditional: null,
    seUnmetNeed: null,
    seLogRModernNoUse: null,
    seLogRTraditionalNoUse: null,
    seLogRUnmetNoNeed: null,
    sourceId: "Bangladesh 1975-1976 World Fertility Survey",
    recordId: "690"
  },
  {
    divisionNumericCode: 50,
    startDate: 1985.915,
    endDate: 1986.2466,
    isInUnion: true,
    ageRange: "15-49",
    dataSeriesType: "National survey",
    groupTypeRelativeToBaseline: "MW",
    contraceptiveUseModern: 0.184,
    contraceptiveUseTraditional: 0.069,
    contraceptiveUseAny: 0.253,
    ratioModernAll: 0.7273,
    unmetNeedModern: null,
    unmetNeedAny: null,
    demand: null,
    demandModern: null,
    demandSatisfied: null,
    demandSatisfiedModern: null,
    nonUse: 0.747,
    isPertainingToMethodsUsedSinceLastPregnancy: false,
    pertainingToMethodsUsedSinceLastPregnancyReason: null,
    hasGeographicalRegionBias: false,
    geographicalRegionBiasReason: null,
    hasNonPregnantAndOtherPositiveBiases: false,
    nonPregnantAndOtherPositiveBiasesReason: null,
    ageGroupBias: "?",
    modernMethodBias: "None",
    hasTraditionalMethodBias: false,
    traditionalMethodBiasReason: null,
    hasAbsenceOfProbingQuestionsBias: false,
    seModern: null,
    seTraditional: null,
    seUnmetNeed: null,
    seLogRModernNoUse: null,
    seLogRTraditionalNoUse: null,
    seLogRUnmetNoNeed: null,
    sourceId: "Bangladesh 1985-1986 Contraceptive Prevalence Survey",
    recordId: "709"
  },
  {
    divisionNumericCode: 50,
    startDate: 1979.6658,
    endDate: 1980,
    isInUnion: true,
    ageRange: "15-49",
    dataSeriesType: "Other",
    groupTypeRelativeToBaseline: "MW",
    contraceptiveUseModern: 0.088,
    contraceptiveUseTraditional: 0.033,
    contraceptiveUseAny: 0.121,
    ratioModernAll: 0.7273,
    unmetNeedModern: null,
    unmetNeedAny: null,
    demand: null,
    demandModern: null,
    demandSatisfied: null,
    demandSatisfiedModern: null,
    nonUse: 0.879,
    isPertainingToMethodsUsedSinceLastPregnancy: false,
    pertainingToMethodsUsedSinceLastPregnancyReason: null,
    hasGeographicalRegionBias: false,
    geographicalRegionBiasReason: null,
    hasNonPregnantAndOtherPositiveBiases: false,
    nonPregnantAndOtherPositiveBiasesReason: null,
    ageGroupBias: "?",
    modernMethodBias: "None",
    hasTraditionalMethodBias: false,
    traditionalMethodBiasReason: null,
    hasAbsenceOfProbingQuestionsBias: false,
    seModern: null,
    seTraditional: null,
    seUnmetNeed: null,
    seLogRModernNoUse: null,
    seLogRTraditionalNoUse: null,
    seLogRUnmetNoNeed: null,
    sourceId: "Bangladesh 1979-1980 Contraceptive Prevalence Survey",
    recordId: "721"
  },
  {
    divisionNumericCode: 50,
    startDate: 1988.9178,
    endDate: 1989.2466,
    isInUnion: true,
    ageRange: "15-49",
    dataSeriesType: "National survey",
    groupTypeRelativeToBaseline: "MW",
    contraceptiveUseModern: 0.232,
    contraceptiveUseTraditional: 0.076,
    contraceptiveUseAny: 0.308,
    ratioModernAll: 0.7532,
    unmetNeedModern: null,
    unmetNeedAny: null,
    demand: null,
    demandModern: null,
    demandSatisfied: null,
    demandSatisfiedModern: null,
    nonUse: 0.692,
    isPertainingToMethodsUsedSinceLastPregnancy: false,
    pertainingToMethodsUsedSinceLastPregnancyReason: null,
    hasGeographicalRegionBias: false,
    geographicalRegionBiasReason: null,
    hasNonPregnantAndOtherPositiveBiases: false,
    nonPregnantAndOtherPositiveBiasesReason: null,
    ageGroupBias: "?",
    modernMethodBias: "None",
    hasTraditionalMethodBias: false,
    traditionalMethodBiasReason: null,
    hasAbsenceOfProbingQuestionsBias: false,
    seModern: null,
    seTraditional: null,
    seUnmetNeed: null,
    seLogRModernNoUse: null,
    seLogRTraditionalNoUse: null,
    seLogRUnmetNoNeed: null,
    sourceId: "Bangladesh 1988-1989 Fertility Survey",
    recordId: "726"
  },
  {
    divisionNumericCode: 50,
    startDate: 2004,
    endDate: 2004.4137,
    isInUnion: true,
    ageRange: "15-49",
    dataSeriesType: "Other",
    groupTypeRelativeToBaseline: "MW",
    contraceptiveUseModern: 0.4729,
    contraceptiveUseTraditional: 0.1078,
    contraceptiveUseAny: 0.5807,
    ratioModernAll: 0.8144,
    unmetNeedModern: 0.2597,
    unmetNeedAny: 0.1519,
    demand: 0.7326,
    demandModern: 0.7326,
    demandSatisfied: 0.7926,
    demandSatisfiedModern: 0.6455,
    nonUse: 0.4193,
    isPertainingToMethodsUsedSinceLastPregnancy: false,
    pertainingToMethodsUsedSinceLastPregnancyReason: null,
    hasGeographicalRegionBias: false,
    geographicalRegionBiasReason: null,
    hasNonPregnantAndOtherPositiveBiases: false,
    nonPregnantAndOtherPositiveBiasesReason: null,
    ageGroupBias: "None",
    modernMethodBias: "None",
    hasTraditionalMethodBias: false,
    traditionalMethodBiasReason: null,
    hasAbsenceOfProbingQuestionsBias: false,
    seModern: null,
    seTraditional: null,
    seUnmetNeed: null,
    seLogRModernNoUse: null,
    seLogRTraditionalNoUse: null,
    seLogRUnmetNoNeed: null,
    lowerContraceptiveUseAny: 0.5807 * 0.95,
    estimatedContraceptiveUseAny: 0.5807,
    upperContraceptiveUseAny: 0.5807 * 1.05,
    sourceId: "Bangladesh 2004 Demographic and Health Survey",
    recordId: "861"
  },
  {
    divisionNumericCode: 50,
    startDate: 1993.8329,
    endDate: 1994.2439,
    isInUnion: true,
    ageRange: "15-49",
    dataSeriesType: "Other",
    groupTypeRelativeToBaseline: "MW",
    contraceptiveUseModern: 0.3621,
    contraceptiveUseTraditional: 0.0836,
    contraceptiveUseAny: 0.4457,
    ratioModernAll: 0.8124,
    unmetNeedModern: 0.301,
    unmetNeedAny: 0.2174,
    demand: 0.6631,
    demandModern: 0.6631,
    demandSatisfied: 0.6721,
    demandSatisfiedModern: 0.5461,
    nonUse: 0.5543,
    isPertainingToMethodsUsedSinceLastPregnancy: false,
    pertainingToMethodsUsedSinceLastPregnancyReason: null,
    hasGeographicalRegionBias: false,
    geographicalRegionBiasReason: null,
    hasNonPregnantAndOtherPositiveBiases: false,
    nonPregnantAndOtherPositiveBiasesReason: null,
    ageGroupBias: "None",
    modernMethodBias: "None",
    hasTraditionalMethodBias: false,
    traditionalMethodBiasReason: null,
    hasAbsenceOfProbingQuestionsBias: false,
    seModern: null,
    seTraditional: null,
    seUnmetNeed: null,
    seLogRModernNoUse: null,
    seLogRTraditionalNoUse: null,
    seLogRUnmetNoNeed: null,
    sourceId: "Bangladesh 1993-1994 Demographic and Health Survey",
    recordId: "862"
  },
  {
    divisionNumericCode: 50,
    startDate: 1996.8356,
    endDate: 1997.2439,
    isInUnion: true,
    ageRange: "15-49",
    dataSeriesType: "Other",
    groupTypeRelativeToBaseline: "MW",
    contraceptiveUseModern: 0.4156,
    contraceptiveUseTraditional: 0.0768,
    contraceptiveUseAny: 0.4924,
    ratioModernAll: 0.8441,
    unmetNeedModern: 0.2748,
    unmetNeedAny: 0.198,
    demand: 0.6904,
    demandModern: 0.6904,
    demandSatisfied: 0.7132,
    demandSatisfiedModern: 0.602,
    nonUse: 0.5076,
    isPertainingToMethodsUsedSinceLastPregnancy: false,
    pertainingToMethodsUsedSinceLastPregnancyReason: null,
    hasGeographicalRegionBias: false,
    geographicalRegionBiasReason: null,
    hasNonPregnantAndOtherPositiveBiases: false,
    nonPregnantAndOtherPositiveBiasesReason: null,
    ageGroupBias: "None",
    modernMethodBias: "None",
    hasTraditionalMethodBias: false,
    traditionalMethodBiasReason: null,
    hasAbsenceOfProbingQuestionsBias: false,
    seModern: null,
    seTraditional: null,
    seUnmetNeed: null,
    seLogRModernNoUse: null,
    seLogRTraditionalNoUse: null,
    seLogRUnmetNoNeed: null,
    sourceId: "Bangladesh 1996-1997 Demographic and Health Survey",
    recordId: "863"
  },
  {
    divisionNumericCode: 50,
    startDate: 1999.8329,
    endDate: 2000.2466,
    isInUnion: true,
    ageRange: "15-49",
    dataSeriesType: "Other",
    groupTypeRelativeToBaseline: "MW",
    contraceptiveUseModern: 0.4347,
    contraceptiveUseTraditional: 0.103,
    contraceptiveUseAny: 0.5377,
    ratioModernAll: 0.8084,
    unmetNeedModern: 0.2877,
    unmetNeedAny: 0.1847,
    demand: 0.7224,
    demandModern: 0.7224,
    demandSatisfied: 0.7443,
    demandSatisfiedModern: 0.6017,
    nonUse: 0.4623,
    isPertainingToMethodsUsedSinceLastPregnancy: false,
    pertainingToMethodsUsedSinceLastPregnancyReason: null,
    hasGeographicalRegionBias: false,
    geographicalRegionBiasReason: null,
    hasNonPregnantAndOtherPositiveBiases: false,
    nonPregnantAndOtherPositiveBiasesReason: null,
    ageGroupBias: "None",
    modernMethodBias: "None",
    hasTraditionalMethodBias: false,
    traditionalMethodBiasReason: null,
    hasAbsenceOfProbingQuestionsBias: false,
    seModern: null,
    seTraditional: null,
    seUnmetNeed: null,
    seLogRModernNoUse: null,
    seLogRTraditionalNoUse: null,
    seLogRUnmetNoNeed: null,
    sourceId: "Bangladesh 1999-2000 Demographic and Health Survey",
    recordId: "864"
  },
  {
    divisionNumericCode: 50,
    startDate: 2007.1616,
    endDate: 2007.663,
    isInUnion: true,
    ageRange: "15-49",
    dataSeriesType: "Other",
    groupTypeRelativeToBaseline: "MW",
    contraceptiveUseModern: 0.4746,
    contraceptiveUseTraditional: 0.0833,
    contraceptiveUseAny: 0.5579,
    ratioModernAll: 0.8506,
    unmetNeedModern: 0.2512,
    unmetNeedAny: 0.1679,
    demand: 0.7258,
    demandModern: 0.7258,
    demandSatisfied: 0.7687,
    demandSatisfiedModern: 0.6539,
    nonUse: 0.4421,
    isPertainingToMethodsUsedSinceLastPregnancy: false,
    pertainingToMethodsUsedSinceLastPregnancyReason: null,
    hasGeographicalRegionBias: false,
    geographicalRegionBiasReason: null,
    hasNonPregnantAndOtherPositiveBiases: false,
    nonPregnantAndOtherPositiveBiasesReason: null,
    ageGroupBias: "None",
    modernMethodBias: "None",
    hasTraditionalMethodBias: false,
    traditionalMethodBiasReason: null,
    hasAbsenceOfProbingQuestionsBias: false,
    seModern: null,
    seTraditional: null,
    seUnmetNeed: null,
    seLogRModernNoUse: null,
    seLogRTraditionalNoUse: null,
    seLogRUnmetNoNeed: null,
    sourceId: "Bangladesh 2007 Demographic and Health Survey",
    recordId: "884"
  },
  {
    divisionNumericCode: 50,
    startDate: 1991,
    endDate: 1992,
    isInUnion: true,
    ageRange: "15-49",
    dataSeriesType: "National survey",
    groupTypeRelativeToBaseline: "MW",
    contraceptiveUseModern: 0.312,
    contraceptiveUseTraditional: 0.087,
    contraceptiveUseAny: 0.399,
    ratioModernAll: 0.782,
    unmetNeedModern: null,
    unmetNeedAny: null,
    demand: null,
    demandModern: null,
    demandSatisfied: null,
    demandSatisfiedModern: null,
    nonUse: 0.601,
    isPertainingToMethodsUsedSinceLastPregnancy: false,
    pertainingToMethodsUsedSinceLastPregnancyReason: null,
    hasGeographicalRegionBias: false,
    geographicalRegionBiasReason: null,
    hasNonPregnantAndOtherPositiveBiases: false,
    nonPregnantAndOtherPositiveBiasesReason: null,
    ageGroupBias: "?",
    modernMethodBias: "None",
    hasTraditionalMethodBias: false,
    traditionalMethodBiasReason: null,
    hasAbsenceOfProbingQuestionsBias: false,
    seModern: null,
    seTraditional: null,
    seUnmetNeed: null,
    seLogRModernNoUse: null,
    seLogRTraditionalNoUse: null,
    seLogRUnmetNoNeed: null,
    sourceId: "Bangladesh 1991 Contraceptive Prevalence Survey",
    recordId: "1051"
  },
  {
    divisionNumericCode: 50,
    startDate: 2011.4959,
    endDate: 2011.9973,
    isInUnion: true,
    ageRange: "15-49",
    dataSeriesType: "Other",
    groupTypeRelativeToBaseline: "MW",
    contraceptiveUseModern: 0.5205,
    contraceptiveUseTraditional: 0.0916,
    contraceptiveUseAny: 0.6121,
    ratioModernAll: 0.8503,
    unmetNeedModern: 0.2263,
    unmetNeedAny: 0.1347,
    demand: 0.7469,
    demandModern: 0.7469,
    demandSatisfied: 0.8196,
    demandSatisfiedModern: 0.6969,
    nonUse: 0.3879,
    isPertainingToMethodsUsedSinceLastPregnancy: false,
    pertainingToMethodsUsedSinceLastPregnancyReason: null,
    hasGeographicalRegionBias: false,
    geographicalRegionBiasReason: null,
    hasNonPregnantAndOtherPositiveBiases: false,
    nonPregnantAndOtherPositiveBiasesReason: null,
    ageGroupBias: "None",
    modernMethodBias: "None",
    hasTraditionalMethodBias: false,
    traditionalMethodBiasReason: null,
    hasAbsenceOfProbingQuestionsBias: false,
    seModern: null,
    seTraditional: null,
    seUnmetNeed: null,
    seLogRModernNoUse: null,
    seLogRTraditionalNoUse: null,
    seLogRUnmetNoNeed: null,
    sourceId: "Bangladesh 2011 Demographic and Health Survey",
    recordId: "1362"
  },
  {
    divisionNumericCode: 50,
    startDate: 2012.9178,
    endDate: 2013.326,
    isInUnion: true,
    ageRange: "15-49",
    dataSeriesType: "Other",
    groupTypeRelativeToBaseline: "MW",
    contraceptiveUseModern: 0.5927,
    contraceptiveUseTraditional: 0.0249,
    contraceptiveUseAny: 0.6176,
    ratioModernAll: 0.9597,
    unmetNeedModern: 0.1641,
    unmetNeedAny: 0.1392,
    demand: 0.7568,
    demandModern: 0.7568,
    demandSatisfied: 0.8161,
    demandSatisfiedModern: 0.7832,
    nonUse: 0.3824,
    isPertainingToMethodsUsedSinceLastPregnancy: false,
    pertainingToMethodsUsedSinceLastPregnancyReason: null,
    hasGeographicalRegionBias: false,
    geographicalRegionBiasReason: null,
    hasNonPregnantAndOtherPositiveBiases: false,
    nonPregnantAndOtherPositiveBiasesReason: null,
    ageGroupBias: "None",
    modernMethodBias: "None",
    hasTraditionalMethodBias: false,
    traditionalMethodBiasReason: null,
    hasAbsenceOfProbingQuestionsBias: true,
    seModern: null,
    seTraditional: null,
    seUnmetNeed: null,
    seLogRModernNoUse: null,
    seLogRTraditionalNoUse: null,
    seLogRUnmetNoNeed: null,
    sourceId: "Bangladesh 2012-2013 Multiple Indicator Cluster Survey",
    recordId: "1540"
  },
  {
    divisionNumericCode: 50,
    startDate: 2014.4959,
    endDate: 2014.8301,
    isInUnion: true,
    ageRange: "15-49",
    dataSeriesType: "Other",
    groupTypeRelativeToBaseline: "MW",
    contraceptiveUseModern: 0.5406,
    contraceptiveUseTraditional: 0.0839,
    contraceptiveUseAny: 0.6245,
    ratioModernAll: 0.8656,
    unmetNeedModern: 0.204,
    unmetNeedAny: 0.1201,
    demand: 0.7446,
    demandModern: 0.7446,
    demandSatisfied: 0.8387,
    demandSatisfiedModern: 0.726,
    nonUse: 0.3755,
    isPertainingToMethodsUsedSinceLastPregnancy: false,
    pertainingToMethodsUsedSinceLastPregnancyReason: null,
    hasGeographicalRegionBias: false,
    geographicalRegionBiasReason: null,
    hasNonPregnantAndOtherPositiveBiases: false,
    nonPregnantAndOtherPositiveBiasesReason: null,
    ageGroupBias: "None",
    modernMethodBias: "None",
    hasTraditionalMethodBias: false,
    traditionalMethodBiasReason: null,
    hasAbsenceOfProbingQuestionsBias: false,
    seModern: null,
    seTraditional: null,
    seUnmetNeed: null,
    seLogRModernNoUse: null,
    seLogRTraditionalNoUse: null,
    seLogRUnmetNoNeed: null,
    sourceId: "Bangladesh 2014 Demographic and Health Survey",
    recordId: "1586"
  }
].map(datum => ({
  ...datum,
  lowerContraceptiveUseAny: datum.contraceptiveUseAny - 0.04,
  estimatedContraceptiveUseAny: datum.contraceptiveUseAny,
  upperContraceptiveUseAny: datum.contraceptiveUseAny + 0.04
}));
