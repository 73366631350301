import React from "react";
import * as PropTypes from "prop-types";
import { formatDateTime } from "../utilities";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import InfoIcon from "@material-ui/icons/Info";
import { withTranslation } from "react-i18next";
import Loading from "./Loading";
import Notification from "./Notification";

const styles = {
  button: {
    marginTop: 20
  },
  failed: {
    color: "red"
  },
  note: {
    marginBottom: 0
  },
  succeeded: {
    color: "green"
  },
  table: {
    width: "initial"
  }
};

const RunHistory = ({ t, ...rest }) => (
  <Loading loading={rest.loadingData} message={t("Loading runs...")}>
    <p className={rest.classes.note}>
      {t("The run history will refresh automatically.")}
    </p>

    <Table className={rest.classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>{t("Run name")}</TableCell>
          <TableCell>{t("Started at")}</TableCell>
          <TableCell />
          <TableCell />
        </TableRow>
      </TableHead>

      <TableBody>
        {rest.runs
          .sort((a, b) => b.startedAt - a.startedAt)
          .map(run => (
            <TableRow key={run.name}>
              <TableCell>
                {run.completed && !run.failed ? (
                  <Tooltip title={t("Load run")}>
                    <Link to={`/viewrun/survey?run=${run.id}`}>{run.name}</Link>
                  </Tooltip>
                ) : (
                  <span>{run.name}</span>
                )}
              </TableCell>
              <TableCell>{formatDateTime(run.startedAt)}</TableCell>
              <TableCell>
                <Tooltip
                  title={
                    run.completed
                      ? !run.failed
                        ? t("Run has completed")
                        : t("Run failed")
                      : t("Run is in progress")
                  }
                >
                  {run.completed ? (
                    <HourglassFullIcon
                      className={
                        !run.failed
                          ? rest.classes.succeeded
                          : rest.classes.failed
                      }
                    />
                  ) : (
                    <HourglassEmptyIcon />
                  )}
                </Tooltip>
              </TableCell>
              <TableCell>
                <IconButton
                  onClick={() => {
                    rest.onDownloadRunLog(run.id);
                  }}
                >
                  <Tooltip title={t("Log")}>
                    <InfoIcon />
                  </Tooltip>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>

    <Notification
      variant={rest.notificationVariant}
      open={rest.notificationOpen}
      message={rest.notificationMessage}
      onClose={rest.onCloseNotification}
    />
  </Loading>
);

RunHistory.propTypes = {
  loadingData: PropTypes.bool,
  runs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      startedAt: PropTypes.instanceOf(Date).isRequired,
      completed: PropTypes.bool.isRequired
    })
  ),
  notificationVariant: PropTypes.string,
  notificationOpen: PropTypes.bool,
  notificationMessage: PropTypes.string,
  onDownloadRunLog: PropTypes.func.isRequired,
  onCloseNotification: PropTypes.func.isRequired
};

RunHistory.defaultProps = {
  loadingData: false,
  runs: [],
  notificationOpen: false
};

export default withTranslation()(withStyles(styles)(RunHistory));
