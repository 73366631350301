import React from "react";
import * as PropTypes from "prop-types";
import contentWithDrawer from "../contentWithDrawer";
import DrawerContent from "./StartRunDrawerContent";
import MainContent from "./StartRunMainContent";
import Notification from "../Notification";

const Component = contentWithDrawer(MainContent, DrawerContent);

const StartRun = ({
  notificationVariant,
  notificationOpen,
  notificationMessage,
  onCloseNotification,
  ...rest
}) => (
  <div>
    <Component {...rest} />

    <Notification
      variant={notificationVariant}
      open={notificationOpen}
      message={notificationMessage}
      onClose={onCloseNotification}
    />
  </div>
);

StartRun.propTypes = {
  notificationVariant: PropTypes.string,
  notificationOpen: PropTypes.bool,
  notificationMessage: PropTypes.string,
  onCloseNotification: PropTypes.func.isRequired
};

StartRun.defaultProps = {
  notificationOpen: false
};

export default StartRun;
