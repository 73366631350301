import React from "react";
import * as PropTypes from "prop-types";

const markerSize = 6;
const enclosureStrokeWidth = 1.5;
const fontSize = 8;

const calculateMarkerDimensions = markerSize => {
  const scaleFactor = markerSize / 10;
  const triangleScaleFactor = 0.9;

  const triangleX = 13.46774 * scaleFactor * triangleScaleFactor;
  const triangleY = 11.6634 * scaleFactor * triangleScaleFactor;

  return {
    circle: markerSize,
    square: 17.72454 * scaleFactor,
    triangle: `0,${-triangleY},${-triangleX},${triangleY},${triangleX},${triangleY}`,
    invertedTriangle: `0,${triangleY},${-triangleX},${-triangleY},${triangleX},${-triangleY}`
  };
};

const markerDimensions = calculateMarkerDimensions(markerSize);

const createEnclosureComponent = (
  shape,
  fillColor,
  strokeColor,
  markerDimensions
) => {
  switch (shape) {
    case "square":
      return (
        <rect
          x={-markerDimensions.square / 2}
          y={-markerDimensions.square / 2}
          width={markerDimensions.square}
          height={markerDimensions.square}
          fill={fillColor}
          stroke={strokeColor}
          strokeWidth={enclosureStrokeWidth}
        />
      );

    case "triangle":
      return (
        <polygon
          points={markerDimensions.triangle}
          fill={fillColor}
          stroke={strokeColor}
          strokeWidth={enclosureStrokeWidth}
        />
      );

    case "inverted triangle":
      return (
        <polygon
          points={markerDimensions.invertedTriangle}
          fill={fillColor}
          stroke={strokeColor}
          strokeWidth={enclosureStrokeWidth}
        />
      );

    default:
      return (
        <circle
          cx={0}
          cy={0}
          r={markerDimensions.circle}
          fill={fillColor}
          stroke={strokeColor}
          strokeWidth={enclosureStrokeWidth}
        />
      );
  }
};

const ChartsMarker = props => {
  let labelVerticalOffset = 1;

  if (props.shape === "triangle") {
    labelVerticalOffset = 6;
  } else if (props.shape === "inverted triangle") {
    labelVerticalOffset = -2;
  }

  return (
    <g>
      {createEnclosureComponent(
        props.shape,
        props.fillColor,
        props.strokeColor,
        markerDimensions
      )}
      <text
        x={0}
        y={0}
        dy={labelVerticalOffset}
        textAnchor="middle"
        alignmentBaseline="middle"
        fontSize={fontSize}
        fontWeight={200}
        fill={props.textColor}
        stroke={props.textColor}
      >
        {props.label}
      </text>
    </g>
  );
};

ChartsMarker.propTypes = {
  shape: PropTypes.oneOf(["circle", "triangle", "inverted triangle", "square"]),
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
  textColor: PropTypes.string,
  label: PropTypes.string
};

ChartsMarker.defaultProps = {
  shape: "circle",
  fillColor: "white",
  strokeColor: "black",
  textColor: "black",
  label: ""
};

export default ChartsMarker;
