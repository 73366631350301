import React from "react";
import { withStyles } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import { withTranslation } from "react-i18next";

const styles = theme => ({
  message: {
    display: "flex",
    alignItems: "center"
  },
  icon: {
    width: "1.5em",
    height: "1.5em",
    color: theme.palette.error.dark
  },
  text: {
    marginLeft: 10
  }
});

const Oops = ({ classes, t }) => (
  <div>
    <h1>{t("Oops!")}</h1>

    <div className={classes.message}>
      <ErrorIcon className={classes.icon} />

      <div className={classes.text}>
        <div
          dangerouslySetInnerHTML={{
            __html: t("We're sorry&mdash;something's gone wrong.")
          }}
        />
        <div>{t("Our team has been notified.")}</div>
      </div>
    </div>
  </div>
);

export default withTranslation()(withStyles(styles)(Oops));
