import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { measurePropType, surveyDatumPropType } from "../../propTypes";
import { indicators } from "../../constants";
import { getScaledResults } from "../../data";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import {
  AreaSeries,
  Crosshair,
  CustomSVGSeries,
  LineSeries,
  MarkSeries,
  WhiskerSeries,
  XAxis,
  XYPlot,
  YAxis
} from "react-vis/es";
import "react-vis/dist/style.css";
import { withTranslation } from "react-i18next";
import ChartsSurveyMarker from "./ChartsSurveyMarker";

const styles = {
  crosshair: {
    backgroundColor: "black",
    whiteSpace: "nowrap"
  },
  table: {
    textAlign: "right"
  },
  title: {
    marginBottom: 20,
    textAlign: "center",
    fontSize: "smaller"
  }
};

class ChartsConfidenceIntervalChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      crosshairValues: []
    };
  }

  getErrors = () =>
    this.props.surveys.reduce((result, survey) => {
      let error;

      if (
        this.props.indicator === "contraceptiveUseAny" &&
        survey.estimatedContraceptiveUseAny
      ) {
        error = {
          lower: survey.lowerContraceptiveUseAny,
          estimate: survey.estimatedContraceptiveUseAny,
          upper: survey.upperContraceptiveUseAny
        };
      } else if (
        this.props.indicator === "contraceptiveUseModern" &&
        survey.estimatedContraceptiveUseModern
      ) {
        error = {
          lower: survey.lowerContraceptiveUseModern,
          estimate: survey.estimatedContraceptiveUseModern,
          upper: survey.upperContraceptiveUseModern
        };
      } else if (
        this.props.indicator === "contraceptiveUseTraditional" &&
        survey.estimatedContraceptiveUseTraditional
      ) {
        error = {
          lower: survey.lowerContraceptiveUseTraditional,
          estimate: survey.estimatedContraceptiveUseTraditional,
          upper: survey.upperContraceptiveUseTraditional
        };
      } else if (
        this.props.indicator === "unmetNeedAny" &&
        survey.estimatedUnmetNeedAny
      ) {
        error = {
          lower: survey.lowerUnmetNeedAny,
          estimate: survey.estimatedUnmetNeedAny,
          upper: survey.upperUnmetNeedAny
        };
      }

      if (error) {
        result.push({
          startDate: survey.startDate,
          endDate: survey.endDate,
          isInUnion: survey.isInUnion,
          ...error
        });
      }

      return result;
    }, []);

  formatCrosshairValue = value =>
    this.props.measure === "percentage"
      ? `${(value * 100).toFixed(1)}%`
      : Math.floor(value / 1000).toFixed(0);

  onNearestX = (value, { index }) => {
    const datum = this.props.data[index];

    this.setState({
      crosshairValues: [
        {
          x: datum.year,
          y: datum.percentile2pt5
        },
        {
          x: datum.year,
          y: datum.percentile10
        },
        {
          x: datum.year,
          y: datum.median
        },
        {
          x: datum.year,
          y: datum.percentile90
        },
        {
          x: datum.year,
          y: datum.percentile97pt5
        }
      ]
    });
  };

  onMouseLeave = () => {
    this.setState({ crosshairValues: [] });
  };

  render() {
    const demandLabel = indicators.find(
      indicator => indicator.value === "demand"
    ).label;

    const crosshairValues = this.state.crosshairValues;

    const data = getScaledResults(this.props.measure, this.props.data);

    const indicatorErrors = this.getErrors();

    return (
      <div>
        <Tooltip title={this.props.description}>
          <div className={this.props.classes.title}>
            {this.props.t(
              this.props.indicator === "demandModern"
                ? demandLabel
                : this.props.title
            )}
          </div>
        </Tooltip>

        <XYPlot
          width={this.props.width}
          height={this.props.height}
          margin={{
            left: 50,
            right: 10,
            top: 10,
            bottom: 56
          }}
          xDomain={this.props.xDomain}
          onMouseLeave={this.onMouseLeave}
        >
          <XAxis
            tickFormat={v => v}
            tickSizeInner={0}
            style={{
              line: {
                stroke: "grey"
              },
              text: {
                fontSize: 10
              }
            }}
          />
          <YAxis
            tickFormat={v =>
              this.props.measure === "percentage"
                ? `${(v * 100).toFixed(0)}%`
                : v
            }
            tickSizeInner={0}
            style={{
              line: {
                stroke: "grey"
              },
              text: {
                fontSize: 10
              }
            }}
          />

          <AreaSeries
            data={data.map(datum => ({
              x: datum.year,
              y: datum.percentile2pt5,
              y0: datum.percentile97pt5
            }))}
            color="lavender"
            opacity={!this.props.comparison ? 1.0 : 0.5}
          />

          {this.props.comparison && (
            <AreaSeries
              data={data.map(datum => ({
                x: datum.year,
                y: datum.comparisonPercentile2pt5,
                y0: datum.comparisonPercentile97pt5
              }))}
              color="plum"
              opacity={0.5}
            />
          )}

          {!this.props.comparison && (
            <AreaSeries
              data={data.map(datum => ({
                x: datum.year,
                y: datum.percentile10,
                y0: datum.percentile90
              }))}
              color="plum"
            />
          )}

          {this.props.comparison && (
            <LineSeries
              data={data.map(datum => ({
                x: datum.year,
                y: datum.percentile10
              }))}
              style={{
                fill: "none"
              }}
              color="black"
            />
          )}

          {this.props.comparison && (
            <LineSeries
              data={data.map(datum => ({
                x: datum.year,
                y: datum.comparisonPercentile10
              }))}
              style={{
                fill: "none"
              }}
              strokeStyle="dashed"
              color="black"
            />
          )}

          {this.props.comparison && (
            <LineSeries
              data={data.map(datum => ({
                x: datum.year,
                y: datum.percentile90
              }))}
              style={{
                fill: "none"
              }}
              color="black"
            />
          )}

          {this.props.comparison && (
            <LineSeries
              data={data.map(datum => ({
                x: datum.year,
                y: datum.comparisonPercentile90
              }))}
              style={{
                fill: "none"
              }}
              strokeStyle="dashed"
              color="black"
            />
          )}

          <LineSeries
            data={data.map(datum => ({
              x: datum.year,
              y: datum.median
            }))}
            style={{
              fill: "none"
            }}
            color="black"
            onNearestX={this.onNearestX}
          />

          {this.props.comparison && (
            <LineSeries
              data={data.map(datum => ({
                x: datum.year,
                y: datum.comparisonMedian
              }))}
              style={{
                fill: "none"
              }}
              strokeStyle="dashed"
              color="black"
            />
          )}

          {this.props.measure === "percentage" &&
            ![
              // "unmetNeedModern",
              // "demandModern",
              // "demandSatisfiedModern"
            ].includes(this.props.indicator) && (
              <CustomSVGSeries
                data={this.props.surveys.map(survey => ({
                  x: (survey.startDate + survey.endDate) / 2,
                  y: survey[this.props.y] || 0,
                  customComponent: () => (
                    <ChartsSurveyMarker
                      dataSeriesType={survey.dataSeriesType}
                      groupTypeRelativeToBaseline={
                        survey.groupTypeRelativeToBaseline
                      }
                      ageGroupBias={survey.ageGroupBias}
                      hasTraditionalMethodBias={survey.hasTraditionalMethodBias}
                      modernMethodBias={survey.modernMethodBias}
                    />
                  )
                }))}
              />
            )}

          {this.props.measure === "percentage" && (
            <MarkSeries
              sizeRange={[3, 3]}
              data={this.props.emuData.map(datum => ({
                x: datum.startYear + 0.5,
                y: datum.emu,
                size: 3
              }))}
              style={{
                fill: "black"
              }}
              color="none"
            />
          )}

          {this.props.measure === "percentage" && (
            <WhiskerSeries
              data={indicatorErrors.map(error => ({
                x: (error.startDate + error.endDate) / 2,
                y: (error.lower + error.upper) / 2,
                yVariance: error.upper - error.lower
              }))}
              style={{
                fill: "none"
              }}
              color="black"
            />
          )}

          <Crosshair values={crosshairValues}>
            {crosshairValues.length && (
              <div className={this.props.classes.crosshair}>
                <table>
                  <tbody className={this.props.classes.table}>
                    <tr>
                      <td>{this.props.t("Year")}</td>
                      <td>{crosshairValues[0].x}</td>
                    </tr>
                    <tr>
                      <td>2.5%</td>
                      <td>{this.formatCrosshairValue(crosshairValues[0].y)}</td>
                    </tr>
                    <tr>
                      <td>10.0%</td>
                      <td>{this.formatCrosshairValue(crosshairValues[1].y)}</td>
                    </tr>
                    <tr>
                      <td>50.0%</td>
                      <td>{this.formatCrosshairValue(crosshairValues[2].y)}</td>
                    </tr>
                    <tr>
                      <td>90.0%</td>
                      <td>{this.formatCrosshairValue(crosshairValues[3].y)}</td>
                    </tr>
                    <tr>
                      <td>97.5%</td>
                      <td>{this.formatCrosshairValue(crosshairValues[4].y)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </Crosshair>
        </XYPlot>
      </div>
    );
  }
}

ChartsConfidenceIntervalChart.propTypes = {
  comparison: PropTypes.bool,
  measure: measurePropType.isRequired,
  indicator: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  xDomain: PropTypes.arrayOf(PropTypes.number),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.number.isRequired,
      percentile2pt5: PropTypes.number.isRequired,
      percentile10: PropTypes.number.isRequired,
      median: PropTypes.number.isRequired,
      percentile90: PropTypes.number.isRequired,
      percentile97pt5: PropTypes.number.isRequired,
      comparisonPercentile2pt5: PropTypes.number,
      comparisonPercentile10: PropTypes.number,
      comparisonMedian: PropTypes.number,
      comparisonPercentile90: PropTypes.number,
      comparisonPercentile97pt5: PropTypes.number
    })
  ),
  surveys: PropTypes.arrayOf(surveyDatumPropType),
  y: PropTypes.string.isRequired,
  tickValues: PropTypes.arrayOf(PropTypes.number),
  shadeInnerBand: PropTypes.bool
};

ChartsConfidenceIntervalChart.defaultProps = {
  description: "",
  comparison: false,
  width: 300,
  height: 300,
  data: [],
  surveys: [],
  emuData: []
};

export default withTranslation()(
  withStyles(styles)(ChartsConfidenceIntervalChart)
);
