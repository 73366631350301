import React from "react";
import * as PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withTranslation } from "react-i18next";

const Title = ({ title, t }) => (
  <Typography variant="subtitle1">
    {title || t("Run has not been fully defined")}
  </Typography>
);

Title.propTypes = {
  title: PropTypes.string
};

export default withTranslation()(Title);
