import React from "react";
import * as PropTypes from "prop-types";

const BooleanCellRenderer = props => <span>{props.value ? "✔" : "✘"}</span>;

BooleanCellRenderer.propTypes = {
  value: PropTypes.bool
};

export default BooleanCellRenderer;
