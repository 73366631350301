import React, { Component } from "react";
import LogRocket from "logrocket";
import authentication from "../authentication";
import { Redirect } from "react-router-dom";
import * as api from "../api/api";
import i18next from "i18next";
import Login from "../components/Login";

class LoginContainer extends Component {
  constructor(props) {
    super(props);

    this.signal = api.getSignal();

    this.state = {
      rememberMe: false
    };
  }

  setStateSafely = (state, callback) => {
    if (!this.unmounting) {
      this.setState(state, callback);
    }
  };

  onChangeUsername = username => {
    this.setStateSafely({
      username
    });
  };

  onChangePassword = password => {
    this.setStateSafely({
      password
    });
  };

  onToggleRememberMe = () => {
    this.setStateSafely({
      rememberMe: !this.state.rememberMe
    });
  };

  onSubmit = () => {
    authentication.login(
      this.state.username,
      this.state.password,
      error => {
        if (!error) {
          LogRocket.identify(this.state.username);
        }

        this.setStateSafely({
          redirectToReferrer: !error,
          error: error
            ? `${this.state.username} could not be authenticated`
            : undefined
        });
      },
      this.signal.token
    );
  };

  onChangeLanguage = language => {
    // noinspection JSIgnoredPromiseFromCall
    i18next.changeLanguage(language);
  };

  componentDidMount() {
    api.fetchSystemStatus().then(response => {
      if (!response) {
        return;
      }

      this.setStateSafely({
        systemOperational: response.data.systemOperational,
        systemStatusMessage: response.data.message
      });
    });
  }

  componentWillUnmount() {
    this.unmounting = true;
    this.signal.cancel();
  }

  render() {
    if (this.state.redirectToReferrer) {
      const { from } = this.props.location.state || { from: { pathname: "/" } };

      return <Redirect to={from} />;
    }

    return (
      <Login
        systemOperational={this.state.systemOperational}
        systemStatusMessage={this.state.systemStatusMessage}
        username={this.state.username}
        password={this.state.password}
        rememberMe={this.state.rememberMe}
        error={this.state.error}
        onChangeUsername={this.onChangeUsername}
        onChangePassword={this.onChangePassword}
        onToggleRememberMe={this.onToggleRememberMe}
        onSubmit={this.onSubmit}
        onChangeLanguage={this.onChangeLanguage}
      />
    );
  }
}

export default LoginContainer;
