import React from "react";
import * as PropTypes from "prop-types";
import { managedLabeledValuePropType } from "../../propTypes";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";
import ManageDataItems from "./ManageDataItems";

const styles = {
  button: {
    marginRight: 20
  },
  buttonGroup: {
    marginTop: 20
  }
};

const ManageDataRuns = ({ t, ...rest }) => (
  <div>
    <ManageDataItems
      items={rest.runs}
      selectedItemValues={rest.selectedRunIds}
      onToggleItem={rest.onToggleRun}
    />

    <div className={rest.classes.buttonGroup}>
      <Button
        variant="contained"
        color="secondary"
        disabled={
          !rest.selectedRunIds.length ||
          rest.runs.filter(
            run => rest.selectedRunIds.includes(run.value) && run.isGlobal
          ).length
        }
        className={rest.classes.button}
        onClick={rest.onDeleteRuns}
      >
        {t("Delete selected runs")}
      </Button>
    </div>

    <p>
      <sup>*</sup>{t("Global runs cannot be deleted.")}
    </p>
  </div>
);

ManageDataRuns.propTypes = {
  runs: PropTypes.arrayOf(managedLabeledValuePropType),
  selectedRunIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleRun: PropTypes.func.isRequired,
  onDeleteRuns: PropTypes.func.isRequired
};

ManageDataRuns.defaultProps = {
  runs: []
};

export default withTranslation()(withStyles(styles)(ManageDataRuns));
