import React from "react";
import * as PropTypes from "prop-types";
import {
  emuDatumPropType,
  populationDatumPropType,
  surveyDatumPropType
} from "../../propTypes";
import { Route, Switch, Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import EmuData from "../EmuData";
import PopulationData from "../PopulationData";
import NavigationTab from "../NavigationTab";
import SurveyData from "../SurveyData";
import Title from "../Title";

const styles = {
  tabPage: {
    margin: 10
  },
  title: {
    marginBottom: 20
  }
};

const StartRunMainContent = ({ match, t, ...rest }) => (
  <div>
    <div className={rest.classes.title}>
      <Title title={rest.runTitle} />
    </div>

    <NavigationTab to="/startrun/survey">{t("Survey data")}</NavigationTab>
    <NavigationTab to="/startrun/population">
      {t("Population data")}
    </NavigationTab>
    <NavigationTab to="/startrun/emu">{t("Service statistics")}</NavigationTab>

    <div className={rest.classes.tabPage}>
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => <Redirect replace to={`${match.path}/survey`} />}
        />
        <Route
          path={`${match.path}/survey`}
          render={() => <SurveyData data={rest.surveyData} />}
        />
        <Route
          path={`${match.path}/population`}
          render={() => <PopulationData data={rest.populationData} />}
        />
        <Route
          path={`${match.path}/emu`}
          render={() => <EmuData data={rest.emuData} />}
        />
      </Switch>
    </div>
  </div>
);

StartRunMainContent.propTypes = {
  runTitle: PropTypes.string,
  surveyData: PropTypes.arrayOf(surveyDatumPropType),
  populationData: PropTypes.arrayOf(populationDatumPropType),
  emuData: PropTypes.arrayOf(emuDatumPropType)
};

StartRunMainContent.defaultProps = {
  surveyData: [],
  populationData: [],
  emuData: []
};

export default withTranslation()(withStyles(styles)(StartRunMainContent));
