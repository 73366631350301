import * as PropTypes from "prop-types";

export const databasePropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
});

export const databaseTypePropType = PropTypes.oneOf([
  "default",
  "custom",
  "external"
]);

export const deltaPropType = PropTypes.oneOf(["total", "additional"])
  .isRequired;

export const directionPropType = PropTypes.oneOf(["greaterThan", "lessThan"])
  .isRequired;

export const emuDatumPropType = PropTypes.shape({
  id: PropTypes.string,
  startYear: PropTypes.number.isRequired,
  endYear: PropTypes.number.isRequired,
  emu: PropTypes.number.isRequired,
  serviceStatisticType: PropTypes.string.isRequired
});

export const indicatorResultPropType = PropTypes.shape({
  isInUnion: PropTypes.bool,
  ageRange: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  mean: PropTypes.number.isRequired,
  percentile2pt5: PropTypes.number.isRequired,
  percentile5: PropTypes.number.isRequired,
  percentile10: PropTypes.number.isRequired,
  median: PropTypes.number.isRequired,
  percentile90: PropTypes.number.isRequired,
  percentile95: PropTypes.number.isRequired,
  percentile97pt5: PropTypes.number.isRequired,
  comparatorMean: PropTypes.number,
  comparatorPercentile2pt5: PropTypes.number,
  comparatorPercentile5: PropTypes.number,
  comparatorPercentile10: PropTypes.number,
  comparatorMedian: PropTypes.number,
  comparatorPercentile90: PropTypes.number,
  comparatorPercentile95: PropTypes.number,
  comparatorPercentile97pt5: PropTypes.number
});

export const labeledValuePropType = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
});

export const managedLabeledValuePropType = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isGlobal: PropTypes.bool,
  isInUse: PropTypes.bool,
  createdAt: PropTypes.instanceOf(Date)
});

export const maritalStatusPropType = PropTypes.oneOf([
  "married",
  "unmarried",
  "all_women"
]).isRequired;

export const measurePropType = PropTypes.oneOf(["percentage", "count"]);

export const periodPropType = PropTypes.shape({
  firstYear: PropTypes.number.isRequired,
  lastYear: PropTypes.number.isRequired
}).isRequired;

export const populationDatumPropType = PropTypes.shape({
  id: PropTypes.string,
  isInUnion: PropTypes.bool.isRequired,
  ageRange: PropTypes.string.isRequired,
  midYear: PropTypes.number.isRequired,
  population: PropTypes.number.isRequired
});

export const resultPropType = PropTypes.shape({
  contraceptiveUseAny: PropTypes.arrayOf(indicatorResultPropType).isRequired,
  contraceptiveUseModern: PropTypes.arrayOf(indicatorResultPropType),
  contraceptiveUseTraditional: PropTypes.arrayOf(indicatorResultPropType),
  unmetNeedAny: PropTypes.arrayOf(indicatorResultPropType).isRequired,
  unmetNeedModern: PropTypes.arrayOf(indicatorResultPropType).isRequired,
  demand: PropTypes.arrayOf(indicatorResultPropType).isRequired,
  demandSatisfied: PropTypes.arrayOf(indicatorResultPropType).isRequired,
  demandSatisfiedModern: PropTypes.arrayOf(indicatorResultPropType).isRequired,
  ratioModernAny: PropTypes.arrayOf(indicatorResultPropType)
});

export const surveyDatumPropType = PropTypes.shape({
  id: PropTypes.string,
  startDate: PropTypes.number.isRequired,
  endDate: PropTypes.number.isRequired,
  isInUnion: PropTypes.bool.isRequired,
  ageRange: PropTypes.string.isRequired,
  dataSeriesType: PropTypes.string.isRequired,
  groupTypeRelativeToBaseline: PropTypes.string.isRequired,
  contraceptiveUseModern: PropTypes.number,
  contraceptiveUseTraditional: PropTypes.number,
  contraceptiveUseAny: PropTypes.number,
  ratioModernAny: PropTypes.number,
  unmetNeedModern: PropTypes.number,
  unmetNeedAny: PropTypes.number,
  demand: PropTypes.number,
  demandModern: PropTypes.number,
  demandSatisfied: PropTypes.number,
  demandSatisfiedModern: PropTypes.number,
  nonUse: PropTypes.number,
  isPertainingToMethodsUsedSinceLastPregnancy: PropTypes.bool.isRequired,
  pertainingToMethodsUsedSinceLastPregnancyReason: PropTypes.string,
  hasGeographicalRegionBias: PropTypes.bool.isRequired,
  geographicalRegionBiasReason: PropTypes.string,
  hasNonPregnantAndOtherPositiveBiases: PropTypes.bool.isRequired,
  nonPregnantAndOtherPositiveBiasesReason: PropTypes.string,
  ageGroupBias: PropTypes.oneOf(["+", "-", "?", "None"]),
  modernMethodBias: PropTypes.oneOf(["+", "-", "None"]),
  hasTraditionalMethodBias: PropTypes.bool.isRequired,
  traditionalMethodBiasReason: PropTypes.string,
  hasAbsenceOfProbingQuestionsBias: PropTypes.bool.isRequired,
  seModern: PropTypes.number,
  seTraditional: PropTypes.number,
  seUnmetNeed: PropTypes.number,
  seLogRModernNoUse: PropTypes.number,
  seLogRTraditionalNoUse: PropTypes.number,
  seLogRUnmetNoNeed: PropTypes.number,
  lowerContraceptiveUseAny: PropTypes.number,
  estimatedContraceptiveUseAny: PropTypes.number,
  upperContraceptiveUseAny: PropTypes.number,
  lowerContraceptiveUseModern: PropTypes.number,
  estimatedContraceptiveUseModern: PropTypes.number,
  upperContraceptiveUseModern: PropTypes.number,
  lowerContraceptiveUseTraditional: PropTypes.number,
  estimatedContraceptiveUseTraditional: PropTypes.number,
  upperContraceptiveUseTraditional: PropTypes.number,
  lowerUnmetNeedAny: PropTypes.number,
  estimatedUnmetNeedAny: PropTypes.number,
  upperUnmetNeedAny: PropTypes.number,
  sourceId: PropTypes.string,
  recordId: PropTypes.string
});
