import { createMuiTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";

const theme = createMuiTheme({
  palette: {
    background: {
      default: "#fff"
    },
    primary: {
      main: blue[900]
    }
  },
  typography: {
    useNextVariants: true
  }
});

export default theme;
