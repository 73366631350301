import React, { Component } from "react";
import { defaultFirstYear, minimumYear, maximumYear } from "../constants";
import { getDefaultLastYear } from "../utilities";
import * as api from "../api/api";
import {
  filterPopulationData,
  filterSurveyData,
  normalizeDatabases,
  normalizeEmuData,
  normalizePopulationData,
  normalizeSurveyData
} from "../data";
import { withErrorBoundary } from "../errors";
import { withTranslation } from "react-i18next";
import StartRun from "../components/StartRun/StartRun";

const createInitialState = () => {
  return {
    emuDatabaseType: "custom",
    period: {
      firstYear: defaultFirstYear,
      lastYear: getDefaultLastYear()
    }
  };
};

const removeFileExtension = filename => filename.replace(/\.[^/.]+$/, "");

const getDatabaseName = file => file && removeFileExtension(file.name);

class StartRunContainer extends Component {
  constructor(props) {
    super(props);

    this.signal = api.getSignal();

    this.state = createInitialState();
  }

  setStateSafely = (state, callback) => {
    if (!this.unmounting) {
      this.setState(state, callback);
    }
  };

  uploadDatabase = (file, databaseType, onProgress) => {
    const data = new FormData();

    data.append("file", file, file.name);

    return api.uploadDatabase(
      data,
      databaseType,
      onProgress,
      this.signal.token
    );
  };

  getCountryIntersection = (
    surveyDatabaseId,
    populationDatabaseId,
    emuDatabaseId
  ) => {
    if (!surveyDatabaseId || !populationDatabaseId) {
      return {};
    }

    const surveyDatabase = this.state.databases[surveyDatabaseId];
    const populationDatabase = this.state.databases[populationDatabaseId];
    const emuDatabase = emuDatabaseId && this.state.databases[emuDatabaseId];

    return Object.keys(surveyDatabase.divisions).reduce(
      (result, divisionNumericCode) => {
        const division = surveyDatabase.divisions[divisionNumericCode];

        if (
          division.isCountry &&
          populationDatabase.divisions[divisionNumericCode] &&
          (!emuDatabase || emuDatabase.divisions[divisionNumericCode])
        ) {
          result[divisionNumericCode] = division;
        }

        return result;
      },
      {}
    );
  };

  getRegionIntersection = (
    surveyDatabaseId,
    populationDatabaseId,
    emuDatabaseId
  ) => {
    if (!surveyDatabaseId || !populationDatabaseId) {
      return [];
    }

    const surveyRegions =
      this.state.databases[surveyDatabaseId].regionCodes || [];

    const populationRegionSet = new Set(
      this.state.databases[populationDatabaseId].regionCodes || []
    );

    const emuRegionSet =
      emuDatabaseId &&
      new Set(this.state.databases[emuDatabaseId].regionCodes || []);

    return surveyRegions.reduce((result, region) => {
      if (
        populationRegionSet.has(region) &&
        (!emuRegionSet || emuRegionSet.has(region))
      ) {
        result.push(region);
      }

      return result;
    }, []);
  };

  selectDefaultCountryOrRegion = (
    surveyDatabaseId,
    populationDatabaseId,
    emuDatabaseId
  ) => {
    const countries = this.getCountryIntersection(
      surveyDatabaseId,
      populationDatabaseId,
      emuDatabaseId
    );

    const countryNumericCodes = Object.keys(countries);

    const regions = this.getRegionIntersection(
      surveyDatabaseId,
      populationDatabaseId,
      emuDatabaseId
    );

    if (countryNumericCodes.length + regions.length === 1) {
      if (countryNumericCodes.length) {
        this.setStateSafely({
          selectedCountryNumericCode: parseInt(countryNumericCodes[0], 10)
        });
      } else {
        this.setStateSafely({
          selectedRegion: regions[0]
        });
      }
    }
  };

  createRunTitle = () => {
    let countryAlphaCodeOrRegion;

    if (this.state.selectedRegion) {
      countryAlphaCodeOrRegion = this.state.selectedRegion;
    } else if (this.state.selectedCountryNumericCode) {
      countryAlphaCodeOrRegion = this.state.databases[
        this.state.selectedSurveyDatabaseId
      ].divisions[this.state.selectedCountryNumericCode].alphaCode;
    }

    if (countryAlphaCodeOrRegion) {
      const { firstYear, lastYear } = this.state.period;

      return this.props.t(
        "Defining run for {{countryAlphaCodeOrRegion}} during {{firstYear}}-{{lastYear}}",
        {
          countryAlphaCodeOrRegion,
          firstYear,
          lastYear
        }
      );
    }
  };

  showNotification = (variant, message) => {
    this.setStateSafely({
      notificationVariant: variant,
      notificationOpen: true,
      notificationMessage: message
    });
  };

  onChangeSurveyDatabaseType = type => {
    this.setStateSafely({
      surveyDatabaseType: type,
      selectedSurveyDatabaseId: undefined,
      selectedCountryNumericCode: undefined,
      selectedRegion: undefined
    });
  };

  onSelectSurveyDatabase = databaseId => {
    this.setStateSafely({
      selectedSurveyDatabaseId: databaseId,
      selectedCountryNumericCode: undefined,
      selectedRegion: undefined
    });

    this.selectDefaultCountryOrRegion(
      databaseId,
      this.state.selectedPopulationDatabaseId,
      this.state.selectedEmuDatabaseId
    );
  };

  onSelectSurveyDatabaseFile = file => {
    debugger
    this.setStateSafely({
      uploadedSurveyDatabaseFile: file
    });
  };

  onUploadSurveyDatabase = () => {
    const file = this.state.uploadedSurveyDatabaseFile;

    this.setStateSafely({
      uploadingSurveyDatabase: true
    });

    this.uploadDatabase(file, "survey", this.onProgressSurveyDatabaseUpload)
      .then(uploadDatabaseResponse => {
        return api
          .fetchDatabases(this.signal.token)
          .then(fetchDatabasesResponse => {
            const database = uploadDatabaseResponse.data;

            this.setStateSafely({
              databases: normalizeDatabases(fetchDatabasesResponse.data),
              selectedSurveyDatabaseId: database.id,
              surveyDatabaseType: "custom"
            });
          });
      })
      .catch(error => {
        const filename = this.state.uploadedSurveyDatabaseFile.name;

        this.showNotification("error", `Failed to upload "${filename}"`);

        throw error;
      })
      .finally(() => {
        this.setStateSafely({
          uploadedSurveyDatabaseFile: undefined,
          uploadingSurveyDatabase: false
        });
      });
  };

  onProgressSurveyDatabaseUpload = progressEvent => {
    this.setState({
      surveyDatabaseUploadProgress:
        (progressEvent.loaded / progressEvent.total) * 100
    });
  };

  onCancelSurveyDatabaseUpload = () => {
    this.setStateSafely({
      uploadedSurveyDatabaseFile: undefined
    });
  };

  onChangePopulationDatabaseType = type => {
    this.setStateSafely({
      populationDatabaseType: type,
      selectedPopulationDatabaseId: undefined,
      selectedCountryNumericCode: undefined,
      selectedRegion: undefined
    });
  };

  onSelectPopulationDatabase = databaseId => {
    this.setStateSafely({
      selectedPopulationDatabaseId: databaseId,
      selectedCountryNumericCode: undefined,
      selectedRegion: undefined
    });

    this.selectDefaultCountryOrRegion(
      this.state.selectedSurveyDatabaseId,
      databaseId,
      this.state.selectedEmuDatabaseId
    );
  };

  onSelectPopulationDatabaseFile = file => {
    this.setStateSafely({
      uploadedPopulationDatabaseFile: file
    });
  };

  onUploadPopulationDatabase = () => {
    const file = this.state.uploadedPopulationDatabaseFile;

    this.setStateSafely({
      uploadingPopulationDatabase: true
    });

    this.uploadDatabase(
      file,
      "population",
      this.onProgressPopulationDatabaseUpload
    )
      .then(uploadDatabaseResponse => {
        return api
          .fetchDatabases(this.signal.token)
          .then(fetchDatabasesResponse => {
            const database = uploadDatabaseResponse.data;

            this.setStateSafely({
              databases: normalizeDatabases(fetchDatabasesResponse.data),
              selectedPopulationDatabaseId: database.id,
              populationDatabaseType: "custom"
            });
          });
      })
      .catch(error => {
        const filename = this.state.uploadedPopulationDatabaseFile.name;

        this.showNotification("error", `Failed to upload "${filename}"`);

        throw error;
      })
      .finally(() => {
        this.setStateSafely({
          uploadedPopulationDatabaseFile: undefined,
          uploadingPopulationDatabase: false
        });
      });
  };

  onProgressPopulationDatabaseUpload = progressEvent => {
    this.setState({
      populationDatabaseUploadProgress:
        (progressEvent.loaded / progressEvent.total) * 100
    });
  };

  onCancelPopulationDatabaseUpload = () => {
    this.setStateSafely({
      uploadedPopulationDatabaseFile: undefined
    });
  };

  onChangeEmuDatabaseType = type => {
    this.setStateSafely({
      emuDatabaseType: type,
      selectedEmuDatabaseId: undefined,
      selectedCountryNumericCode: undefined,
      selectedRegion: undefined
    });
  };

  onSelectEmuDatabase = databaseId => {
    this.setStateSafely({
      selectedEmuDatabaseId: databaseId,
      selectedCountryNumericCode: undefined,
      selectedRegion: undefined
    });

    this.selectDefaultCountryOrRegion(
      this.state.selectedSurveyDatabaseId,
      this.state.selectedPopulationDatabaseId,
      databaseId
    );
  };

  onSelectEmuDatabaseFile = file => {
    this.setStateSafely({
      uploadedEmuDatabaseFile: file
    });
  };

  onUploadEmuDatabase = () => {
    const file = this.state.uploadedEmuDatabaseFile;

    this.setStateSafely({
      uploadingEmuDatabase: true
    });

    this.uploadDatabase(file, "emu", this.onProgressEmuDatabaseUpload)
      .then(uploadDatabaseResponse => {
        return api
          .fetchDatabases(this.signal.token)
          .then(fetchDatabasesResponse => {
            const database = uploadDatabaseResponse.data;

            this.setStateSafely({
              databases: normalizeDatabases(fetchDatabasesResponse.data),
              selectedEmuDatabaseId: database.id,
              emuDatabaseType: "custom"
            });
          });
      })
      .catch(error => {
        const filename = this.state.uploadedEmuDatabaseFile.name;

        this.showNotification("error", `Failed to upload "${filename}"`);

        throw error;
      })
      .finally(() => {
        this.setStateSafely({
          uploadedEmuDatabaseFile: undefined,
          uploadingEmuDatabase: false
        });
      });
  };

  onProgressEmuDatabaseUpload = progressEvent => {
    this.setState({
      emuDatabaseUploadProgress:
        (progressEvent.loaded / progressEvent.total) * 100
    });
  };

  onCancelEmuDatabaseUpload = () => {
    this.setStateSafely({
      uploadedEmuDatabaseFile: undefined
    });
  };

  onSelectCountry = countryNumericCode => {
    this.setStateSafely({
      selectedCountryNumericCode: countryNumericCode,
      selectedRegion: undefined
    });
  };

  onSelectRegion = region => {
    this.setStateSafely({
      selectedRegion: region
    });
  };

  onSelectNation = nation => {
    this.setStateSafely({
      selectedNation: nation
    });
  }

  onRunNameChanged = runName => {
    this.setStateSafely({
      runName
    });
  };

  onChangePeriod = period => {
    this.setStateSafely({
      period
    });
  };

  onReviewData = () => {
    const countryNumericCodeOrRegion =
      this.state.selectedRegion ||
      this.state.selectedCountryNumericCode.toString();

    this.setStateSafely({
      loadingTables: true
    });

    // noinspection JSCheckFunctionSignatures
    Promise.all([
      api.fetchSurveyDatabase(
        this.state.selectedSurveyDatabaseId,
        countryNumericCodeOrRegion,
        undefined,
        this.signal.token
      ),
      api.fetchPopulationDatabase(
        this.state.selectedPopulationDatabaseId,
        countryNumericCodeOrRegion,
        this.signal.token
      ),
      this.state.selectedEmuDatabaseId
        ? api.fetchEmuDatabase(
            this.state.selectedEmuDatabaseId,
            countryNumericCodeOrRegion,
            this.signal.token
          )
        : new Promise(resolve =>
            resolve({
              data: []
            })
          )
    ])
      .then(response => {
        if (!response || response.some(item => !item)) {
          return;
        }

        const [surveyData, populationData, emuData] = response;

        this.setStateSafely({
          surveyData: normalizeSurveyData(surveyData.data),
          populationData: normalizePopulationData(populationData.data),
          emuData: normalizeEmuData(emuData.data)
        });
      })
      .finally(() => {
        this.setStateSafely({
          loadingTables: false
        });
      });
  };

  onStartRun = () => {
    api
      .startRun(
        this.state.runName,
        this.state.selectedSurveyDatabaseId,
        this.state.selectedPopulationDatabaseId,
        this.state.selectedEmuDatabaseId || undefined,
        this.state.selectedCountryNumericCode,
        this.state.selectedRegion,
        this.state.period
      )
      .then(() => {
        this.props.history.push("/history");
      });
  };

  onCloseNotification = () => {
    this.setStateSafely({
      notificationOpen: false
    });
  };

  componentDidMount() {
    this.setStateSafely({
      loadingData: true
    });

    api
      .fetchDatabases(this.signal.token)
      .then(response => {
        if (!response) {
          return;
        }

        this.setStateSafely({
          databases: normalizeDatabases(response.data)
        });
      })
      .finally(() => {
        this.setStateSafely({
          loadingData: false
        });
      });
  }

  componentWillUnmount() {
    this.unmounting = true;
    this.signal.cancel();
  }

  render() {
    const countries = this.getCountryIntersection(
      this.state.selectedSurveyDatabaseId,
      this.state.selectedPopulationDatabaseId,
      this.state.selectedEmuDatabaseId
    );

    const regions = this.getRegionIntersection(
      this.state.selectedSurveyDatabaseId,
      this.state.selectedPopulationDatabaseId,
      this.state.selectedEmuDatabaseId
    );

    const { firstYear, lastYear } = {
      firstYear: minimumYear,
      lastYear: maximumYear
    };

    const surveyData = filterSurveyData(
      this.state.surveyData,
      firstYear,
      lastYear
    );

    const populationData = filterPopulationData(
      this.state.populationData,
      firstYear,
      lastYear
    );

    return (
      <StartRun
        match={this.props.match}
        loadingData={this.state.loadingData}
        databases={this.state.databases}
        surveyDatabaseType={this.state.surveyDatabaseType}
        selectedSurveyDatabaseId={this.state.selectedSurveyDatabaseId}
        uploadedSurveyDatabaseName={getDatabaseName(
          this.state.uploadedSurveyDatabaseFile
        )}
        uploadingSurveyDatabase={this.state.uploadingSurveyDatabase}
        surveyDatabaseUploadProgress={this.state.surveyDatabaseUploadProgress}
        populationDatabaseType={this.state.populationDatabaseType}
        selectedPopulationDatabaseId={this.state.selectedPopulationDatabaseId}
        uploadedPopulationDatabaseName={getDatabaseName(
          this.state.uploadedPopulationDatabaseFile
        )}
        uploadingPopulationDatabase={this.state.uploadingPopulationDatabase}
        populationDatabaseUploadProgress={
          this.state.populationDatabaseUploadProgress
        }
        emuDatabaseType={this.state.emuDatabaseType}
        selectedEmuDatabaseId={this.state.selectedEmuDatabaseId}
        uploadedEmuDatabaseName={getDatabaseName(
          this.state.uploadedEmuDatabaseFile
        )}
        uploadingEmuDatabase={this.state.uploadingEmuDatabase}
        emuDatabaseUploadProgress={this.state.emuDatabaseUploadProgress}
        countries={countries}
        selectedCountryNumericCode={this.state.selectedCountryNumericCode}
        regions={regions}
        selectedRegion={this.state.selectedRegion}
        selectedNation={this.state.selectedNation}
        runName={this.state.runName}
        period={this.state.period}
        runTitle={this.createRunTitle()}
        loadingTables={this.state.loadingTables}
        surveyData={surveyData}
        populationData={populationData}
        emuData={this.state.emuData}
        notificationVariant={this.state.notificationVariant}
        notificationOpen={this.state.notificationOpen}
        notificationMessage={this.state.notificationMessage}
        onChangeSurveyDatabaseType={this.onChangeSurveyDatabaseType}
        onSelectSurveyDatabase={this.onSelectSurveyDatabase}
        onSelectSurveyDatabaseFile={this.onSelectSurveyDatabaseFile}
        onUploadSurveyDatabase={this.onUploadSurveyDatabase}
        onCancelSurveyDatabaseUpload={this.onCancelSurveyDatabaseUpload}
        onChangePopulationDatabaseType={this.onChangePopulationDatabaseType}
        onSelectPopulationDatabase={this.onSelectPopulationDatabase}
        onSelectPopulationDatabaseFile={this.onSelectPopulationDatabaseFile}
        onUploadPopulationDatabase={this.onUploadPopulationDatabase}
        onCancelPopulationDatabaseUpload={this.onCancelPopulationDatabaseUpload}
        onChangeEmuDatabaseType={this.onChangeEmuDatabaseType}
        onSelectEmuDatabase={this.onSelectEmuDatabase}
        onSelectEmuDatabaseFile={this.onSelectEmuDatabaseFile}
        onUploadEmuDatabase={this.onUploadEmuDatabase}
        onCancelEmuDatabaseUpload={this.onCancelEmuDatabaseUpload}
        onSelectCountry={this.onSelectCountry}
        onSelectRegion={this.onSelectRegion}
        onSelectNation={this.onSelectNation}
        onRunNameChanged={this.onRunNameChanged}
        onChangePeriod={this.onChangePeriod}
        onReviewData={this.onReviewData}
        onStartRun={this.onStartRun}
        onCloseNotification={this.onCloseNotification}
      />
    );
  }
}

export default withTranslation()(withErrorBoundary(StartRunContainer));
