import React from "react";
import * as PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withTranslation } from "react-i18next";

const styles = {
  actionButton: {
    marginTop: 20
  },
  name: {
    display: "block",
    width: 400
  }
};

const PrepareDataSaver = ({ t, ...rest }) => (
  <div>
    <TextField
      label={t("Data set name")}
      placeholder={t("Specify name for data set...")}
      InputLabelProps={{
        shrink: true
      }}
      fullWidth={true}
      value={rest.name || ""}
      className={rest.classes.name}
      onChange={e => {
        rest.onChangeName(e.target.value);
      }}
    />

    <Button
      variant="contained"
      color="primary"
      disabled={rest.saveDisabled}
      className={rest.classes.actionButton}
      onClick={rest.onSave}
    >
      {t("Save")}
    </Button>
  </div>
);

PrepareDataSaver.propType = {
  name: PropTypes.string,
  saveDisabled: PropTypes.bool,
  onChangeName: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

PrepareDataSaver.defaultProps = {
  saveDisabled: false
};

export default withTranslation()(withStyles(styles)(PrepareDataSaver));
