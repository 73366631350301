import React from "react";
import * as PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { withTranslation } from "react-i18next";
import LanguageChooser from "./LanguageChooser/LanguageChooser";
import LastUpdated from "./LastUpdated";

const styles = (theme) => ({
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  container: {
    width: "auto",
    display: "block", // Fix IE 11 issue
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  error: {
    marginTop: 20,
    fontWeight: "bold",
    color: theme.palette.error.dark,
  },
  form: {
    width: "100%", // Fix IE 11 issue
    marginTop: theme.spacing.unit,
  },
  languages: {
    margin: "20px 0 10px",
    textAlign: "center",
  },
  lastUpdated: {
    textAlign: "center",
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
  },
  register: {
    marginTop: 20,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

const Login = ({ classes, t, ...rest }) => (
  <div className={classes.container}>
    <Paper className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>

      <Typography component="h1" variant="h5">
        {t("Sign in")}
      </Typography>

      {rest.systemStatusMessage ? (
        <div className={classes.error}>{rest.systemStatusMessage}</div>
      ) : (
        undefined
      )}

      {rest.error ? (
        <div className={classes.error}>{rest.error}</div>
      ) : (
        undefined
      )}

      <form className={classes.form}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="username">{t("Username")}</InputLabel>
          <Input
            id="username"
            name="username"
            autoComplete="username"
            autoFocus
            value={rest.username || ""}
            onChange={(e) => {
              rest.onChangeUsername(e.target.value);
            }}
          />
        </FormControl>

        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="password">{t("Password")}</InputLabel>
          <Input
            name="password"
            type="password"
            id="password"
            value={rest.password || ""}
            autoComplete="current-password"
            onChange={(e) => {
              rest.onChangePassword(e.target.value);
            }}
          />
        </FormControl>

        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label={t("Remember me")}
          checked={rest.rememberMe}
          onChange={rest.onToggleRememberMe}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={!rest.systemOperational}
          onClick={(e) => {
            e.preventDefault();

            rest.onSubmit(e);
          }}
        >
          {t("Sign in")}
        </Button>

        <div className={classes.register}>
          {t("Don't have an account?")}{" "}
          <a
            href={`https://authuser.avenirhealth.org/?sv=${
              process.env.REACT_APP_AUTHENTICATION_SERVICE_ID
            }&md=new`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("Register")}
          </a>{" "}
          {t("now.")}
        </div>

        <div className={classes.register}>
          {t("Forgotten your password?")}{" "}
          <a
            href={`https://authuser.avenirhealth.org/?sv=${
              process.env.REACT_APP_AUTHENTICATION_SERVICE_ID
            }&md=fg`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("Reset")}
          </a>{" "}
          {t("it.")}
        </div>
      </form>
    </Paper>

    <div className={classes.languages}>
      <LanguageChooser onChangeLanguage={rest.onChangeLanguage} />
    </div>

    <div className={classes.lastUpdated}>
      <LastUpdated />
    </div>
  </div>
);

Login.propTypes = {
  systemOperational: PropTypes.bool,
  systemStatusMessage: PropTypes.string,
  username: PropTypes.string,
  password: PropTypes.string,
  rememberMe: PropTypes.bool.isRequired,
  error: PropTypes.string,
  onChangeUsername: PropTypes.func.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  onToggleRememberMe: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChangeLanguage: PropTypes.func.isRequired,
};

Login.defaultProps = {
  systemOperational: true,
};

export default withTranslation()(withStyles(styles)(Login));
