import React from "react";
import * as PropTypes from "prop-types";
import {
  emuDatumPropType,
  populationDatumPropType,
  surveyDatumPropType
} from "../../propTypes";
import { Redirect, Route, Switch } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import EmuData from "./PrepareDataEmuData";
import NavigationTab from "../NavigationTab";
import Notification from "../Notification";
import PopulationData from "./PrepareDataPopulationData";
import SurveyData from "./PrepareDataSurveyData";

const styles = {
  tabPage: {
    margin: 10
  }
};

const compareDatabases = (a, b) =>
  a.isDefault !== b.isDefault
    ? b.isDefault - a.isDefault
    : a.isGlobal !== b.isGlobal
    ? b.isGlobal - a.isGlobal
    : a.name.localeCompare(b.name);

const getDatabases = (databases, type) =>
  Object.entries(databases)
    .filter(database => database[1].type === type)
    .sort((a, b) => compareDatabases(a[1], b[1]))
    .map(([id, database]) => ({
      value: id,
      label: database.name
    }));

const normalizeCountries = countries =>
  Object.entries(countries)
    .map(([numericCode, country]) => ({
      value: numericCode,
      label: country.name
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

const PrepareData = ({ match, ...rest }) => {
  const surveyDatabases = getDatabases(rest.databases, "survey");
  const surveyDatabaseCountries = normalizeCountries(
    rest.surveyDatabaseCountries
  );

  const populationDatabases = getDatabases(rest.databases, "population");
  const populationDatabaseCountries = normalizeCountries(
    rest.populationDatabaseCountries
  );

  const emuDatabases = [
    { value: "", label: "None" },
    ...getDatabases(rest.databases, "emu")
  ];
  const emuDatabaseCountries = normalizeCountries(rest.emuDatabaseCountries);

  const { t } = useTranslation();

  return (
    <div>
      <NavigationTab to="/preparedata/surveys">
        {t("Survey data")}
      </NavigationTab>
      <NavigationTab to="/preparedata/populations">
        {t("Population data")}
      </NavigationTab>
      <NavigationTab to="/preparedata/emu">
        {t("Service statistics")}
      </NavigationTab>

      <div className={rest.classes.tabPage}>
        <Switch>
          <Route
            exact
            path={match.path}
            render={() => <Redirect replace to={`${match.path}/surveys`} />}
          />
          <Route
            path={`${match.path}/surveys`}
            render={() => (
              <SurveyData
                databases={surveyDatabases}
                countries={surveyDatabaseCountries}
                selectedDatabaseId={rest.selectedSurveyDatabaseId}
                selectedCountryNumericCode={
                  rest.selectedSurveyDataCountryNumericCode
                }
                data={rest.surveyData}
                regionData={rest.surveyRegionData}
                regionNumericCode={rest.surveyRegionNumericCode}
                regionAlphaCode={rest.surveyRegionAlphaCode}
                regionName={rest.surveyRegionName}
                newDatabaseName={rest.newSurveyDatabaseName}
                onSelectDatabase={rest.onSelectSurveyDatabase}
                onSelectCountry={rest.onSelectSurveyDataCountry}
                onLoadCountryData={rest.onLoadCountrySurveyData}
                onAddNewItem={rest.onAddNewSurveyItem}
                onEditItem={rest.onEditSurveyItem}
                onDeleteItem={rest.onDeleteSurveyItem}
                onClearData={rest.onClearSurveyData}
                onToggleRegionData={rest.onToggleSurveyRegionData}
                onChangeRegionNumericCode={rest.onChangeSurveyRegionNumericCode}
                onChangeRegionAlphaCode={rest.onChangeSurveyRegionAlphaCode}
                onChangeRegionName={rest.onChangeSurveyRegionName}
                onChangeDatabaseName={rest.onChangeSurveyDatabaseName}
                onSave={rest.onSaveSurveyDatabase}
              />
            )}
          />
          <Route
            path={`${match.path}/populations`}
            render={() => (
              <PopulationData
                databases={populationDatabases}
                countries={populationDatabaseCountries}
                selectedDatabaseId={rest.selectedPopulationDatabaseId}
                selectedCountryNumericCode={
                  rest.selectedPopulationDataCountryNumericCode
                }
                data={rest.populationData}
                regionData={rest.populationRegionData}
                regionNumericCode={rest.populationRegionNumericCode}
                regionAlphaCode={rest.populationRegionAlphaCode}
                regionName={rest.populationRegionName}
                newDatabaseName={rest.newPopulationDatabaseName}
                onSelectDatabase={rest.onSelectPopulationDatabase}
                onSelectCountry={rest.onSelectPopulationDataCountry}
                onLoadCountryData={rest.onLoadCountryPopulationData}
                onAddNewItem={rest.onAddNewPopulationItem}
                onEditItem={rest.onEditPopulationItem}
                onDeleteItem={rest.onDeletePopulationItem}
                onClearData={rest.onClearPopulationData}
                onToggleRegionData={rest.onTogglePopulationRegionData}
                onChangeRegionNumericCode={
                  rest.onChangePopulationRegionNumericCode
                }
                onChangeRegionAlphaCode={rest.onChangePopulationRegionAlphaCode}
                onChangeRegionName={rest.onChangePopulationRegionName}
                onChangeDatabaseName={rest.onChangePopulationDatabaseName}
                onSave={rest.onSavePopulationDatabase}
              />
            )}
          />
          <Route
            path={`${match.path}/emu`}
            render={() => (
              <EmuData
                databases={emuDatabases}
                countries={emuDatabaseCountries}
                selectedDatabaseId={rest.selectedEmuDatabaseId}
                selectedCountryNumericCode={
                  rest.selectedEmuDataCountryNumericCode
                }
                data={rest.emuData}
                regionData={rest.emuRegionData}
                regionNumericCode={rest.emuRegionNumericCode}
                regionAlphaCode={rest.emuRegionAlphaCode}
                regionName={rest.emuRegionName}
                newDatabaseName={rest.newEmuDatabaseName}
                onSelectDatabase={rest.onSelectEmuDatabase}
                onSelectCountry={rest.onSelectEmuDataCountry}
                onLoadCountryData={rest.onLoadCountryEmuData}
                onAddNewItem={rest.onAddNewEmuItem}
                onEditItem={rest.onEditEmuItem}
                onDeleteItem={rest.onDeleteEmuItem}
                onClearData={rest.onClearEmuData}
                onToggleRegionData={rest.onToggleEmuRegionData}
                onChangeRegionNumericCode={rest.onChangeEmuRegionNumericCode}
                onChangeRegionAlphaCode={rest.onChangeEmuRegionAlphaCode}
                onChangeRegionName={rest.onChangeEmuRegionName}
                onChangeDatabaseName={rest.onChangeEmuDatabaseName}
                onSave={rest.onSaveEmuDatabase}
              />
            )}
          />
        </Switch>
      </div>

      <Notification
        variant={rest.notificationVariant}
        open={rest.notificationOpen}
        message={rest.notificationMessage}
        onClose={rest.onCloseNotification}
      />
    </div>
  );
};

PrepareData.propTypes = {
  databases: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ),
  surveyDatabaseCountries: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ),
  selectedSurveyDatabaseId: PropTypes.string,
  selectedSurveyDataCountryNumericCode: PropTypes.number,
  surveyData: PropTypes.arrayOf(surveyDatumPropType),
  newSurveyDatabaseName: PropTypes.string,
  populationDatabaseCountries: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ),
  selectedPopulationDatabaseId: PropTypes.string,
  selectedPopulationDataCountryNumericCode: PropTypes.number,
  populationData: PropTypes.arrayOf(populationDatumPropType),
  newPopulationDatabaseName: PropTypes.string,
  emuDatabaseCountries: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ),
  selectedEmuDatabaseId: PropTypes.string,
  selectedEmuDataCountryNumericCode: PropTypes.number,
  emuData: PropTypes.arrayOf(emuDatumPropType),
  newEmuDatabaseName: PropTypes.string,
  notificationVariant: PropTypes.string,
  notificationOpen: PropTypes.bool,
  notificationMessage: PropTypes.string,
  onSelectSurveyDatabase: PropTypes.func.isRequired,
  onSelectSurveyDataCountry: PropTypes.func.isRequired,
  onLoadCountrySurveyData: PropTypes.func.isRequired,
  onAddNewSurveyItem: PropTypes.func.isRequired,
  onEditSurveyItem: PropTypes.func.isRequired,
  onDeleteSurveyItem: PropTypes.func.isRequired,
  onClearSurveyData: PropTypes.func.isRequired,
  onChangeSurveyDatabaseName: PropTypes.func.isRequired,
  onSaveSurveyDatabase: PropTypes.func.isRequired,
  onSelectPopulationDatabase: PropTypes.func.isRequired,
  onSelectPopulationDataCountry: PropTypes.func.isRequired,
  onLoadCountryPopulationData: PropTypes.func.isRequired,
  onAddNewPopulationItem: PropTypes.func.isRequired,
  onEditPopulationItem: PropTypes.func.isRequired,
  onDeletePopulationItem: PropTypes.func.isRequired,
  onClearPopulationData: PropTypes.func.isRequired,
  onChangePopulationDatabaseName: PropTypes.func.isRequired,
  onSavePopulationDatabase: PropTypes.func.isRequired,
  onSelectEmuDatabase: PropTypes.func.isRequired,
  onSelectEmuDataCountry: PropTypes.func.isRequired,
  onLoadCountryEmuData: PropTypes.func.isRequired,
  onAddNewEmuItem: PropTypes.func.isRequired,
  onEditEmuItem: PropTypes.func.isRequired,
  onDeleteEmuItem: PropTypes.func.isRequired,
  onClearEmuData: PropTypes.func.isRequired,
  onChangeEmuDatabaseName: PropTypes.func.isRequired,
  onSaveEmuDatabase: PropTypes.func.isRequired,
  onCloseNotification: PropTypes.func.isRequired
};

PrepareData.defaultProps = {
  databases: {},
  surveyDatabaseCountries: {},
  surveyData: [],
  populationDatabaseCountries: {},
  populationData: [],
  emuDatabaseCountries: {},
  emuData: [],
  notificationOpen: false
};

export default withStyles(styles)(PrepareData);
