import React from "react";
import * as PropTypes from "prop-types";
import { periodPropType } from "../../propTypes";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import LinearProgress from "@material-ui/core/LinearProgress";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import PeriodSelector from "../PeriodSelector/PeriodSelector";
import ProgressButton from "../ProgressButton";

const styles = {
  actionButton: {
    display: "inline-flex",
    margin: "10px 0"
  },
  countrySelector: {
    display: "none"
  },
  divider: {
    margin: "10px 0"
  },
  instruction: {
    margin: "10px 0",
    fontWeight: "bold"
  },
  periodSelector: {
    margin: "30px 20px 40px",
    zIndex: -1
  },
  progress: {
    margin: "15px 0 5px"
  },
  select: {
    marginBottom: 10
  },
  stepNumber: {
    display: "none"
  }
};

const ViewRunDrawerContent = props => {
  const runs = Object.entries(props.runs).map(([id, run]) => ({
    value: id,
    label: run.name
  }));

  const countries = Object.entries(props.countries)
    .map(([numericCode, country]) => ({
      value: parseInt(numericCode, 10),
      label: country.name
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const regions = props.regions.map((region, index) => ({
    value: -index - 1,
    label: region
  }));

  const selectedRegion = regions.find(
    region => region.label === props.selectedRegion
  );

  const selectedRegionValue = selectedRegion && selectedRegion.value;

  const countriesAndRegions = countries.concat(regions);

//   const getPopulations = (countryCode) => {
//     if (!countryCode) {
//         return [];
//     }

//     // HACK: Need a way to determine if run is a subnationals run.
//     const isSubnationalRun = true;

//     if (isSubnationalRun) {
//         return [
//             // { value: "Aggregated", label: "Aggregated" },
//             { value: "population_1", label: "population_1" },
//             { value: "population_2", label: "population_2" },
//             { value: "population_3", label: "population_3" }
//         ] 
//     }
//     else {
//         return [];
//     }
//   }

//   const selectedPopulationValue = props.selectedPopulation;
//   const populations = getPopulations(props.selectedCountryNumericCode);

  const { t } = useTranslation();

  return (
    <>
      {props.loadingData && (
        <div className={props.classes.progress}>
          <LinearProgress />
        </div>
      )}

      <div className={props.classes.instruction}>
        <span className={props.classes.stepNumber}>1.</span>{" "}
        {t("Select existing run")}
      </div>

      <Select
        placeholder={t("Select run...")}
        options={runs}
        value={runs.filter(({ value }) => value === props.selectedRunId)}
        className={props.classes.select}
        onChange={({ value }) => props.onSelectRun(value)}
      />

        {/* {
            props.selectedRunId && populations.length > 0 && (
                <>
                
                    <div className={props.classes.instruction}>
                        <span className={props.classes.stepNumber}>2.</span>{" "}
                        {t("Select population")}
                    </div>
                    <Select
                        placeholder={t("Select population...")}
                        options={populations}
                        value={populations.filter(({ value }) => value === selectedPopulationValue)}
                        className={props.classes.select}
                        onChange={({ value }) => props.onSelectPopulation(value)}
                    />
                </>
            )
        } */}

      <div className={props.classes.countrySelector}>
        <Divider className={props.classes.divider} />

        <div className={props.classes.instruction}>
          2. {t("Select country/population to view")}
        </div>

        <Select
          placeholder={t("Select country/population...")}
          options={countriesAndRegions}
          value={countriesAndRegions.filter(
            ({ value }) =>
              value ===
              (selectedRegionValue || props.selectedCountryNumericCode)
          )}
          className={props.classes.select}
          onChange={({ value }) => {
            if (value >= 0) {
              props.onSelectCountry(value);
            } else {
              props.onSelectRegion(
                regions.find(region => region.value === value).label
              );
            }
          }}
        />

        <Divider className={props.classes.divider} />
      </div>

      <ProgressButton
        variant="contained"
        color="primary"
        disabled={!(props.selectedCountryNumericCode || props.selectedRegion)}
        loading={props.loadingRun}
        className={props.classes.actionButton}
        onClick={props.onViewRun}
      >
        {t("View run")}
      </ProgressButton>

      <Divider className={props.classes.divider} />

      <div className={props.classes.instruction}>
        {t("Select years for display/export of results")}
      </div>

      <div className={props.classes.periodSelector}>
        <PeriodSelector period={props.period} onChange={props.onChangePeriod} />
      </div>
    </>
  );
};

ViewRunDrawerContent.propTypes = {
  loadingData: PropTypes.bool,
  runs: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ),
  selectedRunId: PropTypes.string,
  countries: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ),
  selectedCountryNumericCode: PropTypes.number,
  regions: PropTypes.arrayOf(PropTypes.string),
  selectedRegion: PropTypes.string,
  period: periodPropType,
  loadingRun: PropTypes.bool,
  onSelectRun: PropTypes.func.isRequired,
  onSelectCountry: PropTypes.func.isRequired,
  onSelectRegion: PropTypes.func.isRequired,
  onViewRun: PropTypes.func.isRequired,
//   selectedPopulation : PropTypes.string.isRequired,
//   onSelectPopulation : PropTypes.func.isRequired,
};

ViewRunDrawerContent.defaultProps = {
  loadingData: false,
  runs: {},
  countries: {},
  regions: [],
  loadingRun: false,
};

export default withStyles(styles)(ViewRunDrawerContent);
