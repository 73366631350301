import React from "react";
import * as PropTypes from "prop-types";
import { maritalStatusPropType } from "../../propTypes";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import { withTranslation } from "react-i18next";

const ChartsMaritalStatusSelector = ({ t, ...rest }) => (
  <div className={rest.className}>
    <FormControl>
      <InputLabel htmlFor="input" shrink={true}>
        {t(rest.label)}
      </InputLabel>

      <NativeSelect
        input={<Input id="input" />}
        value={rest.value}
        onChange={e => rest.onChange(e.target.value)}
      >
        <option value="married">{t("Married")}</option>
        <option value="unmarried">{t("Unmarried")}</option>
        <option value="all_women">{t("All women")}</option>
      </NativeSelect>
    </FormControl>
  </div>
);

ChartsMaritalStatusSelector.propTypes = {
  label: PropTypes.string,
  value: maritalStatusPropType,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

ChartsMaritalStatusSelector.defaultProps = {
  label: "Marital status"
};

export default withTranslation()(ChartsMaritalStatusSelector);
